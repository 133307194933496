import React, { useState } from 'react';
import ModelTabs from './Components/ModelTabs';
import SegmentationTree from './Components/SegmentationTree';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconMenu from './Components/IconMenu'

import { useGetProjectDetailsQuery, useGetSegmentationTypeQuery, usePostCompleteSegmentationMutation, useUpdateTocIsSavedMutation } from '../redux/slices/ProjectPageAPISlice';
import { useParams } from 'react-router-dom';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import { useEffect } from 'react';
import { useGetDNRDataQuery } from './Store/dataSlices/DriverAndRestraintAPISlice';
import { useCallback } from 'react';
import { SegmentationTreeContext, SegmentationTreeProvider } from './Provider/SegmentationTreeProvider';


export default function DriversRestraints(props) {
  const [open, setOpen] = useState(false);
  const [openDialog,setOpenDialog]=useState(false);
  const [openSegmentationDialog,setOpenSegmentationDialog]=useState(false);
  const {projectId}=useParams();
    const [isForecastCompleted,setIsForecastCompleted]=useState(false);
    const {data:ProjectData,isLoading:isProjectDataLoading,isSuccess:isProjectDataSuccess}=useGetProjectDetailsQuery({projectId:projectId});
    const { data: DnrData, error, isError, isLoading, isSuccess, } = useGetDNRDataQuery({ projectId, userId: getUserDetailsFromLocalStorage().user_id });
    useEffect(()=>{
        if(isProjectDataSuccess){
            setIsForecastCompleted(ProjectData?.data.forecast_status===1)
        }
    },[isProjectDataLoading,isProjectDataSuccess,ProjectData])
  

  const [postCompleteSegmentation]=usePostCompleteSegmentationMutation();
  
  const handleSegmentationComplete=async()=>{
    
    try {
      postCompleteSegmentation({
        projectId:projectId,
        body:{
          user_id:getUserDetailsFromLocalStorage().user_id,
          forecast_status: 1
        }
      })
      
      
    } catch (error) {
      console.log(error)
    }
    
      setOpen(true)
      setOpenDialog(false)
      setIsForecastCompleted(true)
    
  }

  const checkAllLevel1HasChildren=useCallback((node)=>{
    if(isSuccess){
      if (node?.level === 0) {
        if (node.children.length === 0) {
          return false;
        }
        return node?.children.every((child) => {
          if (child.level === 1) {
            return child.children.length > 0 && checkAllLevel1HasChildren(child);
          }
          return true;
        });
      } else if (node?.level === 1) {
        return node.children.length > 0 && node.children.every(checkAllLevel1HasChildren);
      }
      return true;
    }
  },[isLoading,isSuccess])
  const {data:segmentationData,isLoading:isSegmentationLoading,isSuccess:isSegmentationSuccess}=useGetSegmentationTypeQuery({projectId:projectId})
  const [parentNodeId,setParentNodeId]=useState(DnrData?.initialNodes[0]?.id);
  useEffect(()=>{
    if(isSuccess){
      setParentNodeId(DnrData?.initialNodes[0]?.id)
    }
    if(isError){
      setParentNodeId(null)
      console.log(error);
    }
  },[isLoading,isSuccess,isError])
  
  return (
    <>
    <SegmentationTreeProvider
        isForecastCompleted={isForecastCompleted}
      >
    <SegmentationTreeContext.Consumer>
      { ({isNodeEditable})=>{
        return <React.Fragment>
          {!isForecastCompleted&&isSegmentationSuccess||isNodeEditable?
          <div className='text-center'>
            {segmentationData?.data?.length!==0?<button className="btn btn-primary btn-sm mx-1" onClick={() => setOpenSegmentationDialog(true)}>Modify Segmentations</button>:null}
            {checkAllLevel1HasChildren(DnrData?.initialNodes[0])?
              <button 
                className="btn btn-primary btn-sm mx-1" 
                onClick={() => setOpenDialog(true)}>Complete</button>:null}
          </div>:null}

     
    <div className=''>
      
        <SegmentationTree 
          DnrData={DnrData} 
          error={error} 
          isError={isError} 
          isLoading={isLoading} 
          isSuccess={isSuccess}
          isForecastCompleted={isForecastCompleted}
          />
        
      <Dialog
        open={openDialog}
        onClose={()=>setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use this configuration ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This configuration for Segmentation tree would be used for model creation in the next steps. Proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenDialog(false)}>No</Button>
          <Button onClick={handleSegmentationComplete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={openSegmentationDialog}
        onClose={()=>setOpenSegmentationDialog(false)}
        aria-labelledby="segmentation-dialog-title"
        aria-describedby="segmentation-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {"Modify Segmentation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <IconMenu/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenSegmentationDialog(false)}>No</Button>
          <Button onClick={()=>{
            setOpenSegmentationDialog(false)
          }} autoFocus>
            Yes
          </Button>
        </DialogActions>
        </Dialog>
    </div>
        </React.Fragment>
      } }
    </SegmentationTreeContext.Consumer>
    </SegmentationTreeProvider>
    {(open||isForecastCompleted)&& 
      <ModelTabs 
          parentNodeId={parentNodeId} 
          DnrData={DnrData} 
          isForecastCompleted={isForecastCompleted}
          tocId={props.id} />}
    </>
  );
}
