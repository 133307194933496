import React, { useContext, useEffect, useRef, useState } from 'react'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { WordCount } from '@ckeditor/ckeditor5-word-count';

import Font from '@ckeditor/ckeditor5-font/src/font'
import {
  SpecialCharacters,
  SpecialCharactersEssentials,
} from '@ckeditor/ckeditor5-special-characters'
import {
  Bold,
  Code,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from '@ckeditor/ckeditor5-basic-styles'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent'
import {
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from '@ckeditor/ckeditor5-table'
import { List, ListProperties, TodoList } from '@ckeditor/ckeditor5-list'
import { Link, LinkImage, AutoLink } from '@ckeditor/ckeditor5-link'
import {
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  AutoImage,
} from '@ckeditor/ckeditor5-image'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import { PageBreak } from '@ckeditor/ckeditor5-page-break'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { Highlight } from '@ckeditor/ckeditor5-highlight'
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace'
import { Comments, CommentsOnly } from '@ckeditor/ckeditor5-comments'
import { TrackChanges } from '@ckeditor/ckeditor5-track-changes'


import CommentsAdapter from './Adapters/CommentsAdapter'
import UsersInit from './Adapters/UsersInit'
import TrackChangesAdapter from './Adapters/TrackChangesAdapter'

import './Style.css'
import { useGetEditorDataQuery } from '../redux/slices/ProjectPageAPISlice'
import { useParams } from 'react-router-dom'
import { ProjectStatusContext } from '../Providers/ProjectStatusProvider'
import CustomUploadAdapterPlugin from './Adapters/customUploadAdapter'
const CkEditorBase = ({ onChange, data, editorId, tocId, Limits }) => {
  const { projectId } = useParams()
  const editorRef = useRef(null)
  const { isProjectEditable } = useContext(ProjectStatusContext)
  const [editorData, setEditorData] = useState(data)
  const [isEditorReady, setIsEditorReady] = useState(false)
  const [isSidebarVisible, setIsSidebarVisible] = useState('')

  //assign word limit from props here
  const wordLimit = Limits[0];
  //assign character limit from props here
  const characterLimit = Limits[1];

  //show details assign from props here
  const showDetails = Limits[2];

  const [words, setWords] = useState(0)
  const [characters, setCharacters] = useState(0)
  const {
    data: projectDetails,
    isLoading,
    isSuccess,
    error,
    isError,
  } = useGetEditorDataQuery({ projectId: projectId })

  useEffect(() => {
    if (projectDetails) {
      ClassicEditor.create(document.querySelector(`#${editorId}`), {
        id: editorId,
        tocId: tocId,
        plugins: [
          Essentials,
          Alignment,
          Heading,
          WordCount,
          Font,
          SpecialCharacters,
          SpecialCharactersEssentials,
          Paragraph,
          Bold,
          Code,
          Italic,
          Strikethrough,
          Subscript,
          Superscript,
          Underline,
          Indent,
          IndentBlock,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          List,
          ListProperties,
          TodoList,
          Link,
          LinkImage,
          AutoLink,
          Image,
          ImageCaption,
          ImageInsert,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          PictureEditing,
          AutoImage,
          HorizontalLine,
          PageBreak,
          BlockQuote,
          Highlight,
          FindAndReplace,
          Comments,
          CommentsOnly,
          TrackChanges,

        ],
        toolbar: [
          'undo',
          'redo',
          '|',
          'bold',
          'italic',
          'underline',
          'alignment',
          '|',
          'bulletedList',
          'numberedList',
          'todoList',
          'strikethrough',
          'subscript',
          'superscript',
          
          '|',
          'imageUpload',
          'comment',
          'trackChanges',

          {
            label: 'More basic styles',
            icon: 'threeVerticalDots',
            items: [
              'findAndReplace',
              'selectAll',
              '|',
              'heading',
              '|',
              'specialCharacters',
              'horizontalLine',
              'pageBreak',
              '|',
              'highlight',
              'code',
              'fontSize',
              'fontFamily',
              'fontColor',
              'fontBackgroundColor',
              '|',
              'link',
              'blockQuote',
              'insertTable',

              '|',
              'outdent',
              'indent',
              
            ],
            shouldNotGroupWhenFull: true,
          },
        ],
        extraPlugins: [
          function (editor) {
            return new UsersInit(editor, projectDetails)
          },
          function (editor) {
            return new CommentsAdapter(editor, projectDetails)
          },
          function (editor) {
            return new TrackChangesAdapter(editor, projectDetails)
          },

          CustomUploadAdapterPlugin,
        ],
        licenseKey: process.env.REACT_APP_GO_IN_CK_key,
        sidebar: {
          container: document.querySelector(`#sidebar-go-in`),
        },

        revisionHistory: {
          editorContainer: document.querySelector(
            `#editor-container-${editorId}`
          ),
          viewerContainer: document.querySelector(
            `#revision-viewer-container-${editorId}`
          ),
          viewerEditorElement: document.querySelector(
            `#revision-viewer-editor-${editorId}`
          ),
          viewerSidebarContainer: document.querySelector(
            `#revision-viewer-sidebar-${editorId}`
          ),
        },
        comments: {
          editorConfig: {
            extraPlugins: [Bold, Italic, List],
            mention: {
              feeds: [
                {
                  marker: '@',
                  feed: [
                    '@Baby Doe',
                    '@Joe Doe',
                    '@Jane Doe',
                    '@Jane Roe',
                    '@Richard Roe',
                  ],
                  minimumCharacters: 1,
                },
              ],
            },
          },
        },
        wordCount: {
          onUpdate: (stats) => {
            // Prints the current content statistics.
            // console.log( stats,`Characters: ${ stats?.characters }\nWords: ${ stats?.words }` );
            setWords(stats.words)
            setCharacters(stats.characters)
          }
        },
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, 'default', 18, 20, 22],
          supportAllValues: true,
        },
        htmlEmbed: {
          showPreviews: true,
        },
        image: {
          styles: ['alignCenter', 'alignLeft', 'alignRight'],
          resizeOptions: [
            { name: 'resizeImage:original', label: 'Original', value: null },
            { name: 'resizeImage:50', label: '50%', value: '50' },
            { name: 'resizeImage:75', label: '75%', value: '75' },
          ],
          toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            'imageStyle:side',
            '|',
            'resizeImage',
          ],
          insert: {
            type: 'auto',
            integrations: [
              'upload',
              'assetManager',
              'url',
              'insertImageViaUrl',
            ],
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file',
              },
            },
          },
        },
        placeholder: 'Type or paste your content here!',
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties',
            'toggleTableCaption',
          ],
        },
        updateSourceElementOnDestroy: true,
      })
        .then((editorInstance) => {
          setIsEditorReady(true)
          editorInstance.setData(data || '') // Use the data prop here
          editorRef.current = editorInstance
          editorInstance.editing.view.focus()



          editorInstance.model.document.on('change:data', (e) => {
            const newData = editorInstance.getData()
            setEditorData(newData)
            // Call the onChange prop with the new data
            onChange(newData)
            const stats = editorInstance.plugins.get('WordCount');
            if (
              // stats.words > wordLimit || 
              stats.characters > characterLimit || editorInstance.model.document.getRoot().maxOffset > characterLimit) {
              // If limit is exceeded, prevent further input
              editorInstance.plugins.get('CommentsOnly').isEnabled = true
            }
            else {
              editorInstance.plugins.get('CommentsOnly').isEnabled = false
            }
          })

          editorInstance.editing.view.document.on('keydown', (evt, data) => {
            const keyCode = data.keyCode;
            if ((keyCode === 8 || keyCode === 46) && (editorInstance.plugins.get('CommentsOnly').isEnabled)) {
              editorInstance.plugins.get('CommentsOnly').isEnabled = false
            }
          });

          editorInstance.editing.view.document.on('clipboardInput', (evt, data) => {
            const clipboardContent = data.dataTransfer.getData('text/plain');
            const currentContentLength = editorInstance.model.document.getRoot().maxOffset;
            const combinedLength = currentContentLength + clipboardContent.length;

            if (combinedLength > characterLimit) {
              const remainingCharacters = characterLimit - currentContentLength;
              const truncatedContent = clipboardContent.substring(0, remainingCharacters);
              editorInstance.model.change(writer => {
                const insertPosition = editorInstance.model.document.selection.getFirstPosition();
                writer.insertText(truncatedContent, insertPosition);
              });
              evt.stop();
            } else {
              editorInstance.execute('input', clipboardContent);
            }
          });

          if (!isProjectEditable) {
            editorInstance.plugins.get('CommentsOnly').isEnabled =
              !editorInstance.plugins.get('CommentsOnly').isEnabled
          }
        })
        .catch((error) => {
          console.error(error)
        })

      return () => {
        if (editorRef.current) {
          editorRef.current
            .destroy()
            .catch((error) => console.log('Destroy error:', error))
        }
      }
    }
  }, [projectDetails, editorId, isSidebarVisible, isProjectEditable])
  const removeSidebarContainers = () => {
    return new Promise((resolve) => {
      const elements = document.querySelectorAll('.sidebar-ck')
      elements.forEach((element) => {
        if (element.querySelector(`#sidebar-${isSidebarVisible}`) === null) {
          element.style.display = 'none'
        } else {
          element.style.display = 'block'
        }
      })
      resolve()
    })
  }

  // Call the function to remove the elements

  const handleEditorClick = async () => {
    setIsSidebarVisible(editorId)
    await removeSidebarContainers()
  }
  return (
    <div className='editors-holder w-100'>
      <div id={`editor-container-${editorId}`} className='w-100'>
        <div
          className='editor-container'
          onClick={() => {
            handleEditorClick()
          }}
        >
          <div id={editorId}></div>

          {/* {sessionStorage.getItem('mode') != 'fullScreen' ? 
         
            <div className='sidebar-container-main sidebar-ck shadow-sm'>
              <p
                style={{ backgroundColor: '#0e4c8b' }}
                className='text-center text-white font14 fw600 py-1'
              >
                Comments <i className='ms-1 fa fa-comments-o'></i>
              </p>
              <div
                className='sidebar-container'
                id={`sidebar-${isSidebarVisible}`}
              ></div>
            </div>
            : null} */}
        </div>
        {showDetails ? <div className="word-count-container font14 my-2 text-end" style={{ color: '#f7913d', fontWeight: '600' }}>
          <span>Characters: {characters}/{characterLimit}</span>
        </div> : null}
      </div>
    </div>
  )
}

export default CkEditorBase
