import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetGrowthMetricDatabyProjectIdQuery, usePostGrowthMetricsDataMutation, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import { GrowthMetricsStaticData } from '../../AppStaticData/ProjectPageStaticData';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import SSOLoader from '../SSOLoader';
import GrowthMetricCard from '../GrowthMetrics/GrowthMetricCard';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { ProgressContext } from '../../Providers/ProgressProvider';
import Footer from './Footer';
import high from "../../images/high.png";
import medium from "../../images/medium.png";
import low from "../../images/low.png";
import DialogueBox from '../../ComponentUtils/DialogBox';

const FilterButton = ({ selectedImpact, impact, label, className, onClick }) => (
    <button 
        className={`btn btn-sm ${selectedImpact === impact ? className : `btn-outline-${className.split('-')[1]}`} me-2`}
        onClick={() => onClick(impact)}
    >
        {label}
    </button>
);


function GrowthMetrics(props) {
    const { projectId } = useParams();
    const {handleSectionSave}=useContext(ProgressContext)
    const { data, 
        isSuccess, isLoading 
    } = useGetGrowthMetricDatabyProjectIdQuery({projectId:projectId, 
        segmentId: props.seg_id,
        tocId:props.id});
    const {isProjectEditable}=useContext(ProjectStatusContext)
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved]=useUpdateTocIsSavedMutation();
    const [postGrowthMetricsData]=usePostGrowthMetricsDataMutation()
    const [growthMetrics, setGrowthMetrics] = useState([]);
    const [selectedImpact, setSelectedImpact] = useState(null);
    const [loader, setLoader] = useState(false);
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");

    useEffect(() => {
        if (isSuccess) {
            setGrowthMetrics(data?.data.length ? data.data : GrowthMetricsStaticData.data);
        }
    }, [data, isSuccess]);

    useEffect(() => {
        setHeader(props?.name);
    }, [props]);

    const addMetric = () => {
        const newId = growthMetrics.length  + 1 ;
        setGrowthMetrics([...growthMetrics, { id: newId, name: 'New Metric' }]);
    };

    const deleteMetric = (id) => {
        setGrowthMetrics(prevMetrics => prevMetrics.filter(metric => metric.id !== id));
    };

    const updateMetricName = (index,field, value) => {
        
        const newGrowthMetricsData = growthMetrics.map((metric, i) => {
            if (i === index) {
                return { ...metric, [field]: value };
            }
            return metric;
        });
        setGrowthMetrics(newGrowthMetricsData);
    };

    const updateMetricImpact = (id, impact) => {
        setGrowthMetrics(prevMetrics => 
            prevMetrics.map(metric => 
                metric.id === id ? { ...metric, impact: metric.impact === impact ? null : impact } : metric
            )
        );
    };

    const toggleImpactFilter = (impact) => {
        setSelectedImpact(prevImpact => prevImpact === impact ? null : impact);
    };

    const handleSaveToc = async () => {
        try {
            setLoader(true);
            await postTocData({
                projectId:projectId,
                propsId: props?.id,
                body: {
                    userid: getUserDetailsFromLocalStorage()?.user_id,
                    description: props?.description,
                    name: header,
                    isSaved:1
                }
            });
            setHeaderIsEdit(false);
            setLoader(false);
            toast.success('Saved Successfully');
        } catch (error) {
            console.error( error);
            setLoader(false);
            toast.error("Something went wrong");
        }
    };

    const filteredMetrics = useMemo(() => 
        selectedImpact ? growthMetrics.filter(metric => metric.impact === selectedImpact) : growthMetrics,
    [selectedImpact, growthMetrics]);


    const postGrowthMetricData=async()=>{
        try {
            await postGrowthMetricsData({
                projectId:projectId,
                body:{
                    "userid": getUserDetailsFromLocalStorage().user_id,
                    "seg_id": props.seg_id,
                    "metrics":growthMetrics,
                    "tocid":props.id
                }
            }).then((res)=>{
                if(res.data.message=='Successfully saved'){
                    toast.success(res.data.message);
                }
            })
            updateTocIsSaved({projectId:projectId,tocId:props.id})
            handleSectionSave(props.id)

        } catch (error) {
            console.error(error)
        }
        
    } 

    const [createCloneToc,{
        isLoading:isCreateCloneLoading,
    }]=useCreateCloneTocMutation()
    const [deleteClone,{isLoading:isCloneDeleteLoading}]=useDeleteCloneMutation();
    const [updateIsApplicable,{isLoading:isUpdateApplicableLoading}]=useUpdateIsApplicableMutation()
    

    
        const handleCreateClone=async()=>{
            try {
                createCloneToc({projectId:projectId,tocId:props.id}).then((res)=>toast.success("Clone created"))
                
            } catch (error) {
                console.error(error)
                toast.error("Something went wrong")
            }
        }

        const handleDeleteClone=async()=>{
                try {
                    deleteClone({
                        projectId:projectId,
                        tocId:props.id
                    })
                    toast.success("Clone successfully deleted")
                } catch (error) {
                    console.error(error)
                    toast.error("Something went wrong")
                }
            
            
        }
        const [isApplicable,setIsApplicable]=useState(props?.isApplicable)
        const handleUpdateIsApplicable = async (crtIsApplicable) => {
            try {
                updateIsApplicable({
                    projectId: projectId,
                    tocId: props.id,
                    isApplicable:crtIsApplicable
                })
            } catch (error) {
                console.error(error);
                toast.error("Something went wrong")
            }
        }
    
        const handleToggle = () => {
            setIsApplicable(1-isApplicable)
            handleUpdateIsApplicable(1-isApplicable)
        };

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
          handleDeleteClone();
          // Perform the delete action here
        }
        else if(dialogContent.action === 'clone'){
            handleCreateClone()
        }
        setOpenDialog(false);
      };

      const handleClose = () => {
        setOpenDialog(false);
      };

      const onDelete=()=>{
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
      }
      const onCreateClone=()=>{
        setDialogContent({
            title: 'Confirm Clone creation',
            text: 'Are you sure you want to clone this section?',
            action: 'clone',
        });
        setOpenDialog(true);
      }
      
    return (
        <div className="card ">
            {loader||isCreateCloneLoading||isCloneDeleteLoading||isUpdateApplicableLoading ? <SSOLoader />:null}
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    {headerIsEdit ? (
                        <div className='input-group input-group-sm w-50'>
                            <input disabled={!isProjectEditable} type='text' className='form-control rounded-0' value={header} onChange={(e) => setHeader(e.target.value)} />
                            <button disabled={!isProjectEditable}  type='button' className='btn btn-warning mx-2 rounded-0' onClick={handleSaveToc}><i className='fa fa-save'></i> Save</button>
                            <button disabled={!isProjectEditable}  type='button' className='btn btn-danger rounded-0' onClick={() => setHeaderIsEdit(false)}><i className='fa fa-refresh'></i> Reset</button>
                        </div>
                    ) : (
                        <p className="mb-0">{props?.name}<i onClick={() => setHeaderIsEdit(true)} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i></p>
                    )}
                    <div className='d-flex align-items-center'>
                        <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                            <input 
                                className="form-check-input" 
                                checked={isApplicable} 
                                onChange={handleToggle}
                                type="checkbox" 
                                role="switch" 
                                id="flexSwitchCheckDefault"
                            />
                            <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                        </div>
                        {props.isClone?<i className="me-3 fa fa-plus-square" alt="Square Plus" onClick={onCreateClone}></i>:null}
                        {(props.roleType!=='analyst')||(props.hasCloned)?<i className="fa fa-trash-o" alt="Trash" onClick={onDelete} title='Delete'></i>:null}
                        <DialogueBox
                            openDialog={openDialog} 
                            setOpenDialog={setOpenDialog}
                            dialogTitle={dialogContent.title}
                            dialogContentText={dialogContent.text} 
                            handleConfirmation={handleConfirmation}
                            handleClose={handleClose}
                        />
                    </div>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className="row gmdiv gx-2">
                    {/* <div className="mb-3">
                        <FilterButton selectedImpact={selectedImpact} impact={null} label="No filter" className="btn-primary" onClick={toggleImpactFilter} />
                        <FilterButton selectedImpact={selectedImpact} impact="high" label="High" className="btn-success" onClick={toggleImpactFilter} />
                        <FilterButton selectedImpact={selectedImpact} impact="medium" label="Medium" className="btn-warning" onClick={toggleImpactFilter} />
                        <FilterButton selectedImpact={selectedImpact} impact="low" label="Low" className="btn-danger" onClick={toggleImpactFilter} />
                    </div> */}
                    {filteredMetrics.map((metric,i) => (
                        <GrowthMetricCard 
                            key={i} 
                            index={i}
                            metric={metric} 
                            updateMetricName={updateMetricName} 
                            deleteMetric={deleteMetric} 
                            updateMetricImpact={updateMetricImpact}
                        />
                    ))}
                    {!selectedImpact &&isProjectEditable &&(filteredMetrics.length <10) && (
                        <div className="col-md-3 mb-3 d-flex align-items-center justify-content-center">
                            <button type='button' onClick={addMetric} className='btn btn-primary btn-sm'>
                                <FontAwesomeIcon
                                    icon={faAdd}
                                    style={{cursor: 'pointer'}}
                                    
                                /> Add New</button>
                            <div className="card rounded-0 border-0 d-none">
                                <div className="card-header py-1 rounded-0 d-flex align-items-center justify-content-center px-2">
                                    Add new
                                </div>
                                <div className="card-body px-2">
                                    <div className='position-relative'>
                                        <div className='position-absolute top-50 start-50 translate-middle'>
                                            <FontAwesomeIcon
                                                icon={faAdd}
                                                style={{color: '#1582c5', cursor: 'pointer',fontSize:'42px' }}
                                                onClick={addMetric}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='d-flex justify-content-end'>
                    <button type="button" className='btn btn-primary btn-sm mb-3' onClick={postGrowthMetricData}><i className='fa fa-save me-1'></i>Save</button>
                </div>
            </div>
            <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{backgroundColor:'#1582C5'}}>
                <Footer/>
            </div>
        </div>
    );
}

export default GrowthMetrics;