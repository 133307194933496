import React, { useContext, useEffect, useState } from 'react';
import { useAddSegmentationNodeMutation, useDeleteSegmentationNodeMutation, useGetDNRDataQuery } from '../Store/dataSlices/DriverAndRestraintAPISlice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import Node from './Node';
import { Tree, TreeNode } from 'react-organizational-chart';
import DynamicTree from './DynamicTree';
import Hint from './Hint';
import DialogueBox from '../../ComponentUtils/DialogBox';
import { usePostCompleteSegmentationMutation } from '../../redux/slices/ProjectPageAPISlice';
import { SegmentationTreeContext } from '../Provider/SegmentationTreeProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};
const SegmentationTree = ({ 
    DnrData, 
    error, 
    isError, 
    isLoading, 
    isSuccess,
    isForecastCompleted
 }) => {
    const { projectId } = useParams();
    const [data, setData] = useState(DnrData?.initialNodes[0] || {});
    const [addSegmentationNode] = useAddSegmentationNodeMutation();
    const [deleteSegmentationNode] = useDeleteSegmentationNodeMutation();
    const [showAddHint, setShowAddHint] = useState(true);
    const [showDeleteHint, setShowDeleteHint] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [nodeToDelete, setNodeToDelete] = useState(null);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });
    const {isNodeEditable,setIsNodeEditable}=useContext(SegmentationTreeContext)
    
    //const [open, setOpen] = useState(false)
    //const handleOpen = () => setOpen(true);
    //const handleClose = () => setOpen(false);

    useEffect(() => {
        if (isSuccess && DnrData) {
            setData(DnrData?.initialNodes[0]);
        }
    }, [isLoading, isSuccess, DnrData]);


    const handleNameChange = (id, newName) => {
        const updateNode = (node) => {
            if (node.id === id) {
                node.name = newName;
            } else {
                node.children.forEach(updateNode);
            }
        };
        const newData = JSON.parse(JSON.stringify(data));
        updateNode(newData);
        setData(newData);
    };

    function findNodeById(node, id) {
        if (node.id === id) {
            return node;
        }
        if (node.children) {
            for (let child of node.children) {
                const found = findNodeById(child, id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    async function postNode(nodeData, parentId) {

        await addSegmentationNode({ projectId: projectId, body: nodeData }).then((res) => {

        }).catch((err) => console.log(err))

    }
    const addChild = (parentId) => {
        setShowAddHint(false);
        const parentNode = findNodeById(data, parentId);
        const newNode = {
            id: Date.now(),
            name: 'Add Segment',
            children: [],
            source: parentId,
            user_id: getUserDetailsFromLocalStorage().user_id,
            level: (parseInt(parentNode.level) + 1)
        };
        if (parentNode) {
            // Check if a node with the name 'Name this Node' already exists at this level
            const isDuplicate = parentNode.children.some(child => child.name === 'Add Segment');

            if (isDuplicate) {
                toast.error("A new node already exists at this level. Please rename it before adding another.");
                return;
            }


            newNode.level = (parseInt(parentNode.level) + 1);
            const newChildren = [...(parentNode.children), newNode];
            const updatedParentNode = { ...parentNode, children: newChildren };

            const updateNode = (node) => {
                if (node.id === parentId) {
                    return updatedParentNode;
                }
                if (node.children) {
                    const newChildren = node.children.map(child => updateNode(child));
                    return { ...node, children: newChildren };
                }
                return node;
            };

            const newData = updateNode(data);
            setData(newData);
            postNode(newNode, parentId);
        }

    };
    async function deleNodeItem(id) {
        await deleteSegmentationNode({
            projectId: projectId,
            body: {
                id: id,
                user_id: getUserDetailsFromLocalStorage().user_id
            }
        }).then().catch((err) => console.error(err))
    }
    const deleteNode = (id) => {
        setShowDeleteHint(false);
        setNodeToDelete(id);
        setOpenDialog(true);
        setDialogContent({
            title:'Confirm Deletion',
            text:"All nodes under this segment would be deleted. Are you sure you want to delete this segment?",
            action:'delete'
        })
    };


    const handleDeleteConfirmation = () => {
        if (nodeToDelete) {
            const removeNode = (node, parent) => {
                if (node.id === nodeToDelete) {
                    if (parent) {
                        parent.children = parent.children.filter(child => child.id !== nodeToDelete);
                    }
                } else {
                    node.children.forEach(child => removeNode(child, node));
                }
            };
            const newData = JSON.parse(JSON.stringify(data));
            if (newData.id !== nodeToDelete) {
                removeNode(newData, null);
                setData(newData);
                deleNodeItem(nodeToDelete);
            } else {
                alert("Can't delete the root node!");
            }
            setNodeToDelete(null);
        }
    };
    const [postCompleteSegmentation]=usePostCompleteSegmentationMutation();
    async function handleEditSegmentTree(){
        await postCompleteSegmentation({
            projectId:projectId,
            body:{
              user_id:getUserDetailsFromLocalStorage().user_id,
              forecast_status: 0
            }
          }).then((res)=>console.log(res)).catch((err)=>console.error(err))
    }
    
    
    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
            handleDeleteConfirmation();
          
        } else if (dialogContent.action === 'editNode') {
            // handleEditSegmentTree();
            setIsNodeEditable(true)
        }
        
        
        setOpenDialog(false);
      };
      
      const onEditSegment=()=>{
        setOpenDialog(true);
        setDialogContent({
            title:'Confirm Edit',
            text:"Please make sure to click on Complete button once you finish working on segmentation tree to save your changes.",
            action:'editNode'
        })
        
        
      }

      
      
    return (
        <>
            {/* <button className='btn btn-sm btn-outline-primary mx-2' onClick={handleOpen}> <i className="fa fa-eye" aria-hidden="true"></i> Chart Preview</button> */}
            <div className='d-flex justify-content-between align-items-center'>
                <div className="hints-container">
                    <Hint
                    show={showAddHint&&!isForecastCompleted} 
                    message="Click on the Add button to add a segment" 
                    />
                    <Hint 
                    show={showDeleteHint&&!isForecastCompleted} 
                    message="Click on the Delete button to delete a segment" 
                    />
                    <Hint 
                    show={!isForecastCompleted} 
                    message="Once you are done creating segments click on Complete button on the top" 
                    />
                </div>
                
                
                <React.Fragment>
                    <div className="gap-2 d-flex">
                        <button type="button" className="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#segmentPreviewModal"><i className="fa fa-eye" aria-hidden="true"></i> Preview</button>
                       
                        {isForecastCompleted?
                         <button className="btn btn-sm btn-outline-primary" onClick={onEditSegment}><i className='fa fa-edit me-1'></i>Edit</button>
                        :null}
                    </div>
                </React.Fragment>
                
            </div>
            <div className='org-chart'>
                <div style={{ margin: '0 auto' }}>
                    {data && (
                        <Node
                            node={data}
                            onAddChild={addChild}
                            onDeleteNode={deleteNode}
                            onNameChange={handleNameChange}
                        />
                    )}
                </div>
            </div>

            <DialogueBox 
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                dialogTitle={dialogContent.title}
                dialogContentText={dialogContent.text}
                handleConfirmation={handleConfirmation}
                handleClose={() => setOpenDialog(false)}
            />

            {/* <div className={`modal fade ${open ? 'show' : ''}`} id="ContributorModal" style={{ display: open ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header py-2 d-flex justify-content-between">
                            <h6 className="modal-title">Segmentation Preview</h6>
                            <p className="" onClick={handleClose} style={{ cursor: 'pointer', marginTop: '13px' }}><span className="">&#x2715;</span></p>
                        </div>
                        <div className="modal-body pt-0">
                            <div style={{overflowY:"auto"}} className='py-4'>
                                <DynamicTree data={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="modal fade" id="segmentPreviewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header py-2">
                            <h6 className="modal-title">Segmentation Preview</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <DynamicTree data={data} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SegmentationTree;
