import React, { useContext } from 'react'
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';

const AwardRow = ({ index, awardsData, handleChange }) => {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    return (
        <React.Fragment >
            <div class="gp-award">
                <div class="gp-label">
                    <span>AWARD {index + 1}</span>
                </div>
                <div class="wrapper">
                    <div class="gp-input-wrapper">
                        <label for="recipient">Recipient</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.recipient} onChange={(e) => handleChange(index, 'recipient', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="company-contact">Company Contact</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.company_contact} onChange={(e) => handleChange(index, 'company_contact', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="contactTitle">Contach Title</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.contact_title} onChange={(e) => handleChange(index, 'contact_title', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="contactPhone">Contact Phone</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.contact_phone} onChange={(e) => handleChange(index, 'contact_phone', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="contactEmail">Contact Email</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.contact_email} onChange={(e) => handleChange(index, 'contact_email', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="awardCategory">Award Category</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.award_category} onChange={(e) => handleChange(index, 'award_category', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="region">Region</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.Region} onChange={(e) => handleChange(index, 'Region', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="market">Market</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.market} onChange={(e) => handleChange(index, 'market', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="bpr-support">BPR Support? (Y/N)</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.bpr_support} onChange={(e) => handleChange(index, 'bpr_support', e.target.value)} />
                    </div>
                    <div class="gp-input-wrapper">
                        <label for="sub-date">Planned Submission Date/ Comments</label>
                        <input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.comments} onChange={(e) => handleChange(index, 'comments', e.target.value)} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AwardRow;