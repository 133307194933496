import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetProjectDetailsQuery, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import CkEditorBase from '../../CkEditorReact/CkEditorBase';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { useParams } from 'react-router-dom';
import downarrow from "../../images/downarrow.png";
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';
function StrategicImperative(props) {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const { handleSectionSave } = useContext(ProgressContext)
    const { projectId } = useParams()
    const [loader, setLoader] = useState(false)
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation()
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [ckId, setCkId] = useState(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess } = useGetProjectDetailsQuery({ projectId: props?.go_in_id });
    const [Industry, setIndustry] = useState('')
    const getIndustryData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getindustry/`);
            setIndustry(res?.data?.data?.filter(ele => ele.id == id)[0]?.name || "")
        } catch (error) {
            console.log(error);
        }
    }
    const [sector, setSector] = useState({})
    const getSectorData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsectorbyid/${id}`);
            setSector(res?.data?.data?.[0] || {})
        } catch (error) {
            console.log(error);
        }
    }
    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        if (props?.go_in_id) {
            setHeader(props?.name)
            getStrategicImperative()
        }
    }, [props])

    const initialState = {
        userid: getUserDetailsFromLocalStorage()?.user_id,
        industryid: getUserDetailsFromLocalStorage()?.user_id,
        strategic: [
            { name: "", why: "", perspective: "", sort_by: 1 },
            { name: "", why: "", perspective: "", sort_by: 2 },
            { name: "", why: "", perspective: "", sort_by: 3 }
        ],
        tocid: props.id
    }
    const [formData, setFormData] = useState(initialState)
    const [reqErrMsg, setReqErrMsg] = useState(false)

    const handleInputChange = (event, index) => {
        setReqErrMsg(false)
        const { name, value } = event.target;
        const updatedStrategic = [...formData.strategic];
        updatedStrategic[index][name] = value;
        setFormData({
            ...formData,
            strategic: updatedStrategic
        });
    };

    const handleCKEditorChange = (name, value, index) => {
        setReqErrMsg(false)
        let strippedText = value.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;
        
       
            if (characterCount < 500) {
                const updatedStrategic = [...formData.strategic];
                updatedStrategic[index][name] = value;
                setFormData({
                    ...formData,
                    strategic: updatedStrategic
                });
                if (debounceTimeout) clearTimeout(debounceTimeout);
                const timeout = setTimeout(() => {

                    let secondaryPostData = {
                        type: 'strategic_imperative',
                        column:
                            name == 'why'
                                ? 'strategic_imperative_why'
                                : 'strategic_imperative_perspective',
                        description: value,
                    }
                    try {
                        if (formData.strategic[index]?.id) {
                            async function apiCall() {
                                let response2 = await axios.post(
                                    `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${window?.location?.href.split('/').pop()}/ckeditorupdate/${formData.strategic[index]?.id}`,
                                    secondaryPostData
                                )
                                console.log(response2);
                            }
                            apiCall()
                        }
                    } catch (error) {
                        console.log(error)
                    }

                }, 1000);

                setDebounceTimeout(timeout);
            } else {
                toast.error("Only 500 Characters allowed")
            }
        
    };

    const getStrategicImperative = async () => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${projectId}/strategicimperative/${props.id}`)
            if (Object.keys(res?.data?.data).length != 0) {
                const transformedData = transformData(res?.data?.data);
                setFormData({
                    ...formData,
                    strategic: transformedData?.strategic
                });

            }
            setCkId(props.id)

        } catch (error) {
            console.log(error);
        }
    }



    const transformData = (input) => {
        return {
            strategic: input.map(item => ({
                name: item.name,
                why: item.strategic_imperative_why,
                perspective: item.strategic_imperative_perspective,
                id: item.id,
                sort_by: item.sort_by
            })).sort((a, b) => a.sort_by - b.sort_by)
        };
    };



    const handleSave = async () => {
        const projectid = props?.go_in_id
        function checkLength(arr) {
            for (let i = 0; i < formData.strategic.length; i++) {
                if (arr[i].name.length > 1 && arr[i].why.length > 1 && arr[i].perspective.length > 1) {
                    return true
                } else {
                    return false
                }
            }
        }

        try {
            if (checkLength(formData.strategic)) {
                let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${projectid}/strategicimperative`, formData,)
                if (response?.data?.msg == "Successfully Saved") {
                    toast.success('Successfully Saved')
                    getStrategicImperative()

                    handleSectionSave(props.id)
                    updateTocIsSaved({ projectId: projectid, tocId: props.id })
                } else {
                    toast.error('Something went wrong!!Please try again later')
                }

            } else {
                setReqErrMsg(true)
            }
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!!Please try again later')

        }
    }

    useEffect(() => {
        if (ProjectData?.data) {
            getIndustryData(ProjectData?.data?.industry)
            getSectorData(ProjectData?.data?.sector_id)
        }
    }, [ProjectData])


    const [createCloneToc, {
        isLoading: isCreateCloneLoading,
    }] = useCreateCloneTocMutation()
    const [deleteClone, { isLoading: isCloneDeleteLoading }] = useDeleteCloneMutation();
    const [updateIsApplicable, { isLoading: isUpdateApplicableLoading }] = useUpdateIsApplicableMutation()



    const handleCreateClone = async () => {
        try {
            createCloneToc({ projectId: projectId, tocId: props.id }).then((res) => toast.success("Clone created"))

        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }

    const handleDeleteClone = async () => {
        try {
            deleteClone({
                projectId: projectId,
                tocId: props.id
            })
            toast.success("Clone successfully deleted")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }


    }
    const [isApplicable, setIsApplicable] = useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable: crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1 - isApplicable)
        handleUpdateIsApplicable(1 - isApplicable)
    };

    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
            handleDeleteClone();
            // Perform the delete action here
        }
        else if (dialogContent.action === 'clone') {
            handleCreateClone()
        }
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onDelete = () => {
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
    }
    const onCreateClone = () => {
        setDialogContent({
            title: 'Confirm Clone creation',
            text: 'Are you sure you want to clone this section?',
            action: 'clone',
        });
        setOpenDialog(true);
    }
    return (
        <>
            <div className="card">

                {loader || isCreateCloneLoading || isCloneDeleteLoading ? <SSOLoader /> : null}
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            headerIsEdit && isProjectEditable ?
                                <div className='input-group input-group-sm w-50'>
                                    <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                    <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                    <button type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                                </div>
                                :
                                <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
                        }

                        <div className='d-flex align-items-center'>
                            <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                                <input
                                    className="form-check-input"
                                    checked={isApplicable}
                                    onChange={handleToggle}
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                            </div>
                            {props.isClone ? <i className="me-3 fa fa-plus-square" alt="Square Plus" onClick={onCreateClone}></i> : null}
                            {(props.roleType !== 'analyst') || (props.hasCloned) ? <i className="fa fa-trash-o" alt="Trash" onClick={onDelete} title='Delete'></i> : null}
                            <DialogueBox
                                openDialog={openDialog}
                                setOpenDialog={setOpenDialog}
                                dialogTitle={dialogContent.title}
                                dialogContentText={dialogContent.text}
                                handleConfirmation={handleConfirmation}
                                handleClose={handleClose}
                            />
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='d-flex justify-content-between'>
                        <h6> The Impact of the Top 3 Strategic Imperatives on the {sector?.name} Industry</h6>
                        <i data-bs-toggle="modal" data-bs-target="#myModalSi" style={{ color: '#1582C5' }} className='fa fa-info-circle font16 cursor-pointer'></i>
                    </div>
                    <div className="row industryxyz gx-2 p-1">
                        <div className="col-md-4 mb-3">
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='verticalText'>S18</div>
                                <input type="text" className="form-control form-control-sm" disabled={!isProjectEditable} value={formData.strategic[0]?.name} name="name" onInput={(event) => handleInputChange(event, 0)} placeholder="Enter Top 1 Strategic Imperatives" />
                            </div>
                            <img className='downarrow my-1' src={downarrow} />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='verticalText'>Why</div>
                                <div className="w-100">
                                    {
                                        ckId ? <CkEditorBase editorId={`siWhy-1-${ckId}`} onChange={(data) => handleCKEditorChange("why", data, 0)} data={formData.strategic[0]?.why} tocId={formData.strategic[0]?.id} Limits={[100, 500, true]} /> : null
                                    }
                                </div>
                            </div>
                            <img className='downarrow my-1' src={downarrow} />
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='verticalText'>Frost Perspective</div>
                                <div className="w-100">
                                    {
                                        ckId ? <CkEditorBase editorId={`siPer-1-${ckId}`} className="" onChange={(data) => handleCKEditorChange("perspective", data, 0)} data={formData.strategic[0]?.perspective} tocId={formData.strategic[0]?.id} Limits={[100, 500, true]} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <input type="text" disabled={!isProjectEditable} className="form-control form-control-sm" placeholder="Enter Top 2 Strategic Imperatives" value={formData.strategic[1]?.name} name="name" onInput={(event) => handleInputChange(event, 1)} />
                            <img className='downarrow my-1' src={downarrow} />
                            <div className="w-100">
                                {
                                    ckId ? <CkEditorBase editorId={`siWhy-2-${ckId}`} onChange={(data) => handleCKEditorChange("why", data, 1)} data={formData.strategic[1]?.why} tocId={formData.strategic[1]?.id} Limits={[100, 500, true]} /> : null
                                }

                            </div>
                            <img className='downarrow my-1' src={downarrow} />
                            <div className="w-100">
                                {
                                    ckId ? <CkEditorBase editorId={`siPer-2-${ckId}`} className="" onChange={(data) => handleCKEditorChange("perspective", data, 1)} data={formData.strategic[1]?.perspective} tocId={formData.strategic[1]?.id} Limits={[100, 500, true]} /> : null
                                }
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <input type="text" disabled={!isProjectEditable} className="form-control form-control-sm" placeholder="Enter Top 3 Strategic Imperatives" value={formData.strategic[2]?.name} name="name" onInput={(event) => handleInputChange(event, 2)} />
                            <img className='downarrow my-1' src={downarrow} />
                            <div className="w-100">
                                {
                                    ckId ? <CkEditorBase editorId={`siWhy-3-${ckId}`} onChange={(data) => handleCKEditorChange("why", data, 2)} data={formData.strategic[2]?.why} tocId={formData.strategic[2]?.id} Limits={[100, 500, true]} /> : null
                                }

                            </div>
                            <img className='downarrow my-1' src={downarrow} />
                            <div className="w-100">
                                {
                                    ckId ? <CkEditorBase editorId={`siPer-3-${ckId}`} className="" onChange={(data) => handleCKEditorChange("perspective", data, 2)} data={formData.strategic[2]?.perspective} tocId={formData.strategic[2]?.id} Limits={[100, 500, true]} /> : null
                                }
                            </div>

                        </div>
                    </div>
                    {reqErrMsg ? <span style={{ color: 'red' }}>All fields are required</span> : null}
                    {isProjectEditable && <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary btn-sm me-2" onClick={handleSave}><i className="fa fa-save me-1"></i>Save</button>
                        <button type="button" className="btn btn-danger btn-sm"><i className="fa fa-close me-1"></i>Cancel</button>
                    </div>}


                </div>
                <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{ backgroundColor: '#1582C5' }}>
                    <Footer />
                </div>
            </div>

            <div class="modal fade" id="myModalSi">
                <div class="modal-dialog  modal-lg">
                    <div class="modal-content">
                        <div class="modal-header py-2">
                            <h5 class="modal-title">The Impact of the Top 3 Strategic Imperatives on the {sector?.name} Industry</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div class="modal-body font14">
                            Analyst picks the top 3 strategic imperatives from the 8 imperatives. The imperatives will be prepopulated from which the analyst can chose the top 3.In future the 3 imperatives can expand, but for now it will be 3
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default StrategicImperative