import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import {  toast } from 'react-toastify';
import { ProjectStatusContext } from './Providers/ProjectStatusProvider';

function ExcelUploader() {
    const [files, setFiles] = useState([]);
    const [allFiles, setAllFiles] = useState([])
    const project_id = window.location.href.split('/').pop();
    const { setFileUploaded }=useContext(ProjectStatusContext)
    const handleFileUpload = (event) => {
        setFiles(event.target.files);
    };
    const handleFileDelete = async (name) => {
        try {
            let response = await axios.delete(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/deluploadFile/${name}`)
            getFiles()
            if (response?.status == 200) {
                toast.success('File deleted successfully')
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.log(error);
        }
    };

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append("excelfile", files[0]);
        console.log(formData);
        console.log(files[0]);
        try {
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/uploadFile/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            if (response?.status == 200) {
                setFileUploaded(true);
                toast.success('File uploaded successfully')
            } else {
                toast.error('Something went wrong')
            }
            getFiles()
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong')
        }
    }

    const downloadFile = async(name)=>{
        try {
            let response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/download/${project_id}/${name}`)
            window.open(response?.data?.data,'_blank')
        } catch (error) {
         console.log(error);   
        }
    }

    const getFiles = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/getuploadFile`)
            
            setFileUploaded(data?.data?.data.length>0)
            setAllFiles(data?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }
  
    return (
        <div className='card'>
            <div className='card-header d-flex '>
                <h6 className='text-center mb-0 text-white'>
                    Upload Excel Files
                </h6>
            </div>
            <div className='card-body'>

            <div class="input-group input-group-sm">
                <input type="file" multiple
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload} 
                    className='form-control'/>
                <button className='btn btn-secondary' onClick={uploadFile}><i class="fa fa-upload"></i> Upload</button>
            </div>
                <table className='table table-bordered table-striped table-sm text-center table-excelToHtml mt-3 mb-0'>
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allFiles.length > 0 ? allFiles?.map((file, index) => (
                            <tr key={index}>
                                <td>{file?.file_name}</td>
                                <td className='d-flex justify-content-around'>
                                    <i className='fa fa-trash font16' onClick={() => handleFileDelete(file?.file_name)} title='Delete'></i>
                                    <i className='fa fa-download font16' title='Download' onClick={()=>downloadFile(file?.file_name)}></i>
                                </td>
                            </tr>
                        )) : <tr><td colSpan={2}>No files Uploaded yet</td></tr>}
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default ExcelUploader;
