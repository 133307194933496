// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-invalid {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.is-invalid button {
    border-color: #dc3545 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/GrowthOppUniverse/Style.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,gDAAgD;AACpD;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".is-invalid {\n    border-color: #dc3545 !important;\n    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);\n}\n\n.is-invalid button {\n    border-color: #dc3545 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
