import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {  toast } from 'react-toastify'
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils'
import { useParams } from 'react-router-dom'
import DialogueBox from '../ComponentUtils/DialogBox'

function AddContributors({contributorsList, setContributorsList,project_Id}) {
    const [types, setTypes] = useState([])
    const [userGroup, setUserGroup] = useState([])
    const [selectedUser,setSelectedUser]=useState('');
    const [selectedType,setSelectedType]=useState('')
    let initialData = {
        'created_by': getUserDetailsFromLocalStorage()?.user_id,
        'role_type': '',
        'user_id': ''
    }
    const [contributors, setContributors] = useState(initialData)
    // let project_Id = window.location.href?.split('/').pop()

    const headers = {
        Authorization: `Bearer ${document?.cookie?.split(';')?.find(token => token.startsWith(' $access_token'))?.split('=')[1]}`,
    }
    const getTypes = async () => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/groups`)
            setTypes(response?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const getContributorsList = async (e) => {
        setSelectedType(types.find((item)=>item.slug===e.target.value));
        
        let params = {
            "slug_name": e.target.value
        }
        try {
            let response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/groupsbyuser`, { params })
            setUserGroup(response?.data?.data)
            setContributors(prevState => ({
                ...prevState,
                role_type: e.target.options[e.target.selectedIndex].text
            }));
        } catch (error) {
            console.log(error)
        }
    }

    const contributorOnChange = (e) => {
        setSelectedUser(userGroup.find((item)=>item.user_id===e.target.value))
        setContributors(prevState => ({
            ...prevState,
            user_id: e.target.value
        }));
    }
    const {projectId}=useParams();
    const AddContrib = async () => { 
         
        try {
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_Id||projectId}/addcontributor`,{ 
                created_by:contributors.created_by,
                role_type:selectedType.role_type,
                user_id:selectedUser?.user_id
            })
            if (response?.data?.message.startsWith('Contributor')) {
                toast.success(response?.data?.message)
                listContributors()
                setContributors(initialData)
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const listContributors = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_Id||projectId}/listcontributor`)
            let arr1 = data?.data?.data
            let arr2 = data?.data?.userdetails
            let userMap = {};
            arr2.forEach(user => {
                userMap[user.id] = user;
            });
            arr1.forEach(obj => {
                let user = userMap[obj.user_id];
                if (user) {
                    obj.first_name = user.first_name;
                    obj.last_name = user.last_name;
                }
            });
            if (contributorsList.length > arr1.length || contributorsList.length < arr1.length) {
                setContributorsList(arr1)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const deleteContributors = async (e) => {
        let data = {
            'user_id': e
        }
        try {
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_Id}/deletecontributor`, data)
            if (response?.data?.message == 'Contributor Deleted!') {
                listContributors()
                toast.success(response?.data?.message)
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTypes()
    }, [])
    useEffect(() => {
        listContributors()
    }, [contributorsList])
    const [openDialog,setOpenDialog]=useState(false);
    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            
            <div  className="">
                <div  className="row gx-1">
                    <div  className="col-4">
                        <select  className="form-select form-select-sm" onChange={getContributorsList}>
                            <option disabled>Please select</option>
                            {
                                types?.map((ele, i) => <option key={i} value={ele?.slug}>{ele?.name}</option>)
                            }
                        </select>
                    </div>
                    <div  className="col-8">
                        <div  className="input-group input-group-sm mb-1">
                            <select  className="form-select form-select-sm" onChange={contributorOnChange}>
                                <option disabled>Please select</option>
                                {
                                    userGroup?.map((ele,i) => <option key={i} value={ele?.user_id}>{ele?.first_name + ' ' + ele?.last_name}</option>)
                                }
                            </select>
                            <span  className="input-group-text cursor-pointer" style={{ cursor: 'pointer',backgroundColor:'#0E4C8B',color:'white' }} onClick={AddContrib}>Add</span>
                        </div>
                    </div>
                </div>
                <div  className='input-group input-group-sm mt-3 '>
                    <h6>Contributors</h6>
                    {contributorsList?.map((e, index) => <div key={index}  className='d-flex w-100'>
                        <span
                            type="text"
                             className="form-control form-control-sm border-end-0 rounded-0"
                            name='name'
                        >{e?.first_name + ' ' + e?.last_name + ' - ' + e?.role_type}</span>
                        <button title='Remove Contributor' className="input-group-text rounded-0" onClick={()=>setOpenDialog(true) }>
                            <img
                                 className=""
                                src={require('../images/action_icon/close.png')}
                            />
                        </button>
                        <DialogueBox
                            dialogContentText={'This contributor for this project will be deleted. Continue?'}
                            dialogTitle={'Delete contributors'}
                            handleClose={handleClose}
                            handleConfirmation={()=>deleteContributors(e.user_id)}
                            openDialog={openDialog}
                            setOpenDialog={setOpenDialog}

                        />
                    </div>)}
                </div>
            </div>
            <div  className="" id="">
                <div  className="">
                    <div  className="">
                        <div  className="modal-body">
                        </div>
                    </div>
                </div>
            </div>

            <div  className="modal fade" id="myModal2">
                <div  className="modal-dialog">
                    <div  className="modal-content">
                        <div  className="modal-header border-bottom-0">
                            <h5  className="modal-title">Add Customers</h5>
                            <button type="button"  className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div  className="modal-body">
                            <div  className="row gx-1">
                                <div  className="col-4">
                                    <select  className="form-select form-select-sm">
                                        <option>Level</option>
                                        <option>Level 1</option>
                                        <option>Level 2</option>
                                        <option>Level 3</option>
                                    </select>
                                </div>
                                <div  className="col-8">
                                    <div  className="input-group input-group-sm mb-1">
                                        <input type="text"  className="form-control form-control-sm" placeholder="Search By Name" />
                                        <span  className="input-group-text">Add</span>
                                    </div>
                                </div>
                                <div  className="font12 mt-2">
                                    Customer will see radar-specific information WITHOUT the text descriptions, a list of companies names ONLY (with no other information), and a radar graphic with all dots but NO labels.
                                </div>
                            </div>
                        </div>
                        <div  className="modal-footer border-top-0">
                            <button type="button"  className="btn btn-danger btn-sm" data-bs-dismiss="modal">Cancel</button>
                            <button type="button"  className="btn btn-primary btn-sm" data-bs-dismiss="modal">Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddContributors
