import React, { useEffect, useMemo, useState } from "react"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Styles/createProject.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SSOLoader from './SSOLoader';
import Select from 'react-select';
import { useGetProjectDetailsQuery, usePostContributorsDataMutation } from "../redux/slices/ProjectPageAPISlice";
import { getUserDetailsFromLocalStorage } from "../Utils/userUtils";

function CreateProject(data) {
  const [loader, setLoader] = useState(false)
  const [editData, setEditData] = useState({});
  const [selectedProjectManager, setSelectedProjectManager] = useState({ value: '', label: '' })
  const [programAreaEnabled, setProgramAreaEnabled] = useState(true)

  const headers = {
    Authorization: `Bearer ${document?.cookie?.split(';')?.find(token => token.startsWith(' $access_token'))?.split('=')[1]}`,
  }

  const { data: projectDetailsData, isLoading, isError, isSuccess } = useGetProjectDetailsQuery({ projectId: data?.editData?.id }, {
    skip: !data?.editData?.id
  })
  const findUserFromProjectData = useMemo(() => {
    return (user_id) => {
      const userObj = projectDetailsData?.userdetails.find((item) => item.id === user_id);
      return userObj ? `${userObj.first_name} ${userObj.last_name}` : '';
    };
  }, [projectDetailsData]);
  useEffect(() => {
    if (data?.editData?.id && isSuccess) {
      console.log(projectDetailsData);
      setSelectedProjectManager({
        value: projectDetailsData?.data?.contributor_list[0]?.user_id,
        label: findUserFromProjectData(projectDetailsData?.data?.contributor_list[0]?.user_id)
      })
    }
  }, [isLoading, isError, isSuccess])

  function CheckAllKeyValuePairs(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if ((typeof value === 'string' && value.trim().length === 0)) {
          console.log(key);

          return false;
        }
      }
    }
    return true;
  }
  let initialData = {
    sector_id: '',
    industry: '',
    title_name: '',
    wipcode: '',
    baseyear: '',
    geograpy_coverage: '',
    short_title: '',
    study_from: '',
    study_to: '',
    currency: '',
    user_id: getUserDetailsFromLocalStorage()?.user_id ? getUserDetailsFromLocalStorage()?.user_id : '',
    go_in_type: '1',
    perspective: "",
    functionalTitle: "",
    subtitle: ""
  }

  const [createProject, setCreateProject] = useState(initialData)
  const [requiredError, setRequiredError] = useState(false)
  const [requiredNumber, setRequiredNumber] = useState(false)
  const [requiredAlphaNumeric, setRequiredAlphaNumeric] = useState(false)
  const [practiceArea, setPracticeArea] = useState([])
  const [programArea, setProgramArea] = useState([])
  const [regions, setRegions] = useState([])

  const navigate = useNavigate();

  const getProgramArea = async (id, name) => {
    let params = {
      industry: name,
      parent_id: id
    }
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsector/${id}/`)
      setProgramArea(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

  function handleChange(e) {
    setRequiredNumber(false)
    setRequiredError(false)
    setRequiredAlphaNumeric(false)

    if (e.target.id == "4digit") {
      if (isNaN(e.target.value)) {
        setRequiredNumber(true)
      } else {
        const { name, value } = e.target;
        setCreateProject(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {

      const { name, value } = e.target;
      if (name == 'industry') {
        getProgramArea(e.target.value, e.target.options[e.target.selectedIndex].innerHTML)
        setCreateProject(prevState => ({
          ...prevState,
          [name]: value,
          sector_id: ''
        }));
        setProgramAreaEnabled(false)
      }
      setCreateProject(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }

  }
  const [postContributorsData] = usePostContributorsDataMutation();

  async function CreateProject() {
    createProject.currency = parseInt(createProject.currency)
    createProject.go_in_type = parseInt(createProject.go_in_type)
    // console.log(isNaN(createProject.wipcode));

    if (selectedProjectManager.value == '') {
      setRequiredError(true)
      return;
    }
    if (!isNaN(createProject.wipcode) || !isNaN(createProject.short_title)) {
      setRequiredAlphaNumeric(true)
      return;
    }

    try {
      setLoader(true)
      setRequiredError(false)
      let checkValues = { ...createProject }
      delete checkValues.perspective;
      delete checkValues.subtitle;
      delete checkValues.functionalTitle;
      if (CheckAllKeyValuePairs(checkValues)) {

        if (Object.keys(editData).length == 0) {
          var response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/createproject`, { ...createProject, user_id: getUserDetailsFromLocalStorage().user_id }, { headers })
        } else {
          let sendData = { ...createProject }
          delete sendData?.updated_by
          var response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${editData?.id}`, { ...sendData, user_id: getUserDetailsFromLocalStorage().user_id })
        }
        setCreateProject(initialData)
        data.reload()
        
        if (Object.keys(editData).length == 0) {
          await postContributorsData({
            projectId: response?.data?.data?.id,
            body: {
              user_id: selectedProjectManager?.value,
              created_by: getUserDetailsFromLocalStorage().user_id,
              role_type: "Project Manager",
            },
          });

          // Navigate to the project page after posting data
          navigate(`/project/${response?.data?.data?.id}`);
          setLoader(false)
          // Reload the window
          window.location.reload();
          return toast.success('Project Created Successfully')
        } else {

          await postContributorsData({
            projectId: data?.editData?.id,
            body: {
              user_id: selectedProjectManager?.value,
              created_by: getUserDetailsFromLocalStorage().user_id,
              role_type: "Project Manager"
            }
          })
          window.location.reload()
          setLoader(false)
          return toast.success('Project Updated Successfully')
        }
      } else {
        setRequiredError(true)
        setLoader(false)
        return toast.error("Something went wrong")

      }
    } catch (error) {
      console.log(error)
      setLoader(false)
      return toast.error(error?.response?.data?.message || 'Something went wrong!Please try again later')

    }
  }
  const getPracticeArea = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getindustry`)
      setPracticeArea(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const [pmList, setPmList] = useState([]);
  const getProjectManager = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/groupsbyuser?slug_name=growth_opportunity_analyst&email=`)
      let options = response.data?.data?.map(user => ({
        value: user.user_id,
        label: `${user.first_name} ${user.last_name}`
      }))
      // console.log(options);
      setPmList(options)
    } catch (error) {
      console.error(error)
    }
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      width: 300,
      height: 20,
      boxShadow: state.isFocused ? '0 0 0 1px #0D6BF7' : null
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: 300
    }),
    option: (provided, state) => ({
      ...provided,
      width: 300,
      backgroundColor: state.isSelected ? '#0D6BF7' : state.isFocused ? '#0D6BF7' : null,
      color: state.isSelected ? 'white' : 'black'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    })
  };
  const getRegions = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getregions`)
      setRegions(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (practiceArea.length == 0) {
      getPracticeArea()
    }
  }, [practiceArea])
  useEffect(() => {
    getRegions()
    getProjectManager()
  }, [])

  useEffect(() => {
    if (data?.editData) {
      setEditData(data?.editData)
      let tempdata = practiceArea?.filter(ele => ele.id == data?.editData?.industry)
      let id = tempdata?.[0]?.id;
      let name = tempdata?.[0]?.name;
      if (id) getProgramArea(id, name)
      console.log(data?.editData);
      let dataS = data?.editData;
      delete dataS?.deleted_by;
      delete dataS?.return_status;
      setCreateProject(dataS)
    }
  }, [data, practiceArea])


  return (
    <>
      {loader ? <SSOLoader /> : null}
      <div className="accordion-item custom-form">
        <h2 className="accordion-header d-none" id="heading1">
          <button className="accordion-button " type="button" data-bs-toggle="collapse" style={{ height: '50px', borderRadius: '5px' }} data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
            &nbsp;&nbsp;Create Project
          </button>
        </h2>
        <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <div className="row mt-2">
              <div className="col-md-4">
                <label class="form-label">Type <span className="spann">*</span></label>
                <select value={createProject.go_in_type} onChange={handleChange} name='go_in_type' class="form-select form-select-sm" aria-label="Default select example">
                  {/* <option value={"0"}>Select Project type</option> */}
                  <option value='1' selected>GO-IN - Industry</option>
                  <option value='2'>GO-3-WEEK-MAX</option>
                </select>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-md-6'>
                <div className="mb-3">
                  <label title="Enter the approved title as created in Production Plan" className="form-label">Title<span className="spann">*</span></label>
                  <input type="text" value={createProject.title_name} onChange={handleChange} name='title_name' placeholder="(Sample - Growth Opportunities in Battery Sector)" className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label title="Enter Short Title - (Sample - Goibs-12)" className="form-label"><i title="This Short title is used for internal purpose instead of title name" className="fa fa-info-circle"></i> Short Title <span className="spann">*</span></label>
                  <input type="text" value={createProject.short_title} onChange={handleChange} name='short_title' placeholder="(Sample - Goibs)" className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label">Geographic Coverage <span className="spann">*</span></label>
                  <select className="form-select form-select-sm" id="" value={createProject.geograpy_coverage} onChange={handleChange} name='geograpy_coverage'>
                    <option value="">Please Select</option>
                    {regions.map((e) => <option key={e?.id} value={e?.id}>{e?.name}</option>)}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label title="Enter product Code - (Sample - TRS5-543)" className="form-label">Product Code <span className="spann">*</span></label>
                  <input type="text" disabled={Object.keys(editData).length !== 0 ?
                    (editData?.updated_by == null && editData?.hasCloned == 1) ? false
                      : true
                    : false} className="form-control form-control-sm" placeholder="(Sample - PFJ8-01-00-00)" value={createProject.wipcode} onChange={handleChange} name='wipcode' />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label title="Enter Base Year - (Sample - 2024)" className="form-label">Base Year <span className="spann">*</span></label>
                  <input className="form-control form-control-sm" id="4digit" value={createProject.baseyear} onChange={handleChange} disabled={Object.keys(editData).length !== 0} name='baseyear' placeholder="(Sample - 2024)" type="text" pattern="^0[1-9]|[1-9]\d$" maxLength="4" />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label">Practice Area <span className="spann">*</span></label>
                  <select className="form-select form-select-sm" id="" value={createProject.industry} onChange={handleChange} name='industry'>
                    <option value="">Please Select</option>
                    {practiceArea.map((e) => <option id={e?.name} key={e?.id} value={e?.id}>{e?.name}</option>)}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label" >Program Area <span className="spann">*</span></label>
                  <select className="form-select form-select-sm" disabled={programAreaEnabled} value={createProject.sector_id} onChange={handleChange} name='sector_id'>
                    <option value="">Please Select</option>
                    {programArea.map((e) => <option value={e?.id} key={e?.id}>{e?.name}</option>)}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label title="Enter Study Period From - (Sample - 2020)" className="form-label" >Study Period From <span className="spann">*</span></label>
                  <input type="text" id="4digit" className="form-control form-control-sm" value={createProject.study_from} disabled={Object.keys(editData).length !== 0} onChange={handleChange} name='study_from' placeholder="(Sample - 2020)" maxLength='4' />
                </div>
              </div>
              <div className='col-md-3 '>
                <div className="mb-3">
                  <label title="Enter Study Period To - (Sample - 2027)" className="form-label">Study Period To <span className="spann">*</span></label>
                  <input type="text" id="4digit" className="form-control form-control-sm" value={createProject.study_to} disabled={Object.keys(editData).length !== 0} onChange={handleChange} name='study_to' placeholder="(Sample - 2027)" maxLength='4' />
                </div>
              </div>

              <div className='col-md-6'>
                <div className="mb-3">
                  <label className="form-label">Monetary Unit <span className="spann">*</span></label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="currency" id="inlineRadio1" value='1' onChange={handleChange} checked={createProject?.currency == '1'} />
                      <label className="form-check-label" htmlFor="inlineRadio1">$ (USD)</label>
                    </div>
                    <div className="form-check form-check-inline ms-3">
                      <input className="form-check-input" type="radio" name="currency" id="inlineRadio2" value='2' onChange={handleChange} checked={createProject?.currency == '2'} />
                      <label className="form-check-label" htmlFor="inlineRadio2">€ (EUR)</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label" >Strategic Imperatives </label>
                  <select className="form-select form-select-sm" id="" name="perspective" onChange={handleChange} value={createProject?.perspective}>
                    <option value="" selected>Select Strategic Imperatives</option>
                    <option value="Innovative Business Models">Innovative Business Models</option>
                    <option value="Customer Value Chain Compression">Customer Value Chain Compression</option>
                    <option value="Transformative Mega Trends">Transformative Mega Trends</option>
                    <option value="Internal Challenges">Internal Challenges</option>
                    <option value="Competitive Intensity">Competitive Intensity</option>
                    <option value="Geopolitical Chaos">Geopolitical Chaos</option>
                    <option value="Disruptive Technologies">Disruptive Technologies</option>
                    <option value="Industry Convergence">Industry Convergence</option>
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label title="Enter Functional Title" className="form-label">Functional Title </label>
                  <input type="text" value={createProject?.functionalTitle} onChange={handleChange} name='functionalTitle' placeholder="Enter Functional Title" className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label title="Enter Sub Title" className="form-label">Sub Title </label>
                  <input type="text" value={createProject?.subtitle} onChange={handleChange} name='subtitle' placeholder="Enter Sub Title" className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-md-3'>
                <div class="">
                  <label class="form-label">Select Project Manager <span className="spann">*</span></label>

                  <Select
                    className="form-select-sm ps-0"
                    placeholder="Select Project Manager"
                    value={selectedProjectManager || null}
                    onChange={(e) => {
                      setRequiredError(false),
                        setSelectedProjectManager(e)
                      console.log(e);
                    }}
                    options={pmList}
                    styles={customStyles}
                    isSearchable
                  />
                </div>
              </div>
            </div>
            {requiredError ? <span style={{ color: 'red' }}>All fields are required</span> : null}
            {requiredNumber ? <span style={{ color: 'red' }}>Please input number</span> : null}
            {requiredAlphaNumeric ? <span style={{ color: 'red' }}>Only numbers not allowed in product code and short title</span> : null}

            <div className="d-flex justify-content-end">
              <button type="button" onClick={CreateProject} className="btn btn-primary btn-sm me-2"><i className="fa fa-save me-1"></i>{Object.keys(editData).length == 0 ? "Create" : "Update"}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateProject
