import React, { useEffect, useState, useContext } from 'react'
import '../src/layout/CSS/TotalMarketAnalysis.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import SSOLoader from './Components/SSOLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import SplitModel from './SplitModel'
import { ProjectStatusContext } from './Providers/ProjectStatusProvider';
import { useUpdateTocIsSavedMutation } from './redux/slices/ProjectPageAPISlice'
import { ProgressContext } from './Providers/ProgressProvider'
import { useParams } from 'react-router-dom'

const TotalMarketAnalysis = ({
    parentNode_Id,
    dnrDataSet,
    isForecastCompleted,
    tocId
}) => {
    const [rows, setRows] = useState([])
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [col, setCol] = useState([])
    const [currentPair, setCurrentPair] = useState([])
    const [loader, setLoader] = useState(false)
    const [inputValues, setInputValues] = useState({})
    const [openSplitModel, setOpenSplitModel] = useState(0)
    const [calculatedValues, setCalculatedValues] = useState({})
    const [market_type, setMarket_type] = useState('')
    const [unitCurrency, setUnitCurrency] = useState({
        unit: 'millions',
        currency: 'USD',
    })
    const [dummy, setDummy] = useState(0)
    const [finetuneData, setFinetuneData] = useState({
        revenue_drivers: '',
        revenue_restraints: '',
        units_drivers: '',
        units_restraints: '',
        price_drivers: '1.20',
        price_restraints: '1.20',
    })
    const [dnrData, setDnrData] = useState({})
    const [parentNodeId, setParentNodeId] = useState(parentNode_Id)
    useEffect(() => {
        setParentNodeId(parentNode_Id)
    }, [isForecastCompleted, parentNode_Id])
    const [driverRestraintValues, setDriverRestraintValues] = useState({
        unitsDrivers: {},
        unitsRestraints: {},
        priceDrivers: {},
        priceRestraints: {},
        revenueDrivers: {},
        revenueRestraints: {},
    })
    let [forecastOutputData, setForecastOutputData] = useState([])
    const [driverRestraintNames, setDriverRestraintNames] = useState({
        unitsDrivers: {},
        unitsRestraints: {},
        priceDrivers: {},
        priceRestraints: {},
        revenueDrivers: {},
        revenueRestraints: {},
    })
    const [count, setCount] = useState({
        unitsDrivers: '4',
        unitsRestraints: '4',
        priceDrivers: '4',
        priceRestraints: '4',
        revenueDrivers: '4',
        revenueRestraints: '4',
    })

    const handleInputChange = (year, column, value) => {
        const updatedValues = { ...inputValues, [`${year}-${column}`]: value }
        setInputValues(updatedValues)

        let filledColumns = [...currentPair]

        if (value) {
            if (!filledColumns.includes(column)) {
                filledColumns.push(column)
            }
            if (column === 'revenue' && filledColumns.length === 1) {
                filledColumns = ['revenue']
            }
        } else {
            filledColumns = filledColumns.filter((col) => col !== column)
        }

        if (filledColumns.length > 2) {
            filledColumns = filledColumns.slice(0, 2)
        }

        setCurrentPair(filledColumns)
    }
    const isEmpty = (obj) => {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false
            }
        }

        return true
    }
    const isNestedEmpty = (obj) => {
        let count = 0

        for (const prop in obj) {
            if (Object.keys(obj[prop]).length > 0) {
                count++
            }
        }
        if (count > 2) {
            return false
        } else {
            return true
        }
    }

    const isReadOnly = (column) => {
        if (currentPair.includes('revenue') && currentPair.length === 1) {
            return column !== 'revenue'
        }
        if (currentPair.length >= 2) {
            return !currentPair.includes(column)
        }
        return false
    }

    const addNoImpact = (obj, years) => {
        // Iterate through each category in the object
        for (const category in obj) {
            // Iterate through each step in the category
            for (const step in obj[category]) {
                // Get the year dictionary for the current step
                const yearDict = obj[category][step]
                // Check for each year in the given array
                years.forEach((year) => {
                    // If the year is not present in the current step's dictionary, add it with "No impact"
                    if (!yearDict.hasOwnProperty(year)) {
                        yearDict[year] = 'No Impact'
                    }
                })
            }
        }
        return obj
    }

    useEffect(() => {
        if (currentPair.length > 0 && market_type.length < 0 && (finetuneData?.units_drivers == '' || finetuneData?.revenue_drivers == '')) {
            if (currentPair[0] == 'revenue' && !currentPair[1]) {
                setFinetuneData((prevState) => ({
                    ...prevState,
                    units_drivers: '',
                    units_restraints: '',
                    price_drivers: '',
                    price_restraints: '',
                }))
            } else if (
                currentPair.includes('price') &&
                currentPair.includes('units')
            ) {
                setFinetuneData((prevState) => ({
                    ...prevState,
                    revenue_drivers: '',
                    revenue_restraints: '',
                }))
            } else if (
                currentPair.includes('price') &&
                currentPair.includes('revenue')
            ) {
                setFinetuneData((prevState) => ({
                    ...prevState,
                    units_drivers: '',
                    units_restraints: '',
                }))
            } else if (
                currentPair.includes('units') &&
                currentPair.includes('revenue')
            ) {
                setFinetuneData((prevState) => ({
                    ...prevState,
                    price_drivers: '',
                    price_restraints: '',
                }))
            }
        }
    }, [currentPair, market_type])

    const handleDriverRestraintChange = (type, index, year, value) => {
        setDriverRestraintValues((prevValues) => {
            const updatedValues = { ...prevValues }
            if (!updatedValues[type][index]) {
                updatedValues[type][index] = {}
            }
            updatedValues[type][index][year] = value
            return updatedValues
        })
    }

    const handleDriverRestraintNameChange = (type, index, value) => {
        setDriverRestraintNames((prevNames) => {
            const updatedNames = { ...prevNames }
            if (!updatedNames[type][index]) {
                updatedNames[type][index] = ''
            }
            updatedNames[type][index] = value
            return updatedNames
        })
    }

    function addNextDriverName(obj, key) {
        const subObj = obj[key]
        const keys = Object.keys(subObj).map(Number)
        const maxKey = Math.max(...keys)
        const lastElementName = subObj[maxKey]
        const newElementName = lastElementName.replace(
            /\d+$/,
            (match) => Number(match) + 1
        )
        subObj[maxKey + 1] = newElementName
        return obj
    }
    const addRow = (value) => {
        const keys = Object.keys(driverRestraintValues[value])
        let lastObject = { ...driverRestraintValues[value][keys[keys.length - 1]] }
        Object.keys(lastObject)?.map((ele) => (lastObject[ele] = 'No Impact'))
        let updatedValues = { ...driverRestraintValues[value] }
        updatedValues[keys.length] = lastObject
        setDriverRestraintNames(
            addNextDriverName({ ...driverRestraintNames }, value)
        )
        setDriverRestraintValues((prev) => ({
            ...prev,
            [value]: updatedValues,
        }))
        setCount((prevState) => ({
            ...prevState,
            [value]: parseInt(prevState[value]) + 1,
        }))
    }

    const deleteAndRekeyName = (obj, property, index) => {
        const subObj = obj[property]
        const entries = Object.entries(subObj)
        entries.splice(index, 1)
        const newSubObj = entries.reduce((acc, [key, value], i) => {
            acc[i] = value
            return acc
        }, {})
        obj[property] = newSubObj
        return obj
    }
    const deleteRow = (value, index) => {
        if (parseInt(count[value]) <= 4) {
            toast.error('Minimum 4 drivers/restraints required')
        } else {
            setCount((prevState) => ({
                ...prevState,
                [value]: parseInt(prevState[value]) - 1,
            }))
            setDriverRestraintValues(
                deleteAndRekeyName({ ...driverRestraintValues }, value, index)
            )
            setDriverRestraintNames(
                deleteAndRekeyName({ ...driverRestraintNames }, value, index)
            )
        }
    }

    function replaceIndicesWithNames(obj1, obj2) {
        // console.log(obj1);
        // console.log(obj2);
        const result = {}

        for (const key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                result[key] = {}
                for (const subKey in obj1[key]) {
                    if (obj1[key].hasOwnProperty(subKey) && obj2[key]) {
                        const newSubKey = obj2[key][subKey]
                        result[key][newSubKey] = obj1[key][subKey]
                    } else {
                        result[key][subKey] = obj1[key][subKey]
                    }
                }
            }
        }

        return result
    }
    // function replaceIndicesWithNames(obj1, obj2) {
    //     const result = {};

    //     for (const key in obj1) {
    //         if (obj1.hasOwnProperty(key)) {
    //             result[key] = {};
    //             for (const subKey in obj1[key]) {
    //                 if (obj1[key].hasOwnProperty(subKey)) {
    //                     if (obj2[key] && obj2[key][subKey]) {
    //                         const newSubKey = obj2[key][subKey];
    //                         result[key][newSubKey] = obj1[key][subKey];
    //                     } else {
    //                         result[key][subKey] = obj1[key][subKey];
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     return result;

    // }

    const handleMarketType = (e) => {
        let value = e.target.value
        setMarket_type(value)
        if (value == 'Decline') {
            setFinetuneData({
                revenue_drivers: '1.10',
                revenue_restraints: '1.20',
                units_drivers: '1.10',
                units_restraints: '1.20',
                price_drivers: '1.20',
                price_restraints: '1.20',
            })
        } else if (value == 'Mature') {
            setFinetuneData({
                revenue_drivers: '1.20',
                revenue_restraints: '1.20',
                units_drivers: '1.30',
                units_restraints: '1.30',
                price_drivers: '1.20',
                price_restraints: '1.20',
            })
        } else if (value == 'Low Growth') {
            setFinetuneData({
                revenue_drivers: '1.30',
                revenue_restraints: '1.30',
                units_drivers: '1.50',
                units_restraints: '1.50',
                price_drivers: '1.20',
                price_restraints: '1.20',
            })
        } else if (value == 'Medium Growth') {
            setFinetuneData({
                revenue_drivers: '1.50',
                revenue_restraints: '1.50',
                units_drivers: '1.65',
                units_restraints: '1.65',
                price_drivers: '1.20',
                price_restraints: '1.20',
            })
        } else if (value == 'High Growth') {
            setFinetuneData({
                revenue_drivers: '1.80',
                revenue_restraints: '1.80',
                units_drivers: '1.80',
                units_restraints: '1.80',
                price_drivers: '1.20',
                price_restraints: '1.20',
            })
        }
    }

    const getUserDetailsFromLocalStorage = () => {
        const userDetailsString = sessionStorage.getItem('userDetails')
        if (userDetailsString) {
            return JSON.parse(userDetailsString)
        } else {
            return null
        }
    }

    const saveHistoricData = async () => { }
    const handleCalculate = async () => {
        if (market_type) {
            setLoader(true)
            const newValues = { ...inputValues }
            const finalValues = {}
            rows.forEach((year, index) => {
                const units = parseFloat(inputValues[`${year}-units`]) || ''
                const price = parseFloat(inputValues[`${year}-price`]) || ''
                const revenue = parseFloat(inputValues[`${year}-revenue`]) || ''
                let calculatedUnits = units
                let calculatedPrice = price
                let calculatedRevenue = revenue
                let yoyGrowth = ''
                let priceGrowth = ''
                let unitsGrowth = ''

                if (currentPair.includes('units') && currentPair.includes('price')) {
                    calculatedRevenue = (units * price).toFixed(2)
                    newValues[`${year}-revenue`] = calculatedRevenue
                } else if (
                    currentPair.includes('price') &&
                    currentPair.includes('revenue')
                ) {
                    calculatedUnits = (revenue / price).toFixed(2)
                    newValues[`${year}-units`] = calculatedUnits
                } else if (
                    currentPair.includes('units') &&
                    currentPair.includes('revenue')
                ) {
                    calculatedPrice = (revenue / units).toFixed(2)
                    newValues[`${year}-price`] = calculatedPrice
                } else if (
                    currentPair.includes('revenue') &&
                    currentPair.length === 1
                ) {
                    newValues[`${year}-units`] = ''
                    newValues[`${year}-price`] = ''
                }

                if (index > 0) {
                    const prevYear = rows[index - 1]
                    const prevRevenue =
                        parseFloat(
                            newValues[`${prevYear}-revenue`] ||
                            inputValues[`${prevYear}-revenue`]
                        ) || ''
                    const prevPrice =
                        parseFloat(
                            newValues[`${prevYear}-price`] || inputValues[`${prevYear}-price`]
                        ) || ''
                    const prevUnits =
                        parseFloat(
                            newValues[`${prevYear}-units`] || inputValues[`${prevYear}-units`]
                        ) || ''

                    if (prevRevenue) {
                        yoyGrowth =
                            (((calculatedRevenue - prevRevenue) / prevRevenue) * 100).toFixed(
                                2
                            ) + '%'
                    }
                    if (prevPrice) {
                        priceGrowth =
                            (((calculatedPrice - prevPrice) / prevPrice) * 100).toFixed(2) +
                            '%'

                    }
                    if (prevUnits) {
                        unitsGrowth =
                            (((calculatedUnits - prevUnits) / prevUnits) * 100).toFixed(2) +
                            '%'
                    }
                }

                finalValues[year] = {
                    units: calculatedUnits || '',
                    price: calculatedPrice || '',
                    revenue: calculatedRevenue || '',
                    unit_units: unitCurrency?.unit || '',
                    price_units: unitCurrency?.currency || '',
                    revenue_units: unitCurrency?.unit || '',
                    yoyGrowth: yoyGrowth,
                    price_growth: priceGrowth,
                    unit_growth: unitsGrowth
                }
            })
            setInputValues(newValues)
            setCalculatedValues(finalValues)
            saveHistoricData()

            try {
                const url = window.location.href
                const segments = url.split('/').pop()
                const id = segments
                let postData = {
                    user_id: getUserDetailsFromLocalStorage()?.user_id,
                    seg_id: parentNode_Id,
                    dr_type: 'dr',
                    formula_type:
                        currentPair.length > 1
                            ? currentPair[0] + '_' + currentPair[1]
                            : currentPair[0],
                    market_type: market_type,
                    finetune_data: finetuneData,
                    historic_data: finalValues,

                }
                let response = await axios.post(
                    `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}/saveHistoricdata`,
                    postData
                )

                if (response?.data?.data == 'Created DR Model!') {
                    toast.success(
                        'Drivers and restraints model created,Proceed to 2nd step'
                    )
                }
                setLoader(false)
                if (isNestedEmpty(driverRestraintNames)) {
                    setDriverRestraintNames(generateInitialNameState())
                    setDriverRestraintValues(generateInitialValueState())
                }
            } catch (error) {
                console.log(error)
                setLoader(false)
                toast.error('Something went wrong')
            }
        } else {
            toast.error('Please select market type')
        }
    }

    const calculateForecastData = (argument1) => {
        let finetune_data = finetuneData
        let impact_arr = [
            'No Impact',
            'Very Low',
            'Low',
            'Low/Medium',
            'Medium',
            'Medium/High',
            'High',
            'Very High',
        ]
        let historic_year = rows
        let forecast_year = col

        let no_of_unitdrivers_count = parseInt(count.unitsDrivers)
        let no_of_unitrestraints_count = parseInt(count.unitsRestraints)
        let no_of_pricedrivers_count = parseInt(count.priceDrivers)
        let no_of_pricerestraints_count = parseInt(count.priceRestraints)
        let no_of_revenuedrivers_count = parseInt(count.revenueDrivers)
        let no_of_revenuerestraints_count = parseInt(count.revenueRestraints)

        let drivers_restrainsts_data = argument1
        let historic_data = calculatedValues

        //Drivers and restarints sum data for Impact Array calculation
        let unit_drivers_sum = ''
        let price_drivers_sum = ''
        let revenue_drivers_sum = ''
        let unit_restraint_sum = ''
        let price_restraint_sum = ''
        let revenue_restraint_sum = ''
        //Fine tune datavariables
        let unit_drivers_fine_tune = {}
        let price_drivers_fine_tune = {}
        let revenue_drivers_fine_tune = {}
        let unit_restraints_fine_tune = {}
        let price_restraints_fine_tune = {}
        let revenue_restraints_fine_tune = {}

        let sumRevenueDriversByYear = {}
        let sumRevenueRestraintsByYear = {}
        let sumUnitDriversByYear = {}
        let sumUnitRestraintsByYear = {}
        let sumPriceDriversByYear = {}
        let sumPriceRestraintsByYear = {}
        const cleanData = (data) => {
            const cleanedData = {}

            Object.keys(data).forEach((year) => {
                const yearData = data[year]
                const cleanedYearData = { ...yearData }

                // Clean unit_growth
                if (cleanedYearData.unit_growth) {
                    cleanedYearData.unit_growth = parseFloat(
                        cleanedYearData.unit_growth
                    )
                }

                // Clean units
                cleanedYearData.units = parseFloat(cleanedYearData.units)

                // Clean price
                cleanedYearData.price = parseFloat(cleanedYearData.price)

                // Clean revenue
                cleanedYearData.revenue = parseFloat(cleanedYearData.revenue)

                // Clean yoyGrowth
                if (cleanedYearData.yoyGrowth) {
                    cleanedYearData.yoyGrowth = parseFloat(
                        cleanedYearData.yoyGrowth
                    )
                }

                // Clean price_growth
                if (cleanedYearData.price_growth) {
                    cleanedYearData.price_growth = parseFloat(
                        cleanedYearData.price_growth
                    )
                }

                cleanedData[year] = cleanedYearData
            })

            return cleanedData
        }

        //Finetune data Calculation
        function finetunedata() {
            unit_drivers_fine_tune = {
                'Very High': Math.round(finetune_data['units_drivers'] * 100) / 100, // Fine-tuned factor for "Very High"
                'No Impact': 1, // Baseline factor for "No Impact"
            }

            price_drivers_fine_tune = {
                'Very High': Math.round(finetune_data['price_drivers'] * 100) / 100, // Fine-tuned factor for "Very High"
                'No Impact': 1, // Baseline factor for "No Impact"
            }
            revenue_drivers_fine_tune = {
                'Very High': Math.round(finetune_data['revenue_drivers'] * 100) / 100, // Fine-tuned factor for "Very High"
                'No Impact': 1, // Baseline factor for "No Impact"
            }
            unit_restraints_fine_tune = {
                'Very High':
                    2 - Math.round(finetune_data['units_restraints'] * 100) / 100, // Fine-tuned factor for "Very High"
                'No Impact': 1, // Baseline factor for "No Impact"
            }
            price_restraints_fine_tune = {
                'Very High':
                    2 - Math.round(finetune_data['price_restraints'] * 100) / 100, // Fine-tuned factor for "Very High"
                'No Impact': 1, // Baseline factor for "No Impact"
            }
            revenue_restraints_fine_tune = {
                'Very High':
                    2 - Math.round(finetune_data['revenue_restraints'] * 100) / 100, // Fine-tuned factor for "Very High"
                'No Impact': 1, // Baseline factor for "No Impact"
            }

            unit_drivers_sum =
                Math.round(
                    ((unit_drivers_fine_tune['Very High'] -
                        unit_drivers_fine_tune['No Impact']) /
                        7) *
                    100
                ) / 100
            price_drivers_sum =
                Math.round(
                    ((price_drivers_fine_tune['Very High'] -
                        price_drivers_fine_tune['No Impact']) /
                        7) *
                    100
                ) / 100
            revenue_drivers_sum =
                Math.round(
                    ((revenue_drivers_fine_tune['Very High'] -
                        revenue_drivers_fine_tune['No Impact']) /
                        7) *
                    100
                ) / 100
            unit_restraint_sum =
                Math.round(
                    ((unit_restraints_fine_tune['Very High'] -
                        unit_restraints_fine_tune['No Impact']) /
                        7) *
                    100
                ) / 100
            price_restraint_sum =
                Math.round(
                    ((price_restraints_fine_tune['Very High'] -
                        price_restraints_fine_tune['No Impact']) /
                        7) *
                    100
                ) / 100
            revenue_restraint_sum =
                Math.round(
                    ((revenue_restraints_fine_tune['Very High'] -
                        revenue_restraints_fine_tune['No Impact']) /
                        7) *
                    100
                ) / 100

            for (let i = 0; i < impact_arr.length; i++) {
                let impact_level = impact_arr[i]
                if (impact_level === 'No Impact' || impact_level === 'Very High') {
                    unit_drivers_fine_tune[impact_level] =
                        Math.round(unit_drivers_fine_tune[impact_level] * 100) / 100
                    price_drivers_fine_tune[impact_level] =
                        Math.round(price_drivers_fine_tune[impact_level] * 100) / 100
                    revenue_drivers_fine_tune[impact_level] =
                        Math.round(revenue_drivers_fine_tune[impact_level] * 100) / 100
                    unit_restraints_fine_tune[impact_level] =
                        Math.round(unit_restraints_fine_tune[impact_level] * 100) / 100
                    price_restraints_fine_tune[impact_level] =
                        Math.round(price_restraints_fine_tune[impact_level] * 100) / 100
                    revenue_restraints_fine_tune[impact_level] =
                        Math.round(revenue_restraints_fine_tune[impact_level] * 100) / 100
                } else {
                    unit_drivers_fine_tune[impact_level] =
                        Math.round(
                            (parseFloat(unit_drivers_fine_tune[impact_arr[i - 1]]) +
                                parseFloat(unit_drivers_sum)) *
                            100
                        ) / 100
                    price_drivers_fine_tune[impact_level] =
                        Math.round(
                            (parseFloat(price_drivers_fine_tune[impact_arr[i - 1]]) +
                                parseFloat(price_drivers_sum)) *
                            100
                        ) / 100
                    revenue_drivers_fine_tune[impact_level] =
                        Math.round(
                            (parseFloat(revenue_drivers_fine_tune[impact_arr[i - 1]]) +
                                parseFloat(revenue_drivers_sum)) *
                            100
                        ) / 100
                    unit_restraints_fine_tune[impact_level] =
                        Math.round(
                            (parseFloat(unit_restraints_fine_tune[impact_arr[i - 1]]) +
                                parseFloat(unit_restraint_sum)) *
                            100
                        ) / 100
                    price_restraints_fine_tune[impact_level] =
                        Math.round(
                            (parseFloat(price_restraints_fine_tune[impact_arr[i - 1]]) +
                                parseFloat(price_restraint_sum)) *
                            100
                        ) / 100
                    revenue_restraints_fine_tune[impact_level] =
                        Math.round(
                            (parseFloat(revenue_restraints_fine_tune[impact_arr[i - 1]]) +
                                parseFloat(revenue_restraint_sum)) *
                            100
                        ) / 100
                }
            }

            // Iterate over the revenue drivers data
            if (Object.keys(drivers_restrainsts_data?.revenueDrivers).length != 0) {
                forecast_year.forEach((year) => {
                    for (let rvdata in drivers_restrainsts_data.revenueDrivers) {
                        if (!sumRevenueDriversByYear[year]) {
                            sumRevenueDriversByYear[year] = 0
                        }
                        sumRevenueDriversByYear[year] +=
                            revenue_drivers_fine_tune[
                            drivers_restrainsts_data.revenueDrivers[rvdata][year]
                            ]
                    }
                })
            }
            if (Object.keys(drivers_restrainsts_data.revenueRestraints).length != 0) {
                forecast_year.forEach((year) => {
                    for (let rvdata in drivers_restrainsts_data.revenueRestraints) {
                        if (!sumRevenueRestraintsByYear[year]) {
                            sumRevenueRestraintsByYear[year] = 0
                        }
                        sumRevenueRestraintsByYear[year] +=
                            revenue_restraints_fine_tune[
                            drivers_restrainsts_data.revenueRestraints[rvdata][year]
                            ]
                    }
                })
            }

            // Iterate over the unit drivers data
            if (Object.keys(drivers_restrainsts_data?.unitsDrivers).length != 0) {
                forecast_year.forEach((year) => {
                    for (let rvdata in drivers_restrainsts_data.unitsDrivers) {
                        if (!sumUnitDriversByYear[year]) {
                            sumUnitDriversByYear[year] = 0
                        }
                        sumUnitDriversByYear[year] +=
                            unit_drivers_fine_tune[
                            drivers_restrainsts_data.unitsDrivers[rvdata][year]
                            ]
                    }
                })
            }

            // Iterate over the unit drivers data
            if (Object.keys(drivers_restrainsts_data.unitsRestraints).length != 0) {
                forecast_year.forEach((year) => {
                    for (let rvdata in drivers_restrainsts_data.unitsRestraints) {
                        if (!sumUnitRestraintsByYear[year]) {
                            sumUnitRestraintsByYear[year] = 0
                        }
                        sumUnitRestraintsByYear[year] +=
                            unit_restraints_fine_tune[
                            drivers_restrainsts_data.unitsRestraints[rvdata][year]
                            ]
                    }
                })
            }

            if (Object.keys(drivers_restrainsts_data.priceDrivers).length != 0) {
                forecast_year.forEach((year) => {
                    for (let rvdata in drivers_restrainsts_data.priceDrivers) {
                        if (!sumPriceDriversByYear[year]) {
                            sumPriceDriversByYear[year] = 0
                        }
                        sumPriceDriversByYear[year] +=
                            price_drivers_fine_tune[
                            drivers_restrainsts_data.priceDrivers[rvdata][year]
                            ]
                    }
                })
            }

            if (Object.keys(drivers_restrainsts_data.priceRestraints).length != 0) {
                forecast_year.forEach((year) => {
                    for (let rvdata in drivers_restrainsts_data.priceRestraints) {
                        if (!sumPriceRestraintsByYear[year]) {
                            sumPriceRestraintsByYear[year] = 0
                        }
                        sumPriceRestraintsByYear[year] +=
                            price_restraints_fine_tune[
                            drivers_restrainsts_data.priceRestraints[rvdata][year]
                            ]
                    }
                })
            }
        }

        // Function to calculate growth rate between two values
        function calculateGrowthRate(value1, value2) {
            return Math.round((value2 / value1 - 1) * 100 * 100) / 100
        }

        // Function to calculate growth rate between two values
        function calculateRevenue(value1, value2) {
            return Math.round(value2 * value1 * 100) / 100
        }

        // Function to calculate Unit ?price between two values
        function calculateUnitPrice(value1, value2) {
            return Math.round((value2 / value1) * 100) / 100
        }

        let overall_data = []
        let forecast_data = {}
        col.forEach((year) => {
            forecast_data[year] = {
                units: null,
                unit_growth: null,
                unit_units: null,
                price: null,
                price_growth: null,
                price_units: null,
                revenue: null,
                yoyGrowth: null,
                revenue_units: null,
                type: 'forecast',
                market_share_value: null,
                year: year.toString(),
            }
        })

        //Forecast Calculation
        function forecastCalculation(formula_type) {
            let grwthyeardata = {}
            let revenue_calculation = {}
            let grwthpricedata = {}
            let grwthrevenuedata = {}
            let unit_calculation = {}
            let price_calculation = {}

            if (formula_type == 'revenue') {
                let index = 0
                for (let yearData in forecast_data) {
                    let data = forecast_data[yearData]

                    if (index == 0) { grwthyeardata = historic_data[yearData - 1]['revenue'] }
                    else { grwthyeardata = forecast_data[yearData - 1]['revenue'] }

                    (revenue_calculation =
                        (Math.round(sumRevenueDriversByYear[data.year] * 100) / 100 -
                            no_of_revenuedrivers_count +
                            Math.round(sumRevenueRestraintsByYear[data.year] * 100) / 100 -
                            no_of_revenuerestraints_count +
                            1) *
                        parseFloat(grwthyeardata)),
                        (forecast_data[yearData]['units'] = ''),
                        (forecast_data[yearData]['unit_growth'] = ''),
                        (forecast_data[yearData]['unit_units'] = ''),
                        (forecast_data[yearData]['price'] = ''),
                        (forecast_data[yearData]['price_growth'] = ''),
                        (forecast_data[yearData]['price_units'] = ''),
                        (forecast_data[yearData]['revenue'] =
                            Math.round(revenue_calculation * 100) / 100),
                        (forecast_data[yearData]['yoyGrowth'] = calculateGrowthRate(
                            parseFloat(grwthyeardata),
                            parseFloat(forecast_data[yearData]['revenue'])
                        )),
                        (forecast_data[yearData]['revenue_units'] = data.revenue_units),
                        (forecast_data[yearData]['type'] = data.type),
                        (forecast_data[yearData]['year'] = data.year)
                    index++
                }
            }
            if (formula_type == 'units_price' || formula_type == 'price_units') {
                let index = 0
                for (let yearData in forecast_data) {
                    let data = forecast_data[yearData]

                    const prevYearData = forecast_data[yearData - 1]
                    if (index == 0) {
                        grwthyeardata = historic_data[yearData - 1]['units']
                        grwthpricedata = historic_data[yearData - 1]['price']
                        grwthrevenuedata = historic_data[yearData - 1]['revenue']
                    } else {
                        grwthyeardata = prevYearData.units
                        grwthpricedata = prevYearData.price
                        grwthrevenuedata = prevYearData.revenue
                    }

                    ; (unit_calculation =
                        Math.round(
                            ((Math.round(sumUnitDriversByYear[data.year] * 100) / 100 +
                                Math.round(sumUnitRestraintsByYear[data.year] * 100) / 100) /
                                (no_of_unitdrivers_count + no_of_unitrestraints_count)) *
                            parseFloat(grwthyeardata) *
                            100
                        ) / 100),
                        (price_calculation =
                            Math.round(
                                ((Math.round(sumPriceDriversByYear[data.year] * 100) / 100 +
                                    Math.round(sumPriceRestraintsByYear[data.year] * 100) / 100) /
                                    (no_of_pricedrivers_count + no_of_pricerestraints_count)) *
                                parseFloat(grwthpricedata) *
                                100
                            ) / 100),
                        (forecast_data[yearData]['units'] = unit_calculation),
                        (forecast_data[yearData]['unit_growth'] = calculateGrowthRate(
                            parseFloat(grwthyeardata),
                            parseFloat(unit_calculation)
                        )),
                        (forecast_data[yearData]['unit_units'] = ''),
                        (forecast_data[yearData]['price'] = price_calculation),
                        (forecast_data[yearData]['price_growth'] = calculateGrowthRate(
                            parseFloat(grwthpricedata),
                            parseFloat(price_calculation)
                        )),
                        (forecast_data[yearData]['price_units'] = ''),
                        (forecast_data[yearData]['revenue'] = calculateRevenue(
                            unit_calculation,
                            price_calculation
                        )),
                        (forecast_data[yearData]['yoyGrowth'] = calculateGrowthRate(
                            parseFloat(grwthrevenuedata),
                            parseFloat(forecast_data[yearData]['revenue'])
                        )),
                        (forecast_data[yearData]['revenue_units'] = data.revenue_units),
                        (forecast_data[yearData]['type'] = data.type),
                        (forecast_data[yearData]['year'] = data.year)
                    index++
                }
            }
            if (formula_type == 'price_revenue' || formula_type == 'revenue_price') {
                let index = 0
                for (let yearData in forecast_data) {
                    let data = forecast_data[yearData]

                    const prevYearData = forecast_data[yearData - 1]
                    if (index == 0) {
                        grwthyeardata = historic_data[yearData - 1]['units']
                        grwthpricedata = historic_data[yearData - 1]['price']
                        grwthrevenuedata = historic_data[yearData - 1]['revenue']
                    } else {
                        grwthyeardata = prevYearData.units
                        grwthpricedata = prevYearData.price
                        grwthrevenuedata = prevYearData.revenue
                    }

                    ; (price_calculation =
                        Math.round(
                            ((Math.round(sumPriceDriversByYear[data.year] * 100) / 100 +
                                Math.round(sumPriceRestraintsByYear[data.year] * 100) / 100) /
                                (no_of_pricedrivers_count + no_of_pricerestraints_count)) *
                            parseFloat(grwthpricedata) *
                            100
                        ) / 100),
                        (revenue_calculation =
                            Math.round(
                                (Math.round(sumRevenueDriversByYear[data.year] * 100) / 100 -
                                    no_of_revenuedrivers_count +
                                    Math.round(sumRevenueRestraintsByYear[data.year] * 100) /
                                    100 -
                                    no_of_revenuerestraints_count +
                                    1) *
                                parseFloat(grwthrevenuedata) *
                                100
                            ) / 100),
                        (forecast_data[yearData]['units'] = calculateUnitPrice(
                            parseFloat(price_calculation),
                            parseFloat(revenue_calculation)
                        )),
                        (forecast_data[yearData]['unit_growth'] = calculateGrowthRate(
                            parseFloat(grwthyeardata),
                            parseFloat(forecast_data[yearData]['units'])
                        )),
                        (forecast_data[yearData]['unit_units'] = ''),
                        (forecast_data[yearData]['price'] = price_calculation),
                        (forecast_data[yearData]['price_growth'] = calculateGrowthRate(
                            parseFloat(grwthpricedata),
                            parseFloat(price_calculation)
                        )),
                        (forecast_data[yearData]['price_units'] = ''),
                        (forecast_data[yearData]['revenue'] = revenue_calculation),
                        (forecast_data[yearData]['yoyGrowth'] = calculateGrowthRate(
                            parseFloat(grwthrevenuedata),
                            parseFloat(forecast_data[yearData]['revenue'])
                        )),
                        (forecast_data[yearData]['revenue_units'] = data.revenue_units),
                        (forecast_data[yearData]['type'] = data.type),
                        (forecast_data[yearData]['year'] = data.year)
                    index++
                }
            }
            if (formula_type == 'units_revenue' || formula_type == 'revenue_units') {
                let index = 0
                for (let yearData in forecast_data) {
                    let data = forecast_data[yearData]

                    const prevYearData = forecast_data[yearData - 1]
                    if (index == 0) {
                        grwthyeardata = historic_data[yearData - 1]['units']
                        grwthpricedata = historic_data[yearData - 1]['price']
                        grwthrevenuedata = historic_data[yearData - 1]['revenue']
                    } else {
                        grwthyeardata = prevYearData.units
                        grwthpricedata = prevYearData.price
                        grwthrevenuedata = prevYearData.revenue
                    }

                    ; (unit_calculation =
                        Math.round(
                            ((Math.round(sumUnitDriversByYear[data.year] * 100) / 100 +
                                Math.round(sumUnitRestraintsByYear[data.year] * 100) / 100) /
                                (no_of_unitdrivers_count + no_of_unitrestraints_count)) *
                            parseFloat(grwthyeardata) *
                            100
                        ) / 100),
                        (revenue_calculation =
                            Math.round(
                                (Math.round(sumRevenueDriversByYear[data.year] * 100) / 100 -
                                    no_of_revenuedrivers_count +
                                    Math.round(sumRevenueRestraintsByYear[data.year] * 100) /
                                    100 -
                                    no_of_revenuerestraints_count +
                                    1) *
                                parseFloat(grwthrevenuedata) *
                                100
                            ) / 100),
                        (forecast_data[yearData]['units'] = unit_calculation),
                        (forecast_data[yearData]['unit_growth'] = calculateGrowthRate(
                            parseFloat(grwthyeardata),
                            parseFloat(unit_calculation)
                        )),
                        (forecast_data[yearData]['unit_units'] = ''),
                        (forecast_data[yearData]['price'] = calculateUnitPrice(
                            parseFloat(unit_calculation),
                            parseFloat(revenue_calculation)
                        )),
                        (forecast_data[yearData]['price_growth'] = calculateGrowthRate(
                            parseFloat(grwthpricedata),
                            parseFloat(forecast_data[yearData]['price'])
                        )),
                        (forecast_data[yearData]['price_units'] = ''),
                        (forecast_data[yearData]['revenue'] = revenue_calculation),
                        (forecast_data[yearData]['yoyGrowth'] = calculateGrowthRate(
                            parseFloat(grwthrevenuedata),
                            parseFloat(forecast_data[yearData]['revenue'])
                        )),
                        (forecast_data[yearData]['revenue_units'] = data.revenue_units),
                        (forecast_data[yearData]['type'] = data.type),
                        (forecast_data[yearData]['year'] = data.year)
                    index++
                }
            }
            let cleanedData = cleanData(calculatedValues)
            let overallData = { ...forecast_data, ...cleanedData }
            const arrayData = Object.entries(overallData).map(([year, details]) => {
                return { year, ...details }
            })
            setForecastOutputData(arrayData)
        }

        finetunedata()
        forecastCalculation(
            currentPair[1] ? currentPair[0] + '_' + currentPair[1] : currentPair[0]
        )

    }

    const stepTwo = async () => {
        setLoader(true)
        let dataArr = replaceIndicesWithNames(
            driverRestraintValues,
            driverRestraintNames
        )
        setDnrData(dataArr)
        const url = window.location.href
        const segments = url.split('/').pop()
        const id = segments
        let postData = {
            user_id: getUserDetailsFromLocalStorage()?.user_id,
            seg_id: parentNode_Id,
            drivers_restrainsts_data: dataArr,
        }
        try {
            let response = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}/saveDriversRestraints`,
                postData
            )
            if (response?.status == 200) {
                toast.success('Drivers and Restraints Saved')
            } else {
                toast.error('Something went wrong')
            }

            calculateForecastData(dataArr)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
            toast.error('Something went wrong')
        }
    }
    function generateYears(baseYear, studyFrom, studyTo) {
        const forecastYears = []
        const historicYears = []

        for (let year = studyFrom; year <= studyTo; year++) {
            if (year > baseYear) {
                forecastYears.push(year)
            } else if (year <= baseYear) {
                historicYears.push(year)
            }
        }
        setRows(historicYears)
        setCol(forecastYears)
    }
    const transformData = (data) => {
        const transformedData = {}

        for (const year in data) {
            if (data.hasOwnProperty(year)) {
                transformedData[`${year}-units`] = data[year].units
                transformedData[`${year}-price`] = data[year].price
                transformedData[`${year}-revenue`] = data[year].revenue
            }
        }

        return transformedData
    }

    const transformNameData = (data) => {
        const transformedData = {}

        const transformCategory = (category) => {
            const newCategory = {}
            const keys = Object.keys(category)
            keys.forEach((key, index) => {
                newCategory[index] = key
            })
            return newCategory
        }

        transformedData['unitsDrivers'] = transformCategory(data.unitDrivers)
        transformedData['unitsRestraints'] = transformCategory(data.unitsRestraints)
        transformedData['priceDrivers'] = transformCategory(data.priceDrivers)
        transformedData['priceRestraints'] = transformCategory(data.priceRestraints)
        transformedData['revenueDrivers'] = transformCategory(data.revenueDrivers)
        transformedData['revenueRestraints'] = transformCategory(data.revenueRestraints)

        return transformedData
    }

    const transformDNRData = (data) => {
        const transformedData = {}

        const transformCategory = (category) => {
            const newCategory = {}
            const keys = Object.keys(category)
            keys.forEach((key, index) => {
                newCategory[index] = category[key]
            })
            return newCategory
        }

        transformedData['unitsDrivers'] = transformCategory(data.unitDrivers)
        transformedData['unitsRestraints'] = transformCategory(data.unitsRestraints)
        transformedData['priceDrivers'] = transformCategory(data.priceDrivers)
        transformedData['priceRestraints'] = transformCategory(data.priceRestraints)
        transformedData['revenueDrivers'] = transformCategory(data.revenueDrivers)
        transformedData['revenueRestraints'] = transformCategory(data.revenueRestraints)

        return transformedData
    }
    const getProjectDetails = async () => {
        const url = window.location.href
        const segments = url.split('/').pop()
        const id = segments
        try {
            let data = await axios.get(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}`
            )
            generateYears(
                parseInt(data?.data?.data?.baseyear),
                parseInt(data?.data?.data?.study_from),
                parseInt(data?.data?.data?.study_to)
            )
        } catch (error) {
            console.log(error)
        }
    }
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation();
    const { projectId } = useParams();
    const { handleSectionSave } = useContext(ProgressContext)
    const handleTocContentSave = async () => {
        try {
            updateTocIsSaved({ projectId: projectId, tocId: tocId })
            handleSectionSave(tocId)
            toast.success('Progress saved!')
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        }
    }
    const stepThree = async () => {
        setLoader(true)
        try {
            const url = window.location.href
            const segments = url.split('/').pop()
            const id = segments
            const transformedData = forecastOutputData?.reduce((acc, obj) => {
                acc[obj.year] = { ...obj, year: obj.year }
                return acc
            }, {})
            let postData = {
                user_id: getUserDetailsFromLocalStorage()?.user_id,
                seg_id: parentNode_Id,
                forecast_data: transformedData,
                finetune_data: finetuneData
            }
            let response = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}/saveForecastData`,
                postData
            )

            setLoader(false)
            setDummy(dummy + 1)
            if (response?.status == 200) {
                toast.success('Forecast data saved!')
                getDnrData()
            } else {
                toast.error('Something went wrong')
            }
            handleTocContentSave();
        } catch (error) {
            console.log(error)
            setLoader(false)
            toast.error('Something went wrong')
        }
    }
    const ChangeFinetuneData = () => {
        let dataArr = replaceIndicesWithNames(
            driverRestraintValues,
            driverRestraintNames
        )
        calculateForecastData({ ...dataArr })
    }
    const finetuneHandleInput = (e) => {
        const { name, value } = e.target
        setFinetuneData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }
    useEffect(() => {
        getProjectDetails()
    }, [])
    const generateInitialNameState = () => {
        const initialState = {
            unitsDrivers: {},
            unitsRestraints: {},
            priceDrivers: {},
            priceRestraints: {},
            revenueDrivers: {},
            revenueRestraints: {},
        }

        currentPair?.forEach((pair) => {
            for (let i = 0; i < count[`${pair}Drivers`]; i++) {
                initialState[`${pair}Drivers`][i] = `${pair}Driver ${i + 1}`
            }
            for (let i = 0; i < count[`${pair}Restraints`]; i++) {
                initialState[`${pair}Restraints`][i] = `${pair}Restraint ${i + 1}`
            }
        })

        return initialState
    }

    const generateInitialValueState = () => {
        const initialState = {
            unitsDrivers: {},
            unitsRestraints: {},
            priceDrivers: {},
            priceRestraints: {},
            revenueDrivers: {},
            revenueRestraints: {},
        }

        currentPair.forEach((pair) => {
            for (let i = 0; i < count[`${pair}Drivers`]; i++) {
                const driverKey = `${pair}Drivers`
                initialState[driverKey][i] = {}
                col.forEach((year) => {
                    initialState[driverKey][i][year] = 'No Impact'
                })
            }
            for (let i = 0; i < count[`${pair}Restraints`]; i++) {
                const restraintKey = `${pair}Restraints`
                initialState[restraintKey][i] = {}

                col.forEach((year) => {
                    initialState[restraintKey][i][year] = 'No Impact'
                })
            }
        })
        return initialState
    }
    useEffect(() => {
        if (
            (currentPair.length > 1 && currentPair[0] == 'revenue') &&
            col.length > 0 &&
            isNestedEmpty(driverRestraintNames)
        ) {
            const initialState = generateInitialNameState()
            setDriverRestraintNames(initialState)
        }
    }, [col, currentPair])

    useEffect(() => {
        if (
            (currentPair.length > 1 && currentPair[0] == 'revenue') &&
            col.length > 0 &&
            isNestedEmpty(driverRestraintValues)
        ) {
            const initialState = generateInitialValueState()
            setDriverRestraintValues(initialState)
        }
    }, [col, currentPair])

    const getDnrData = async () => {
        const url = window.location.href
        const segments = url.split('/').pop()
        const id = segments
        const seg_id = parentNode_Id
        let params = {
            seg_id: seg_id,
            user_id: getUserDetailsFromLocalStorage()?.user_id,
        }
        setLoader(true)
        try {
            let data = await axios.get(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}/getdrdata`,
                { params }
            )

            if (!isEmpty(data?.data?.data?.basic_input_data)) {
                setCount({
                    unitsDrivers:
                        parseInt(data?.data?.data?.basic_input_data?.no_of_unit_drivers) >=
                            3
                            ? data?.data?.data?.basic_input_data?.no_of_unit_drivers
                            : 4,
                    unitsRestraints:
                        parseInt(
                            data?.data?.data?.basic_input_data?.no_of_unit_restraints
                        ) >= 3
                            ? data?.data?.data?.basic_input_data?.no_of_unit_restraints
                            : 4,
                    priceDrivers:
                        parseInt(data?.data?.data?.basic_input_data?.no_of_price_drivers) >=
                            3
                            ? data?.data?.data?.basic_input_data?.no_of_price_drivers
                            : 4,
                    priceRestraints:
                        parseInt(
                            data?.data?.data?.basic_input_data?.no_of_price_restraints
                        ) >= 3
                            ? data?.data?.data?.basic_input_data?.no_of_price_restraints
                            : 4,
                    revenueDrivers:
                        parseInt(
                            data?.data?.data?.basic_input_data?.no_of_revenue_drivers
                        ) >= 3
                            ? data?.data?.data?.basic_input_data?.no_of_revenue_drivers
                            : 4,
                    revenueRestraints:
                        parseInt(
                            data?.data?.data?.basic_input_data?.no_of_revenue_restraints
                        ) >= 3
                            ? data?.data?.data?.basic_input_data?.no_of_revenue_restraints
                            : 4,
                })
            }

            let thispair = data?.data?.data?.formula_type.split('_')
            if (!isEmpty(data?.data?.data?.formula_type)) {
                setCurrentPair(thispair)
            }
            if (!isEmpty(data?.data?.data?.historic_data)) {
                setCalculatedValues(data?.data?.data?.historic_data)
                const newData = transformData(data?.data?.data?.historic_data)
                setInputValues(newData)
            }
            if (!isEmpty(data?.data?.drivers_restrainsts_data)) {
                let nameData = transformNameData(data?.data?.drivers_restrainsts_data)
                setDriverRestraintNames(nameData)
                console.log(nameData);
                console.log(data?.data?.drivers_restrainsts_data);
                let dnrData = transformDNRData(data?.data?.drivers_restrainsts_data)
                // console.log(dnrData);
                // console.log(data?.data?.drivers_restrainsts_data);
                setDriverRestraintValues(dnrData)
                setDummy(dummy + 1)
            }

            if (!isEmpty(data?.data?.data?.market_type)) {
                setMarket_type(data?.data?.data?.market_type)
            }

            if (!isEmpty(data?.data?.data?.finetune_data)) {
                setFinetuneData(data?.data?.data?.finetune_data)
            }

            if (data?.data?.data?.split_model) {
                setOpenSplitModel(data?.data?.data?.split_model)
            }
            if (!isEmpty(data?.data?.data?.overall_data)) {
                const arrayData = Object.entries(data?.data?.data?.overall_data).map(
                    ([year, details]) => {
                        return { year, ...details }
                    }
                )
                setForecastOutputData(arrayData)
            }

            setLoader(false)
        } catch (error) {
            console.log('D&R error => ', error)
            setLoader(false)
        }
    }

    useEffect(() => {
        if (parentNode_Id) {
            getDnrData()
        }
    }, [parentNode_Id])

    return (
        <div>
            {loader ? <SSOLoader /> : null}

            <div className='card mb-4'>
                <div className='card-header'>
                    <h6 className='text-center mb-0 text-white'>BASIC INPUT</h6>
                </div>
                <div className='card-body'>
                    <div className='d-flex justify-content-start'>
                        <select
                            className='form-select form-select-sm w-25 mb-2'
                            onChange={handleMarketType}
                            value={market_type}
                        >
                            <option value=''>Market Type</option>
                            <option value='Decline'>Decline</option>
                            <option value='Mature'>Mature</option>
                            <option value='Low Growth'>Low Growth</option>
                            <option value='Medium Growth'>Medium Growth</option>
                            <option value='High Growth'>High Growth</option>
                        </select>
                        {isProjectEditable && <span style={{ color: 'red' }} className='ms-2 mb-2 font14'>
                            * Select The Market Type Then Continue
                        </span>}
                    </div>
                    <table className='table table-bordered table-striped text-center table-excelToHtml mb-0'>
                        <thead>
                            <tr>
                                <th scope='col' className='align-middle'>
                                    Years
                                </th>
                                <th>
                                    <div className='d-flex text-center justify-content-center align-items-center'>
                                        <span>Unit</span>
                                        <select
                                            className='ms-2 form-select form-select-sm w-50 rounded-0'
                                            onChange={(e) =>
                                                setUnitCurrency({
                                                    ...unitCurrency,
                                                    unit: e.target.value,
                                                })
                                            }
                                            value={unitCurrency?.unit}
                                        >
                                            <option value='millions'>Million</option>
                                            <option value='billions'>Billion</option>
                                        </select>
                                    </div>
                                </th>
                                <th>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <span>Price</span>
                                        <select
                                            className='ms-2 form-select form-select-sm w-50 rounded-0'
                                            onChange={(e) =>
                                                setUnitCurrency({
                                                    ...unitCurrency,
                                                    currency: e.target.value,
                                                })
                                            }
                                            value={unitCurrency?.currency}
                                        >
                                            <option value='USD'>USD</option>
                                            <option value='EURO'>EURO</option>
                                        </select>
                                    </div>
                                </th>
                                <th>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <span>Revenue</span>
                                        <select
                                            className='ms-2 form-select form-select-sm w-50 rounded-0'
                                            onChange={(e) =>
                                                setUnitCurrency({
                                                    ...unitCurrency,
                                                    unit: e.target.value,
                                                })
                                            }
                                            value={unitCurrency?.unit}
                                        >
                                            <option value='millions'>Million</option>
                                            <option value='billions'>Billion</option>
                                        </select>
                                    </div>
                                </th>
                                <th>YoY Growth</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((year) => (
                                <tr key={year}>
                                    <td scope='row' className='align-middle'>
                                        {year}
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            className='form-control form-control-sm'
                                            value={inputValues[`${year}-units`] || ''}
                                            onChange={(e) =>
                                                handleInputChange(year, 'units', e.target.value)
                                            }
                                            disabled={market_type == '' ? true : false}
                                            readOnly={isReadOnly('units')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            className='form-control form-control-sm'
                                            value={inputValues[`${year}-price`] || ''}
                                            onChange={(e) =>
                                                handleInputChange(year, 'price', e.target.value)
                                            }
                                            disabled={market_type == '' ? true : false}
                                            readOnly={isReadOnly('price')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            className='form-control form-control-sm'
                                            value={inputValues[`${year}-revenue`] || ''}
                                            onChange={(e) =>
                                                handleInputChange(year, 'revenue', e.target.value)
                                            }
                                            disabled={market_type == '' ? true : false}
                                            readOnly={isReadOnly('revenue')}
                                        />
                                    </td>
                                    <td>{calculatedValues[year]?.yoyGrowth || ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {isProjectEditable && <div className='d-flex justify-content-center'>
                        <button
                            type='button'
                            className='btn btn-primary btn-sm me-2 mt-3'
                            onClick={handleCalculate}
                        >
                            <i className='fa fa-save me-1'></i>Calculate and Save
                        </button>
                    </div>}
                </div>
            </div>

            {Object.keys(calculatedValues).length > 0 && (
                <div className=''>
                    <div className='card mb-4'>
                        <div className='card-header'>
                            <h6 className='text-center mb-0 text-white'>
                                {currentPair[0]?.toUpperCase()}&nbsp; DRIVERS
                            </h6>
                        </div>
                        <div className='card-body'>
                            <table className='table table-bordered table-striped text-center table-excelToHtml'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Drivers</th>
                                        {col.map((year) => (
                                            <th key={year}>{year}</th>
                                        ))}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(
                                        { length: count[currentPair[0] + 'Drivers'] },
                                        (v, i) => (
                                            <tr key={i}>
                                                <td className='align-middle'>{i + 1}</td>
                                                <td>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-sm'
                                                        value={
                                                            driverRestraintNames[currentPair[0] + 'Drivers'][
                                                            i
                                                            ] || currentPair[0] + 'Driver' + ' ' + (i + 1)
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintNameChange(
                                                                currentPair[0] + 'Drivers',
                                                                i,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </td>
                                                {col.map((year) => (
                                                <td key={year}>
                                                    <select
                                                        className='form-select form-select-sm'
                                                        value={
                                                            driverRestraintValues[
                                                            currentPair[0] + 'Drivers'
                                                            ][i]?.[year] || 'No Impact'
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintChange(
                                                                currentPair[0] + 'Drivers',
                                                                i,
                                                                year,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option>No Impact</option>
                                                        <option>Very Low</option>
                                                        <option>Low</option>
                                                        <option>Low/Medium</option>
                                                        <option>Medium</option>
                                                        <option>Medium/High</option>
                                                        <option>High</option>
                                                        <option>Very High</option>
                                                    </select>
                                                </td>
                                                ))}
                                                {isProjectEditable && <td className='align-middle'>
                                                    <i
                                                        onClick={() =>
                                                            deleteRow(currentPair[0] + 'Drivers', i)
                                                        }
                                                        style={{ color: 'red' }}
                                                        className='fa fa-trash font16'
                                                    ></i>
                                                </td>}
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {isProjectEditable && <div className='d-flex justify-content-center'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                    onClick={() => {
                                        addRow(currentPair[0] + 'Drivers')
                                    }}
                                >
                                    <i className='fa fa-plus me-1'></i>
                                    Add Line
                                </button>
                            </div>}
                            {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                                <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                    *Save each section to prevent data loss
                                </span>
                                <button
                                    type='button'
                                    className='btn btn-primary btn-sm me-2 mt-3'
                                    onClick={stepTwo}
                                >
                                    <i className='fa fa-save me-1'></i>Save
                                </button>
                            </div>}
                        </div>
                    </div>

                    <div className='card mb-4'>
                        <div className='card-header'>
                            <h6 className='text-center mb-0 text-white'>
                                {currentPair[0]?.toUpperCase()}&nbsp; RESTRAINTS
                            </h6>
                        </div>
                        <div className='card-body'>
                            <table className='table table-bordered table-striped text-center table-excelToHtml'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Restraints</th>
                                        {col.map((year) => (
                                            <th key={year}>{year}</th>
                                        ))}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(
                                        { length: count[currentPair[0] + 'Restraints'] },
                                        (v, i) => (
                                            <tr key={i}>
                                                <td className='align-middle'>{i + 1}</td>
                                                <td>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-sm'
                                                        value={
                                                            driverRestraintNames[
                                                            currentPair[0] + 'Restraints'
                                                            ][i] ||
                                                            currentPair[0] + 'Restraint' + ' ' + (i + 1)
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintNameChange(
                                                                currentPair[0] + 'Restraints',
                                                                i,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </td>
                                                {col.map((year) => (
                                                    <td key={year}>
                                                        <select
                                                            className='form-select form-select-sm'
                                                            value={
                                                                driverRestraintValues[
                                                                currentPair[0] + 'Restraints'
                                                                ][i]?.[year] || 'No Impact'
                                                            }
                                                            onChange={(e) =>
                                                                handleDriverRestraintChange(
                                                                    currentPair[0] + 'Restraints',
                                                                    i,
                                                                    year,
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option>No Impact</option>
                                                            <option>Very Low</option>
                                                            <option>Low</option>
                                                            <option>Low/Medium</option>
                                                            <option>Medium</option>
                                                            <option>Medium/High</option>
                                                            <option>High</option>
                                                            <option>Very High</option>
                                                        </select>
                                                    </td>
                                                ))}
                                                {isProjectEditable && <td className='align-middle'>
                                                    <i
                                                        onClick={() =>
                                                            deleteRow(currentPair[0] + 'Restraints', i)
                                                        }
                                                        style={{ color: 'red' }}
                                                        className='fa fa-trash font16'
                                                    ></i>
                                                </td>}
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {isProjectEditable && <div className='d-flex justify-content-center'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                    onClick={() => {
                                        addRow(currentPair[0] + 'Restraints')
                                    }}
                                >
                                    <i className='fa fa-plus me-1'></i>
                                    Add Line
                                </button>
                            </div>}
                            {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                                <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                    *Save each section to prevent data loss
                                </span>
                                <button
                                    type='button'
                                    className='btn btn-primary btn-sm me-2 mt-3'
                                    onClick={stepTwo}
                                >
                                    <i className='fa fa-save me-1'></i>Save
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            )}

            {currentPair[1] && Object.keys(calculatedValues).length > 0 ? (
                <div>
                    <div className='card mb-4'>
                        <div className='card-header'>
                            <h6 className='text-center mb-0 text-white'>
                                {currentPair[1]?.toUpperCase()}&nbsp; DRIVERS
                            </h6>
                        </div>
                        <div className='card-body'>
                            <table className='table table-bordered table-striped text-center table-excelToHtml'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Drivers</th>
                                        {col.map((year) => (
                                            <th key={year}>{year}</th>
                                        ))}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(
                                        { length: count[currentPair[1] + 'Drivers'] },
                                        (v, i) => (
                                            <tr key={i}>
                                                <td className='align-middle'>{i + 1}</td>
                                                <td>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-sm'
                                                        value={
                                                            driverRestraintNames[currentPair[1] + 'Drivers'][
                                                            i
                                                            ] || currentPair[1] + 'Driver' + ' ' + (i + 1)
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintNameChange(
                                                                currentPair[1] + 'Drivers',
                                                                i,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </td>
                                                {col.map((year) => (
                                                    <td key={year}>
                                                        <select
                                                            className='form-select form-select-sm'
                                                            value={
                                                                driverRestraintValues[
                                                                currentPair[1] + 'Drivers'
                                                                ][i]?.[year] || 'No Impact'
                                                            }
                                                            onChange={(e) =>
                                                                handleDriverRestraintChange(
                                                                    currentPair[1] + 'Drivers',
                                                                    i,
                                                                    year,
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option>No Impact</option>
                                                            <option>Very Low</option>
                                                            <option>Low</option>
                                                            <option>Low/Medium</option>
                                                            <option>Medium</option>
                                                            <option>Medium/High</option>
                                                            <option>High</option>
                                                            <option>Very High</option>
                                                        </select>
                                                    </td>
                                                ))}
                                                {isProjectEditable && <td className='align-middle'>
                                                    <i
                                                        onClick={() =>
                                                            deleteRow(currentPair[1] + 'Drivers', i)
                                                        }
                                                        style={{ color: 'red' }}
                                                        className='fa fa-trash font16'
                                                    ></i>
                                                </td>}
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {isProjectEditable && <div className='d-flex justify-content-center'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                    onClick={() => {
                                        addRow(currentPair[1] + 'Drivers')
                                    }}
                                >
                                    <i className='fa fa-plus me-1'></i>
                                    Add Line
                                </button>
                            </div>}
                            {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                                <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                    *Save each section to prevent data loss
                                </span>
                                <button
                                    type='button'
                                    className='btn btn-primary btn-sm me-2 mt-3'
                                    onClick={stepTwo}
                                >
                                    <i className='fa fa-save me-1'></i>Save
                                </button>
                            </div>}
                        </div>
                    </div>
                    <div className='card mb-4'>
                        <div className='card-header'>
                            <h6 className='text-center mb-0 text-white'>
                                {currentPair[1]?.toUpperCase()}&nbsp; RESTRAINTS
                            </h6>
                        </div>
                        <div className='card-body'>
                            <table className='table table-bordered table-striped text-center table-excelToHtml'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Restraints</th>
                                        {col.map((year) => (
                                            <th key={year}>{year}</th>
                                        ))}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(
                                        { length: count[currentPair[1] + 'Restraints'] },
                                        (v, i) => (
                                            <tr key={i}>
                                                <td className='align-middle'>{i + 1}</td>
                                                <td>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-sm'
                                                        value={
                                                            driverRestraintNames[
                                                            currentPair[1] + 'Restraints'
                                                            ][i] ||
                                                            currentPair[1] + 'Restraint' + ' ' + (i + 1)
                                                        }
                                                        onChange={(e) =>
                                                            handleDriverRestraintNameChange(
                                                                currentPair[1] + 'Restraints',
                                                                i,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </td>
                                                {col.map((year) => (
                                                    <td key={year}>
                                                        <select
                                                            className='form-select form-select-sm'
                                                            value={
                                                                driverRestraintValues[
                                                                currentPair[1] + 'Restraints'
                                                                ][i]?.[year] || 'No Impact'
                                                            }
                                                            onChange={(e) =>
                                                                handleDriverRestraintChange(
                                                                    currentPair[1] + 'Restraints',
                                                                    i,
                                                                    year,
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option>No Impact</option>
                                                            <option>Very Low</option>
                                                            <option>Low</option>
                                                            <option>Low/Medium</option>
                                                            <option>Medium</option>
                                                            <option>Medium/High</option>
                                                            <option>High</option>
                                                            <option>Very High</option>
                                                        </select>
                                                    </td>
                                                ))}
                                                {isProjectEditable && <td className='align-middle'>
                                                    <i
                                                        onClick={() =>
                                                            deleteRow(currentPair[1] + 'Restraints', i)
                                                        }
                                                        style={{ color: 'red' }}
                                                        className='fa fa-trash font16'
                                                    ></i>
                                                </td>}
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {isProjectEditable && <div className='d-flex justify-content-center'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary btn-sm mb-3 rounded-0'
                                    onClick={() => {
                                        addRow(currentPair[1] + 'Restraints')
                                    }}
                                >
                                    <i className='fa fa-plus me-1'></i>
                                    Add Line
                                </button>
                            </div>}
                            {isProjectEditable && <div className='d-flex justify-content-end align-items-center'>
                                <span style={{ color: 'red' }} className='mt-3 me-2 font14'>
                                    *Save each section to prevent data loss
                                </span>
                                <button
                                    type='button'
                                    className='btn btn-primary btn-sm me-2 mt-3'
                                    onClick={stepTwo}
                                >
                                    <i className='fa fa-save me-1'></i>Save
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            ) : null}
            {currentPair.length > 0 && Object.keys(calculatedValues).length > 0 && isProjectEditable ? (
                <div className='d-flex justify-content-center'>
                    <button
                        type='button'
                        className='btn btn-primary btn-sm mt-1 mb-4'
                        onClick={stepTwo}
                    >
                        <i className='fa fa-save me-1'></i>Calculate and Save
                    </button>
                </div>
            ) : null}

            {forecastOutputData.length > 0 && (
                <div className='card mb-4'>
                    <div className='card-header'>
                        <h6 className='text-center mb-0 text-white'>
                            FORECAST OUTPUT
                        </h6>
                    </div>
                    <div className='card-body'>
                        <table className='table table-bordered table-striped text-center table-excelToHtml mb-0'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Year</th>
                                    <th>Units</th>
                                    <th>Growth %</th>
                                    <th>Price</th>
                                    <th>Growth %</th>
                                    <th>Revenue</th>
                                    <th>Growth %</th>
                                </tr>
                            </thead>
                            <tbody>
                                {forecastOutputData.map((e) => (
                                    <tr>
                                        <td>{e?.year || '-'}</td>
                                        <td>{e?.units || '-'}</td>
                                        <td>{e?.unit_growth || '-'} </td>
                                        <td>{e?.price || '-'}</td>
                                        <td>{e?.price_growth || '-'} </td>
                                        <td>{e?.revenue || '-'}</td>
                                        <td>{e?.yoyGrowth || '-'} </td>
                                    </tr>
                                ))}

                                <tr></tr>
                            </tbody>
                        </table>
                        {isProjectEditable && <div className='d-flex justify-content-around'>
                            <button
                                type='button'
                                className='btn btn-primary btn-sm me-2 mt-3'
                                data-bs-toggle='modal'
                                data-bs-target='#finetuneModal'
                            >
                                <i className='fa fa-save me-1'></i>FineTune
                            </button>
                            <button
                                type='button'
                                className='btn btn-primary btn-sm me-2 mt-3'
                                onClick={stepThree}
                            >
                                <i className='fa fa-save me-1'></i>Save Forecast
                            </button>
                        </div>}
                    </div>
                </div>
            )}

            <div className='modal fade' id='finetuneModal'>
                <div className='modal-dialog modal-auto'>
                    <div className='modal-content'>
                        <div className='modal-header py-2'>
                            <h5 className='modal-title'>Fine Tune</h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                            ></button>
                        </div>
                        <div className='modal-body pt-0'>
                            {currentPair[1] ? (
                                <div>
                                    <div className='d-flex justify-content-between mt-2'>
                                        <label>
                                            {currentPair[0].toUpperCase() + ' ' + 'DRIVERS'}
                                        </label>
                                        <input
                                            className='form-control form-control-sm w-50 '
                                            value={finetuneData[currentPair[0] + '_' + 'drivers']}
                                            type='number'
                                            onChange={finetuneHandleInput}
                                            name={currentPair[0] + '_' + 'drivers'}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-between mt-2'>
                                        <label>
                                            {currentPair[1].toUpperCase() + ' ' + 'DRIVERS'}
                                        </label>
                                        <input
                                            className='form-control form-control-sm w-50 '
                                            type='number'
                                            value={finetuneData[currentPair[1] + '_' + 'drivers']}
                                            onChange={finetuneHandleInput}
                                            name={currentPair[1] + '_' + 'drivers'}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-between mt-2'>
                                        <label>
                                            {currentPair[0]?.toUpperCase() + ' ' + 'RESTRAINTS'}
                                        </label>
                                        <input
                                            className='form-control form-control-sm w-50'
                                            type='number'
                                            value={finetuneData[currentPair[0] + '_' + 'restraints']}
                                            onChange={finetuneHandleInput}
                                            name={currentPair[0] + '_' + 'restraints'}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-between mt-2'>
                                        <label>
                                            {currentPair[1]?.toUpperCase() + ' ' + 'RESTRAINTS'}
                                        </label>
                                        <input
                                            className='form-control form-control-sm w-50'
                                            type='number'
                                            value={finetuneData[currentPair[1] + '_' + 'restraints']}
                                            onChange={finetuneHandleInput}
                                            name={currentPair[1] + '_' + 'restraints'}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className='d-flex justify-content-around mt-2'>
                                        <label>
                                            {currentPair[0]?.toUpperCase() + ' ' + 'DRIVERS'}
                                        </label>
                                        <input
                                            className='form-control form-control-sm w-50'
                                            type='number'
                                            onChange={finetuneHandleInput}
                                            value={finetuneData[currentPair[0] + '_' + 'drivers']}
                                            name={currentPair[0] + '_' + 'drivers'}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-around mt-2'>
                                        <label>
                                            {currentPair[0]?.toUpperCase() + ' ' + 'RESTRAINTS'}
                                        </label>
                                        <input
                                            className='form-control form-control-sm w-50'
                                            type='number'
                                            onChange={finetuneHandleInput}
                                            value={finetuneData[currentPair[0] + '_' + 'restraints']}
                                            name={currentPair[0] + '_' + 'restraints'}
                                        />
                                    </div>
                                </div>
                            )}
                            {isProjectEditable && <div className='d-flex justify-content-center'>
                                <button
                                    type='button'
                                    className='btn btn-primary btn-sm me-2 mt-3'
                                    data-bs-dismiss='modal'
                                    onClick={ChangeFinetuneData}
                                >
                                    <i className='fa fa-save me-1'></i>FineTune
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            {openSplitModel == 1 && <SplitModel dnrDataSet={dnrDataSet} dummy={dummy} headings={[{ name: 'Revenue and Growth', value: 'revenue_growth' }, { name: 'Revenue', value: 'revenue' }, { name: 'Market', value: 'market' }]} />}
        </div>
    )
}

export default TotalMarketAnalysis
