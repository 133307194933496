import React, { useEffect, useState } from 'react';
import OddLevelNode from './OddLevelNode';
import EvenLevelNode from './EvenLevelNode';
import { useGetProjectDetailsQuery } from '../../redux/slices/ProjectPageAPISlice';
import { useParams } from 'react-router-dom';

const Node = ({ node, onAddChild, onDeleteNode, onNameChange }) => {
    const {projectId}=useParams();
    const [isForecastCompleted,setIsForecastCompleted]=useState(false);
    const {data:ProjectData,isLoading:isProjectDataLoading,isSuccess:isProjectDataSuccess}=useGetProjectDetailsQuery({projectId:projectId});
  
    useEffect(()=>{
        if(isProjectDataSuccess){
            setIsForecastCompleted(ProjectData?.data.forecast_status===1)
        }
    },[isProjectDataLoading,isProjectDataSuccess,ProjectData,node])
    return node.level % 2 === 1 ? (
        <OddLevelNode
            node={node}
            onAddChild={onAddChild}
            onDeleteNode={onDeleteNode}
            onNameChange={onNameChange}
            isForecastCompleted={isForecastCompleted}
        />
    ) : (
        <EvenLevelNode
            node={node}
            onAddChild={onAddChild}
            onDeleteNode={onDeleteNode}
            onNameChange={onNameChange}
            isForecastCompleted={isForecastCompleted}
        />
    );
};

export default Node;
