const GrowthMetricsStaticData={
    "data": [
      {
        "id":"0",
        "field": "Life Cycle Stage",
        "units": "",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 10
      },
      {
        "id":"1",
        "field": "Revenue",
        "units": "M",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 1,
        "sort": 20
      },
      {
        "id":"2",
        "field": "Units/Volume",
        "units": "M",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 30
      },
      {
        "id":"3",
        "field": "Average Price Per Unit",
        "units": "",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 1,
        "sort": 40
      },
      {
        "id":"4",
        "field": "Revenue for Last Year of Study Period",
        "units": "B",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 1,
        "sort": 50
      },
      {
        "id":"5",
        "field": "Base Year Growth Rate",
        "units": "%",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 60
      },
      {
        "id":"6",
        "field": "Compound Annual Growth Rate",
        "units": "%",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 70
      },
      {
        "id":"7",
        "field": "Number of Competitors",
        "units": "",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 80
      },
      {
        "id":"8",
        "field": "Degree of Technical Change",
        "units": "",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 90
      },
      {
        "id":"9",
        "field": "Concentration",
        "units": "%",
        "field_value": "",
        "deafultMap": "",
        "isPrice": 0,
        "sort": 100
      }
    ]
}
export {GrowthMetricsStaticData}