import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImpactButton from './ImpactButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';


const GrowthMetricCard = ({ metric, updateMetricName, deleteMetric, updateMetricImpact, index }) => {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [editMod, setEditMod] = useState(false);
    return <div className="col-md-3 mb-3">
        <div className="card rounded-0 border-0">
            <div className="card-header py-1 rounded-0 d-flex align-items-center justify-content-center px-2">
                {/*  */}
                {
                    editMod ? (
                        <>
                            <input
                                disabled={!isProjectEditable}
                                className='form-control form-control-sm'
                                value={metric.field}
                                onChange={(e) => updateMetricName(index, 'field', e.target.value)}
                            />
                            <i onClick={() => setEditMod(false)} className='fa fa-save font16 align-text-bottom ms-2'></i>
                        </>
                    ) : (
                        <>
                            <p className='mb-0'>{metric?.field}</p>
                            <i onClick={() => setEditMod(true)} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i>
                        </>
                    )
                }

            </div>
            <div className="card-body px-2 d-flex">
                <div className="flex-grow-1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <input
                        disabled={!isProjectEditable}
                        type="text"
                        value={metric.field_value}
                        onChange={(e) => updateMetricName(index, 'field_value', e.target.value)}
                        className="form-control form-control-sm m-1" />
                    <input
                        disabled={!isProjectEditable}
                        type="text"
                        value={metric.deafultMap}
                        onChange={(e) => updateMetricName(index, 'deafultMap', e.target.value)}
                        className="form-control form-control-sm m-1" />
                    {isProjectEditable && <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => deleteMetric(metric.id)}
                    />}
                </div>
                <div className="d-flex flex-column justify-content-between ms-2">
                    <ImpactButton metric={metric} impact="high" label="High" className="btn-success" onClick={updateMetricImpact} />
                    <ImpactButton metric={metric} impact="medium" label="Med" className="btn-warning" onClick={updateMetricImpact} />
                    <ImpactButton metric={metric} impact="low" label="Low" className="btn-danger" onClick={updateMetricImpact} />
                </div>
            </div>
        </div>
    </div>
};

export default GrowthMetricCard