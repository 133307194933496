import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import '../CSS/SideBar.css';

const Sidebar = (data) => {
  const navigate = useNavigate();
  const NavigateToPage = (e) => {
    navigate(`/${e}`)

  }

  return (
    <div id="sidebar" className={!data?.data ? "active" : ""}>

      <div style={{top:'16px'}}  className='sticky-top'>
        <div className='my-3 border-bottom' onClick={() => NavigateToPage("")}>
          {!data?.data ? <span className='sitename ms-3 text-white' style={{fontSize:'1.6rem'}}>Growth Opportunity</span> : null}
        </div>
        <ul className="list-unstyled components">
          {!data?.data ? <li className="">
            <a onClick={() => {data?.receiveState();NavigateToPage("project")}} href="#"><i className="fa fa-list"></i> <span className=''style={{fontSize:'15px'}}>My Projects</span></a>
          </li> : null}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;


