import React from 'react'

function HomePage() {
  return (
    <div >
        <h2 className=''>Dashboard</h2>
    </div>
  )
}

export default HomePage