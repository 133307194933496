import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetDrDataQuery, usePostTocDataMutation, useUpdateIsApplicableMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import { useGetDNRDataQuery } from '../../DriversAndRestraints/Store/dataSlices/DriverAndRestraintAPISlice';
import OthersEditableDandR from './Components/OtherEditableD&R';
import DisplayDandR from './Components/DisplayD&R';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';
function GrowthUnitRestraints(props) {
    const [loader, setLoader] = useState(false)
    const [postTocData] = usePostTocDataMutation();
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [unitsRestraints, setunitsRestraints] = useState({});
    const [years, setYears] = useState([]);
    const [type, setType] = useState("dr");
    const projectId = props?.go_in_id;
    const { data: DnrData, error, isError, isLoading, isSuccess, } = useGetDNRDataQuery({ projectId, userId: getUserDetailsFromLocalStorage().user_id });
    const { data: DrData, refetch } = useGetDrDataQuery({ id: props?.go_in_id, seg_id: DnrData?.initialNodes[0]?.id, user_id: getUserDetailsFromLocalStorage().user_id });
    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved":1
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        setHeader(props?.name)
    }, [props])


    useEffect(() => {
        if (typeof (DrData) != "undefined") {
            setType(DrData?.data?.dr_type)
            if (DrData?.drivers_restrainsts_data?.unitsRestraints) {
                setunitsRestraints(DrData?.drivers_restrainsts_data?.unitsRestraints)
            } else { setunitsRestraints({}) }
        }
    }, [DrData])
    useEffect(() => {
        if (Object.keys(unitsRestraints).length !== 0) {
            setYears(Object.keys(Object.values(unitsRestraints)[0]))
        }
    }, [unitsRestraints])
    
    const [createCloneToc, {
        isLoading: isCreateCloneLoading,
    }] = useCreateCloneTocMutation()
    const [deleteClone,{isLoading:isCloneDeleteLoading}]=useDeleteCloneMutation();
    const [updateIsApplicable,{isLoading:isUpdateApplicableLoading}]=useUpdateIsApplicableMutation()
    const handleDeleteClone=async()=>{
            try {
                deleteClone({
                    projectId:projectId,
                    tocId:props.id
                })
                toast.success("Clone successfully deleted")
            } catch (error) {
                console.log(error)
                toast.error("Something went wrong")
            } 
    }
    const [isApplicable,setIsApplicable]=useState(props?.isApplicable)
        const handleUpdateIsApplicable = async (crtIsApplicable) => {
            try {
                updateIsApplicable({
                    projectId: projectId,
                    tocId: props.id,
                    isApplicable:crtIsApplicable
                })
            } catch (error) {
                console.log(error);
                toast.error("Something went wrong")
            }
        }
    
        const handleToggle = () => {
            setIsApplicable(1-isApplicable)
            handleUpdateIsApplicable(1-isApplicable)
        };

        const handleCreateClone=async()=>{
            try {
                createCloneToc({projectId:projectId,tocId:props.id}).then((res)=>toast.success("Clone created"))
                
            } catch (error) {
                console.error(error)
                toast.error("Something went wrong")
            }
        }
        const [openDialog, setOpenDialog] = useState(false);
        const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });
    
        const handleConfirmation = () => {
            if (dialogContent.action === 'delete') {
              handleDeleteClone();
              // Perform the delete action here
            }
            else if(dialogContent.action === 'clone'){
                handleCreateClone()
            }
            setOpenDialog(false);
          };
    
          const handleClose = () => {
            setOpenDialog(false);
          };
    
          const onDelete=()=>{
            setDialogContent({
                title: 'Confirm Deletion',
                text: 'Are you sure you want to delete this section?',
                action: 'delete',
            });
            setOpenDialog(true);
          }
          const onCreateClone=()=>{
            setDialogContent({
                title: 'Confirm Clone creation',
                text: 'Are you sure you want to clone this section?',
                action: 'clone',
            });
            setOpenDialog(true);
          }
    return (
        <>
            <div className="card">
                {loader||isCloneDeleteLoading||isUpdateApplicableLoading||isCreateCloneLoading ? <SSOLoader /> : null}
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            headerIsEdit ?
                                <div className='input-group input-group-sm w-50'>
                                    <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                    <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                    <button type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                                </div>
                                :
                                <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
                        }

                        <div className='d-flex align-items-center'>
                            <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                                <input 
                                    className="form-check-input" 
                                    checked={isApplicable} 
                                    onChange={handleToggle}
                                    type="checkbox" 
                                    role="switch" 
                                    id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                            </div>
                            {props.isClone?<i className="me-3 fa fa-plus-square" alt="Square Plus" onClick={onCreateClone}></i>:null}
                            {(props.roleType!=='analyst')||(props.hasCloned)?<i className="fa fa-trash-o" alt="Trash" onClick={onDelete} title='Delete'></i>:null}
                            <DialogueBox
                                openDialog={openDialog} 
                                setOpenDialog={setOpenDialog}
                                dialogTitle={dialogContent.title}
                                dialogContentText={dialogContent.text} 
                                handleConfirmation={handleConfirmation}
                                handleClose={handleClose}
                            />
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    {
                        type == "other" ? <OthersEditableDandR allData={DrData} years={years} refetch={() => { refetch() }} toc={props} type="unitRestraints"/> :
                        <DisplayDandR unitDrivers={unitsRestraints} years={years} type="restraints"/>
                    }
                </div>
                <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{backgroundColor:'#1582C5'}}>
                    <Footer/>
                </div>
            </div>
        </>

    )
}

export default GrowthUnitRestraints
