import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { useGetKeyCompetitorsQuery, usePostKeyCompetitorMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { toast } from 'react-toastify';
import { ProgressContext } from '../../Providers/ProgressProvider';

const CompetitorsBySection = ({ competitorObj, projectData,tocId }) => {
  const {handleSectionSave}=useContext(ProgressContext)
  const { isProjectEditable } = useContext(ProjectStatusContext)
  const [inputValue, setInputValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const { projectId } = useParams();
  const { data:keyCompetitorData, isLoading,isSuccess,isError,error} = useGetKeyCompetitorsQuery({ projectId: projectId, nodeId: competitorObj.id,tocId:tocId })

  useEffect(()=>{
    if(isSuccess){
      setTableData(keyCompetitorData?.data?.map(ele => ele?.desc))
    }
  },[keyCompetitorData,isLoading,isSuccess,isError])



  const [postKeyCompetitor] = usePostKeyCompetitorMutation();
  const [updateTocIsSaved]=useUpdateTocIsSavedMutation()
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    const inputValue1 = e.target.value;
    const items = inputValue1.split('\n').map((item) => item.trim()).filter(Boolean);
    setTableData(items?.map(item => item.split('\t')[0]));
  };

  const handleSaveKeyCompetitor = async () => {

    try {
      postKeyCompetitor({
        projectId: projectId,
        body: {
          userid: getUserDetailsFromLocalStorage().user_id,
          seg_id: competitorObj.id,
          competitors: tableData.flat(),
          tocid:tocId,
        }
      }).then((res) => toast.success("Saved Successfully"))
      handleSectionSave(tocId)
      updateTocIsSaved({projectId:projectId,tocId:tocId})

    } catch (error) {
      console.log(error)
      toast.error("Something went wrong")
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text/plain');
    const rows = pastedData.split('\n');
    setTableData(rows?.map(item => item.split('\t')[0]));
  };

  return (
    <div className=''>
      <div className='d-flex justify-content-between'>
        <h6 className='fw600 font14 mb-2'>{competitorObj.name}</h6>
        <i data-bs-toggle="modal" data-bs-target="#myModalkeyC" style={{color:'#1582C5'}} className='fa fa-info-circle font16 cursor-pointer'></i>
      </div>
      <div className='row'>
        <div className='col-6 text-center'>
          <textarea
            disabled={!isProjectEditable}
            onPaste={handlePaste}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            style={{ width: '100%', height: '250px', padding: '8px' }}
            placeholder="Paste from Excel or Enter items separated by Enter"
          />

        </div>
        <div className='col-6'>
          {tableData.length > 0 && (
            <table className="table table-bordered table-striped table-sm font14">
              <thead className="thead-dark">
                <tr>
                  <th className='fw600'>Competitors</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td key={`${rowIndex}`}>{row}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isProjectEditable && <div className='text-end'>
        <button onClick={handleSaveKeyCompetitor} className='btn btn-primary btn-sm my-2'><i className='fa fa-save'></i> Save Competitors</button>
      </div>}
    </div>
  )
}

export default CompetitorsBySection



