// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-space{
   padding: 1rem;
}
.links{
    width: 100%;
    padding: 1rem;
}
.filterItems-dsh{
    display: flex;
    justify-content: space-between;
    margin: 1rem;
}
.status-tabs{
    display:flex;
    justify-content: space-between;  
    margin: 1em;
    
}
.extras{
    width: 20%;
}
.notselected-link{
    color: white;
    cursor: pointer;
    font-weight: normal;
}
.selected-link{
    cursor: pointer;
    font-weight: bold;
}

.filterlabelname{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

  
`, "",{"version":3,"sources":["webpack://./src/Components/Styles/dashboard.css"],"names":[],"mappings":"AAAA;GACG,aAAa;AAChB;AACA;IACI,WAAW;IACX,aAAa;AACjB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;AAChB;AACA;IACI,YAAY;IACZ,8BAA8B;IAC9B,WAAW;;AAEf;AACA;IACI,UAAU;AACd;AACA;IACI,YAAY;IACZ,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".page-space{\n   padding: 1rem;\n}\n.links{\n    width: 100%;\n    padding: 1rem;\n}\n.filterItems-dsh{\n    display: flex;\n    justify-content: space-between;\n    margin: 1rem;\n}\n.status-tabs{\n    display:flex;\n    justify-content: space-between;  \n    margin: 1em;\n    \n}\n.extras{\n    width: 20%;\n}\n.notselected-link{\n    color: white;\n    cursor: pointer;\n    font-weight: normal;\n}\n.selected-link{\n    cursor: pointer;\n    font-weight: bold;\n}\n\n.filterlabelname{\n    font-size: 12px;\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
