export const routeprefixes={
    homePage:'/',
    myprojects:'/myprojects',
    createproject:'/createproject',
    sampletable:'/sampletable',
    authenticateuser:'/authenticateuser',
    project:'/project',
    projectView:"/projectView",
    notifications:'/notifications',
    pptview:'/pptview'
}
export const routeSuffixes={
    driversAndRestraints:'driversAndRestraints'
}
