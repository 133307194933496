import React, { useContext, useEffect, useState } from 'react'
import { useDeleteGouDataMutation, useGetGrowthUniverseDataByIdQuery, usePostCriteriaDataMutation } from '../../redux/slices/ProjectPageAPISlice';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { range } from '../../Utils/CalculationUtils'
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import CkEditorBase from '../../CkEditorReact/CkEditorBase';
import './Style.css'

function GrowthOpportunityUniverse({ goInid, growthUniverseData }) {

    const [postCriteriaData] = usePostCriteriaDataMutation();
    const [deleteGouData] = useDeleteGouDataMutation()
    const { projectId } = useParams();
    const { data, isLoading } = useGetGrowthUniverseDataByIdQuery({ projectId: projectId, goInId: goInid })
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [selectedUserIndustries, setSelectedUserIndustries] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedGrowthItems, setSelectedGrowthItems] = useState([]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [goInTitle, setGoInTitle] = useState('');
    const [baseYear, setBaseYear] = useState(new Date().getUTCFullYear());
    const [timeLine, setTimeLine] = useState(0);
    const [opportunitySize, setOpportunitySize] = useState(0);
    const [candDone, setCandDOne] = useState('');
    const [candDTwo, setCandDTwo] = useState('');
    const [goInidReload, setGoInIdReload] = useState("");
    const [validationErrors, setValidationErrors] = useState({
        title: false,
        opportunitySize: false,
        timeLine: false,
        baseYear: false,
        userIndustries: false,
        regions: false,
        growthItems: false
    });

    useEffect(() => {
        if (data?.data?.context != undefined && data?.data?.call_to_action != undefined) {
            setCandDOne(data?.data?.context);
            setGoInIdReload(goInid);
            setCandDTwo(data?.data?.call_to_action);
        }
    }, [data])

    const handleCk1 = async (e, type) => {
        let strippedText = e.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;

            if (characterCount < 1200) {
                if (type == '1') {
                    setCandDOne(e)
                    if (debounceTimeout) clearTimeout(debounceTimeout);
                    const timeout = setTimeout(() => {
                        if (goInid) {
                            handleEditGou(e, '')
                        }
                    }, 1000);

                    setDebounceTimeout(timeout);
                } else {
                    setCandDTwo(e)
                    if (debounceTimeout) clearTimeout(debounceTimeout);
                    const timeout = setTimeout(() => {
                        if (goInid) {
                            handleEditGou('', e)
                        }
                    }, 1000);

                    setDebounceTimeout(timeout);
                }
            } else {
                toast.error('Only 1200 Characters allowed')
            }
       
    }
    const handleCk2 = async () => {
        if (debounceTimeout) clearTimeout(debounceTimeout);
        const timeout = setTimeout(() => {
            // handleSave(false, false, e)
        }, 1000);

        setDebounceTimeout(timeout);
    }

    useEffect(() => {
        if (data?.data) {
            setGoInTitle(data?.data.name || '')
            setBaseYear(data?.data.base_year || new Date().getFullYear());
            setTimeLine(data?.data.timeline_for_action_from || 0);
            setOpportunitySize(data?.data.opp_size_from || 0);
            setSelectedUserIndustries(data?.data?.criteria?.industry?.map((item) => item.relevant_id) || [])
            setSelectedRegions(data?.data?.criteria?.region?.map((item) => item.relevant_id) || [])
            setSelectedGrowthItems(data?.data?.criteria?.growth?.map((item) => item.relevant_id) || [])
        }

    }, [data, isLoading])


    const validateGOU = () => {
        let isValid = true;
        let errors = [];
        let newValidationErrors = {
            title: false,
            opportunitySize: false,
            timeLine: false,
            baseYear: false,
            userIndustries: false,
            regions: false,
            growthItems: false
        };

        if (!goInTitle.trim()) {
            errors.push('Title is required.');
            newValidationErrors.title = true;
            isValid = false;
        }

        if (opportunitySize === "") {
            errors.push('Opportunity Size in 5 Years must be selected.');
            newValidationErrors.opportunitySize = true;
            isValid = false;
        }

        if (timeLine === "") {
            errors.push('Timeline for Action must be selected.');
            newValidationErrors.timeLine = true;
            isValid = false;
        }

        if (baseYear === "") {
            errors.push('Base Year must be selected.');
            newValidationErrors.baseYear = true;
            isValid = false;
        }

        if (selectedUserIndustries.length === 0) {
            errors.push('At least one User Industry must be selected.');
            newValidationErrors.userIndustries = true;
            isValid = false;
        }

        if (selectedRegions.length === 0) {
            errors.push('At least one Region must be selected.');
            newValidationErrors.regions = true;
            isValid = false;
        }

        if (selectedGrowthItems.length === 0) {
            errors.push('At least one Growth Item must be selected.');
            newValidationErrors.growthItems = true;
            isValid = false;
        }

        setValidationErrors(newValidationErrors);
        return { isValid, errors };
    };

    const handlePostGOU = (data1 = '', data2 = '') => {
        const { isValid, errors } = validateGOU();
        if (!isValid) {
            // Loop over the array and show the errors
            errors.forEach(error => {
                toast.error(error);
            });
            return;
        }

        const dataObj = {
            userid: getUserDetailsFromLocalStorage().user_id,
            title_go: goInTitle,
            opp_size: opportunitySize,
            timeline: timeLine,
            baseyear: baseYear,
            industry: selectedUserIndustries,
            regions: selectedRegions,
            growth: selectedGrowthItems,
            context: data1 != '' ? data1 : candDone,
            call_to_action: data2 != '' ? data2 : candDTwo
        }

        postCriteriaData({ body: dataObj, eco_project_id: projectId })
            .then((res) => console.log(res)).then(() => {
                setSelectedUserIndustries([]);
                setSelectedRegions([]);
                setSelectedGrowthItems([]);
                setGoInTitle('');
                setBaseYear(new Date().getFullYear());
                setTimeLine(0);
                setOpportunitySize(0);
            }).catch((err) => console.log(err));
    }
    const handleEditGou = async (data1 = '', data2 = '') => {
        const { isValid, errors } = validateGOU();
        if (!isValid) {
            // Loop over the array and show the errors
            errors.forEach(error => {
                toast.error(error);
            });
            return;
        }


        try {
            const dataObj = {
                go_id: goInid,
                userid: getUserDetailsFromLocalStorage().user_id,
                title_go: goInTitle,
                opp_size: opportunitySize,
                timeline: timeLine,
                baseyear: baseYear,
                industry: selectedUserIndustries,
                regions: selectedRegions,
                growth: selectedGrowthItems,
                context: data1 !== '' ? data1 : candDone,
                call_to_action: data2 !== '' ? data2 : candDTwo
            }
            postCriteriaData({ body: dataObj, eco_project_id: projectId })
                .then((res) => console.log(res)).then(() => {
                    toast.success('GOU data updated successfully')
                })
        } catch (error) {
            console.log(error)
        }
    }
    const handleDelete = async () => {
        try {
            await deleteGouData({ projectId: projectId, goInId: goInid }).unwrap();
            toast.success('GOU data deleted successfully')

        } catch (error) {
            toast.error('Error deleting GOU data:', error);
        }
    };
    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);
    return (
        <React.Fragment>

            <div className=''>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input type="text"
                                required
                                disabled={!isProjectEditable}
                                value={goInTitle}
                                onChange={(e) => {
                                    setGoInTitle(e.target.value);
                                    setValidationErrors(prev => ({ ...prev, title: false }));
                                }}
                                name='title_name'
                                className={`form-control form-control-sm ${validationErrors.title ? 'is-invalid' : ''}`}
                            />
                        </div>
                    </div>


                </div>
            </div>
            <div className="px-1">
                <div className="row gx-1">
                    <div className="col">
                        <div className="d-flex gpotbmediaobj mb-1 border selva">
                            <div className="flex-shrink-0 align-items-center d-flex">
                                Opp. Size in 5 Years
                            </div>
                            <div className="flex-grow-1 align-self-center d-flex">
                                <select
                                    style={{ width: '85px', margin: '0 auto' }}
                                    disabled={!isProjectEditable}
                                    required value={opportunitySize}
                                    onChange={(e) => {
                                        setOpportunitySize(e.target.value);
                                        setValidationErrors(prev => ({ ...prev, opportunitySize: false }));
                                    }}
                                    className={`form-select form-select-sm ${validationErrors.opportunitySize ? 'is-invalid' : ''}`}>
                                    {growthUniverseData?.oppsize?.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="d-flex gpotbmediaobj mb-1 border">
                            <div className="flex-shrink-0 align-items-center d-flex">
                                Timeline for Action
                            </div>
                            <div className="flex-grow-1 align-self-center">
                                <select
                                    style={{ width: '85px', margin: '0 auto' }}
                                    disabled={!isProjectEditable}
                                    required value={timeLine}
                                    onChange={(e) => {
                                        setTimeLine(e.target.value)
                                        setValidationErrors(prev => ({ ...prev, timeLine: false }));
                                    }}
                                    className={`form-select form-select-sm ${validationErrors.timeLine ? 'is-invalid' : ''}`}>
                                    {growthUniverseData?.timeline?.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="d-flex gpotbmediaobj mb-1 border">
                            <div className="flex-shrink-0 align-items-center d-flex">
                                Base Year
                            </div>
                            <div className="flex-grow-1 align-self-center">
                                <select
                                    style={{ width: '85px', margin: '0 auto' }}
                                    disabled={!isProjectEditable}
                                    required
                                    className={`form-select form-select-sm ${validationErrors.baseYear ? 'is-invalid' : ''}`}
                                    value={baseYear}
                                    onChange={(e) => {
                                        setBaseYear(e.target.value)
                                        setValidationErrors(prev => ({ ...prev, baseYear: false }));
                                    }}>
                                    {range(new Date().getFullYear(), new Date().getFullYear() + 5, 1).map((item) => {
                                        return <option key={item}>{item}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className='border p-1 text-center relreg_title'>
                            Relevant End User Industries for this Growth Opportunity
                        </div>
                        <div className={`row gpicondiv gx-1 ${validationErrors.userIndustries ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.industry?.map((item, i) => {
                                return <div disabled={!isProjectEditable} onClick={() => {
                                    if (selectedUserIndustries?.includes(item.id)) {
                                        setSelectedUserIndustries((prev) => prev.filter((i) => item.id !== i))
                                    }
                                    else setSelectedUserIndustries((prev) => [...prev, item.id]);
                                    setValidationErrors(prev => ({ ...prev, userIndustries: false }));
                                }} key={i} className="col-md-4 mb-1">
                                    <div className={selectedUserIndustries?.includes(item.id) ? "d-flex gpmediaobj p-1 active" : "d-flex gpmediaobj p-1"}>
                                        <div className="flex-shrink-0 align-self-center">
                                            <img src={require(`../../images/industries/${selectedUserIndustries?.includes(item.id) ? `icon_white` : `icon_grey`}/${item.icon_name}`)} />
                                        </div>
                                        <div className="flex-grow-1 ms-2 align-self-center">
                                            <span>{item.name}</span>
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>
                    </div>
                    <div className="col">
                        <div className='border p-1 text-center relreg_title'>
                            Applicable Regions
                        </div>
                        <div className={`row gx-1 ${validationErrors.regions ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.region?.map((item, i) => {
                                return <div disabled={!isProjectEditable} onClick={() => {
                                    if (selectedRegions?.includes(item.id)) {
                                        setSelectedRegions((prev) => prev.filter((i) => item.id !== i))
                                    }
                                    else setSelectedRegions((prev) => [...prev, item.id]);
                                    setValidationErrors(prev => ({ ...prev, regions: false }));
                                }} key={i} className='col-md-6'>
                                    <div className={selectedRegions?.includes(item.id) ? "mapimg mb-1 active" : "mapimg mb-1"}>
                                        <img className='img-fluid' src={require(`../../images/regions/${selectedRegions?.includes(item.id) ? `icon_white` : `icon_grey`}/${item.icon_name}`)} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>


                <div className='mt-3 mb-2 text-center gp_title'>
                    Frost & Sullivan has identified 10 Growth Processes that serve as levers for determining and evaluating new Growth Opportunities.
                </div>
                <div className="d-flex border growthprocess mb-4">
                    <div className="flex-shrink-0 align-self-center text-center p-2">
                        <img className='mb-2' src={require('../../images/growthprocess/gp_icon.png')} />
                        <span>Growth Process</span>
                    </div>
                    <div className="flex-grow-1 bg-white pt-3 px-2">
                        <div className={`row gx-2 ${validationErrors.growthItems ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.growth.slice(0, 5).map((item, i) => {
                                return <div
                                    disabled={!isProjectEditable}
                                    onClick={() => {
                                        if (selectedGrowthItems?.includes(item.id)) {
                                            setSelectedGrowthItems((prev) => prev.filter((i) => item.id !== i))
                                        }
                                        else setSelectedGrowthItems((prev) => [...prev, item.id]);
                                        setValidationErrors(prev => ({ ...prev, growthItems: false }));
                                    }}
                                    key={i} className='col mb-3'>
                                    <div className={selectedGrowthItems?.includes(item.id) ? "iconWrapper active" : "iconWrapper"}>
                                        <img src={require(`../../images/growthprocess/${selectedGrowthItems?.includes(item.id) ? `icon_white` : `icon_blue`}/${item.icon_name}`)} />
                                    </div>
                                    <span className={selectedGrowthItems?.includes(item.id) ? "gouspan active" : "gouspan"} >{item.name}</span>
                                </div>
                            })}

                        </div>
                        <div className={`row gx-2 ${validationErrors.growthItems ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.growth.slice(5, 10).map((item, i) => {
                                return <div
                                    disabled={!isProjectEditable}
                                    onClick={() => {
                                        if (selectedGrowthItems?.includes(item.id)) {
                                            setSelectedGrowthItems((prev) => prev.filter((i) => item.id !== i))
                                        }
                                        else setSelectedGrowthItems((prev) => [...prev, item.id]);
                                        setValidationErrors(prev => ({ ...prev, growthItems: false }));
                                    }}
                                    key={i} className='col mb-3'>
                                    <div className={selectedGrowthItems?.includes(item.id) ? "iconWrapper active" : "iconWrapper"}>
                                        <img src={require(`../../images/growthprocess/${selectedGrowthItems?.includes(item.id) ? `icon_white` : `icon_blue`}/${item.icon_name}`)} />
                                    </div>
                                    <span className={selectedGrowthItems?.includes(item.id) ? "gouspan active" : "gouspan"} >{item.name}</span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className='row gx-1'>
                    <div className='col-6'>
                        <div className='conTextDef mb-1'>
                            <img src={require('../../images/calltoaction/icon_b.png')} />
                            <span>Context and Definition</span>
                        </div>
                        {
                            isProjectEditable ?
                                <CkEditorBase onChange={(data) => handleCk1(data, '1')} data={candDone} editorId={`context-1-${goInidReload}`} tocId={goInid} Limits={[250, 1200, true]} />
                                : <textarea rows={10} className='form-control form-control-sm '></textarea>
                        }
                    </div>
                    <div className='col-6'>
                        <div className='callToAction mb-1 ps-4'>
                            <img src={require('../../images/calltoaction/icon37.png')} />
                            <span>Call to Action</span>
                        </div>
                        {
                            isProjectEditable ?

                                <CkEditorBase onChange={(data) => handleCk1(data, '2')} data={candDTwo} editorId={`call_to_action-2-${goInidReload}`} tocId={goInid} Limits={[250, 1200, true]} />
                                : <textarea rows={10} className='form-control form-control-sm '></textarea>
                        }
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
                <button disabled={!isProjectEditable} type="button" onClick={() => { goInid ? handleEditGou() : handlePostGOU() }} className="btn btn-primary btn-sm me-2"><i className="fa fa-save me-1"></i>Save</button>
                <button disabled={!isProjectEditable} type="button" onClick={handleDelete} className="btn btn-danger btn-sm"><i className="fa fa-close me-1"></i>Delete</button>
            </div>
        </React.Fragment>
    )
}

export default GrowthOpportunityUniverse