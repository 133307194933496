const Hint = ({ show, message }) => {
    if (!show) return null;
    return (
      <div className="hint font14 mb-1">
        <span className="hint-icon me-1">💡</span>
        {message}
      </div>
    );
  };

export default Hint;