// @ts-nocheck
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'
import appData from '../../AppStaticData/EditorData'
import axios from 'axios'
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils'
// import EditorData from "../../AppStaticData/EditorData";

// import axios from "axios";
const { appData1 } = appData

class CommentsAdapter {
  editor
  editorData
  ckEditorData
  constructor(editor, ckEditorData) {
    this.editor = editor
    this.ckEditorData = ckEditorData
  }

  static get requires() {
    return ['CommentsRepository']
  }

  init() {
    const usersPlugin = this.editor.plugins.get('Users')
    const editorId = this.editor.sourceElement?.id
    const editorVal = this.editor.getData()
    let ckData = this.ckEditorData
    var ConfigData = this.editor.config
    const editorVal1 = this.editor.data.get()
    const editorData = this.editor
    const commentsRepositoryPlugin =
      this.editor.plugins.get('CommentsRepository')
    for (const commentThread of ckData?.comment_list) {
      commentsRepositoryPlugin.addCommentThread(commentThread)
    }
    commentsRepositoryPlugin.adapter = {
      async addComment(data) {
        console.log('Comment added------------>', data)
        try {
          const project_id = window.location.href.split('/').pop()
          let postData = {
            user_id: getUserDetailsFromLocalStorage()?.user_id,
            thread_id: data?.threadId,
            comment_id: data?.commentId,
            content: data?.content,
            ckeditor_id: ConfigData?._config?.id,
            type: 'add',
          }
          let response = await axios.post(
            `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/ckeditoraddcoment`,
            postData
          )
          if (ConfigData?._config?.id == 'scope_analysis') {
            let secondaryPostData = {
              scope_analysis_details: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/update`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (ConfigData?._config?.id.split('-')[0] == 'toc') {
            let secondaryPostData = {
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/tocprojectdata/update/${project_id}/${ConfigData?._config?.tocId}/`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (
            ConfigData?._config?.id.split('-')[0] == 'siWhy' ||
            ConfigData?._config?.id.split('-')[0] == 'siPer'
          ) {
            let secondaryPostData = {
              type: 'strategic_imperative',
              column:
                ConfigData?._config?.id.split('-')[0] == 'siWhy'
                  ? 'strategic_imperative_why'
                  : 'strategic_imperative_perspective',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else {
            let secondaryPostData = {
              type: 'gou',
              column:
                ConfigData?._config?.id.split('-')[0] == 'context'
                  ? 'context'
                  : 'call_to_action',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          }
        } catch (error) {
          console.log(error)
        }
 
        return Promise.resolve({
          createdAt: new Date(), // Should be set on the server side.
        })
      },

      async updateComment(data) {
        console.log('Comment updated', data)
        try {
          const project_id = window.location.href.split('/').pop()
          let postData = {
            user_id: getUserDetailsFromLocalStorage()?.user_id,
            thread_id: data?.threadId,
            comment_id: data?.commentId,
            content: data?.content,
            ckeditor_id: ConfigData?._config?.id,
            type: 'update',
          }
          let response = await axios.post(
            `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/ckeditoraddcoment`,
            postData
          )

          if (ConfigData?._config?.id == 'scope_analysis') {
            let secondaryPostData = {
              scope_analysis_details: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/update`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (ConfigData?._config?.id.split('-')[0] == 'toc') {
            let secondaryPostData = {
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/tocprojectdata/update/${project_id}/${ConfigData?._config?.tocId}/`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (
            ConfigData?._config?.id.split('-')[0] == 'siWhy' ||
            ConfigData?._config?.id.split('-')[0] == 'siPer'
          ) {
            let secondaryPostData = {
              type: 'strategic_imperative',
              column:
                ConfigData?._config?.id.split('-')[0] == 'siWhy'
                  ? 'strategic_imperative_why'
                  : 'strategic_imperative_perspective',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else {
            let secondaryPostData = {
              type: 'gou',
              column:
                ConfigData?._config?.id.split('-')[0] == 'context'
                  ? 'context'
                  : 'call_to_action',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          }
        } catch (error) {
          console.log(error)
        }
        return Promise.resolve()
      },

      async removeComment(data) {
        try {
          const project_id = window.location.href.split('/').pop()
          let postData = {
            user_id: getUserDetailsFromLocalStorage()?.user_id,
            thread_id: data?.threadId,
            comment_id: data?.commentId,
            content: 'dummy',
            ckeditor_id: ConfigData?._config?.id,
            type: 'rem_cmt',
          }
          let response = await axios.post(
            `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/ckeditoraddcoment`,
            postData
          )
          if (ConfigData?._config?.id == 'scope_analysis') {
            let secondaryPostData = {
              scope_analysis_details: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/update`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (ConfigData?._config?.id.split('-')[0] == 'toc') {
            let secondaryPostData = {
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/tocprojectdata/update/${project_id}/${ConfigData?._config?.tocId}/`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (
            ConfigData?._config?.id.split('-')[0] == 'siWhy' ||
            ConfigData?._config?.id.split('-')[0] == 'siPer'
          ) {
            let secondaryPostData = {
              type: 'strategic_imperative',
              column:
                ConfigData?._config?.id.split('-')[0] == 'siWhy'
                  ? 'strategic_imperative_why'
                  : 'strategic_imperative_perspective',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else {
            let secondaryPostData = {
              type: 'gou',
              column:
                ConfigData?._config?.id.split('-')[0] == 'context'
                  ? 'context'
                  : 'call_to_action',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          }
        } catch (error) {
          console.log(error)
        }
        return Promise.resolve()
      },
      async resolveCommentThread(data) {
        try {
          const project_id = window.location.href.split('/').pop()
          let postData = {
            user_id: getUserDetailsFromLocalStorage()?.user_id,
            thread_id: data?.threadId,
            comment_id: 'dummy',
            content: 'dummy',
            ckeditor_id: ConfigData?._config?.id,
            type: 'rem_thread',
          }
          let response = await axios.post(
            `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/ckeditoraddcoment`,
            postData
          )
          await new Promise((resolve) => setTimeout(resolve, 100))
          if (ConfigData?._config?.id == 'scope_analysis') {
            let secondaryPostData = {
              scope_analysis_details: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/update`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (ConfigData?._config?.id.split('-')[0] == 'toc') {
            let secondaryPostData = {
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/tocprojectdata/update/${project_id}/${ConfigData?._config?.tocId}/`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (
            ConfigData?._config?.id.split('-')[0] == 'siWhy' ||
            ConfigData?._config?.id.split('-')[0] == 'siPer'
          ) {
            let secondaryPostData = {
              type: 'strategic_imperative',
              column:
                ConfigData?._config?.id.split('-')[0] == 'siWhy'
                  ? 'strategic_imperative_why'
                  : 'strategic_imperative_perspective',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else {
            let secondaryPostData = {
              type: 'gou',
              column:
                ConfigData?._config?.id.split('-')[0] == 'context'
                  ? 'context'
                  : 'call_to_action',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          }
        } catch (error) {
          console.log(error)
        }
        return Promise.resolve({
          resolvedAt: new Date(), 
          resolvedBy: usersPlugin.me.id, 
        })
      },

      async addCommentThread(data) {
        const commentThreadsData = commentsRepositoryPlugin.getCommentThreads({
          skipNotAttached: true,
          skipEmpty: true,
          toJSON: true,
        })
        try {
          const project_id = window.location.href.split('/').pop()
          let postData = {
            user_id: getUserDetailsFromLocalStorage()?.user_id,
            thread_id: data?.threadId,
            comment_id: data?.comments[0]?.commentId,
            content: data?.comments[0]?.content,
            ckeditor_id: ConfigData?._config?.id,
            type: 'add',
          }
          let response = await axios.post(
            `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/ckeditoraddcoment`,
            postData
          )
          if (ConfigData?._config?.id == 'scope_analysis') {
            let secondaryPostData = {
              scope_analysis_details: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/update`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (ConfigData?._config?.id.split('-')[0] == 'toc') {
            let secondaryPostData = {
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/tocprojectdata/update/${project_id}/${ConfigData?._config?.tocId}/`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (
            ConfigData?._config?.id.split('-')[0] == 'siWhy' ||
            ConfigData?._config?.id.split('-')[0] == 'siPer'
          ) {
            let secondaryPostData = {
              type: 'strategic_imperative',
              column:
                ConfigData?._config?.id.split('-')[0] == 'siWhy'
                  ? 'strategic_imperative_why'
                  : 'strategic_imperative_perspective',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else {
            let secondaryPostData = {
              type: 'gou',
              column:
                ConfigData?._config?.id.split('-')[0] == 'context'
                  ? 'context'
                  : 'call_to_action',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          }
        } catch (error) {
          console.log(error)
        }

        return Promise.resolve({
          threadId: data.threadId,
          comments: data.comments.map((comment) => ({
            commentId: comment.commentId,
            createdAt: new Date(),
          })), 
        })
      },

      getCommentThread(data) {
        const commentThreadsData = commentsRepositoryPlugin.getCommentThreads({
          skipNotAttached: true,
          skipEmpty: true,
          toJSON: true,
        })
        var d1 = localStorage.getItem('commentsThreadData')
        return Promise.resolve({
          d1,
        })
      },

      updateCommentThread(data) {
        console.log('Comment thread updated', data)
        return Promise.resolve()
      },

      reopenCommentThread(data) {
        console.log('Comment thread reopened', data)
        return Promise.resolve()
      },

      async removeCommentThread(data) {
        console.log('Comment thread removed', data)
        try {
          const project_id = window.location.href.split('/').pop()
          let postData = {
            user_id: getUserDetailsFromLocalStorage()?.user_id,
            thread_id: data?.threadId,
            comment_id: 'dummy',
            content: 'dummy',
            ckeditor_id: ConfigData?._config?.id,
            type: 'rem_thread',
          }
          let response = await axios.post(
            `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/ckeditoraddcoment`,
            postData
          )
          if (ConfigData?._config?.id == 'scope_analysis') {
            let secondaryPostData = {
              scope_analysis_details: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/update`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (ConfigData?._config?.id.split('-')[0] == 'toc') {
            let secondaryPostData = {
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/tocprojectdata/update/${project_id}/${ConfigData?._config?.tocId}/`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (
            ConfigData?._config?.id.split('-')[0] == 'siWhy' ||
            ConfigData?._config?.id.split('-')[0] == 'siPer'
          ) {
            let secondaryPostData = {
              type: 'strategic_imperative',
              column:
                ConfigData?._config?.id.split('-')[0] == 'siWhy'
                  ? 'strategic_imperative_why'
                  : 'strategic_imperative_perspective',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else {
            let secondaryPostData = {
              type: 'gou',
              column:
                ConfigData?._config?.id.split('-')[0] == 'context'
                  ? 'context'
                  : 'call_to_action',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          }
        } catch (error) {
          console.log(error)
        }
        return Promise.resolve()
      },
    }
  }
}

export default CommentsAdapter
