import React, { useState } from 'react'
import { Button, Modal, Box, Typography } from '@mui/material';
import '../CSS/TopBar.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/style.css'
import '../../assets/css/../../assets/css/mediaquery.css'
import { useNavigate } from 'react-router-dom';
import frost_logo from '../../images/frost_logo.png'
import hamburger from '../../assets/images/haburger.svg'
import logo from '../../assets/images/logo.svg'
import homeIcon from "../../assets/images/PNG/home.png"
import profile from "../../assets/images/PNG/profile.png" 
import dropDownNew from '../../assets/images/PNG/drop down.png'
import settings from '../../assets/images/PNG/settings.png'
import logout from "../../assets/images/PNG/logout.png"

function TopBar({ sendState, userData }) {

    const [isOpen, setIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const [active,setActive]=useState(false)
    const navigate = useNavigate()
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        sendState(!isOpen)
    };
    const openNotifications = () => {
        navigate('/notifications')
    }
    const handleModalClose = () => {
        setModalOpen(false)
    }
    const goHome = () => {
        window.open('https://growthuat.frost.com/', '_self');
    }
    const handleLogout = () => {
        const cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
        sessionStorage.clear()
        window.location.href = "https://growthuat.frost.com"

    }
    const goHomePage = ()=>{
        window.location.href='https://growthuat.frost.com'
    }
    return (
        <>
            {/* <nav className="navbar navbar-expand-sm navbar-light custom-navbar py-0 sticky-top">
                <div className="container-fluid">
                    <div className='d-flex align-items-center'>
                        <img onClick={toggleSidebar} className='me-3' src={require('../../images/hamburger.png')} alt="logo" />
                        {frost_logo ? <img src={frost_logo} alt='null' /> : null}
                    </div>
                    <ul className="navbar-nav ">
                        <li className="nav-item d-flex align-self-center">
                            <a title='https://growthuat.frost.com' className="nav-link d-flex align-self-center" href="https://growthuat.frost.com" target='_blank'>
                                <i style={{ fontSize: '22px' }} title='Home' className='fa fa-home me-2'></i>
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className='me-2' src={userData?.image} alt='Person Image' style={{ height: '35px', borderRadius: '20px' }} />
                                {userData?.name}
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" onClick={handleLogout}>Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav> */}
            
            <header class="container-fluid d-flex align-items-center justify-content-between gp-header">
                <div class="d-flex align-items-center w-50 gp-header-left">
                    <button class="border-0 bg-transparent pointer-event gp-hamburger" onClick={toggleSidebar}>
                        <img class="img-fluid" src={hamburger} alt="hamburger" />
                    </button>
                    <div class="frost-and-sullivan-logo">
                        <img class="img-fluid" src={logo} alt="logo" />
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end w-50 gp-header-right">
                    <button type="button" class="gp-home-icon">
                        <img class="img-fluid" src={homeIcon} alt="home"  title='https://growthuat.frost.com' onClick={goHomePage}/>
                    </button>
                    <div class="d-flex align-items-center position-relative gp-profile-det" onClick={()=>setActive(!active)}>
                        <div class="gp-profile-icon">
                            <div class="gp-profile-icon-sub">
                                <img class="img-fluid" src={userData?.image} alt="profile" />
                            </div>
                        </div>
                        <span class="gp-profile-name">{userData?.name}</span>
                        <button type="button" class="gp-dropdown-icon">
                            <img class="img-fluid" src={dropDownNew} alt="drop down" />
                        </button>
                        <div class= {`position-absolute overflow-auto gp-profile-action ${active ? 'active' : ''}`} >
                            <ul class="w-100 h-100">
                                <li class="w-100 d-flex align-items-center gp-option-btn">
                                    <div class="gp-option-logo">
                                        <img src={settings} alt="settings"/>
                                    </div>
                                    <span>Settings</span>
                                </li>
                                <li class="w-100 d-flex align-items-center gp-option-btn" onClick={handleLogout}>
                                    <div class="gp-option-logo">
                                        <img src={logout} alt="logout"/>
                                    </div>
                                    <span >Logout</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                style={{
                    position: 'absolute',
                    top: 70,
                    left: 950,
                }}
            >
                <Box sx={{

                    width: 300,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Modal Title
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        This is the content of the modal. You can put any component here.
                    </Typography>
                    <Button onClick={handleModalClose} sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>
        </>
    )
}

export default TopBar



