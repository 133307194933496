import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { useDeleteCloneMutation, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';

function CompetitiveEnvironment(props) {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [rows, setRows] = useState([{
        field_value: "",
        field_details: "",
        sort_by: 1
    }]);
    const [details, setDetails] = useState([]);
    const {projectId}=useParams();
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved]=useUpdateTocIsSavedMutation()
    const {handleSectionSave}=useContext(ProgressContext)
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [loader, setLoader] = useState(false);

    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved":1
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log("There was an error!", error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    
    const headers = {
        Authorization: `Bearer ${document?.cookie?.split(';')?.find(token => token.startsWith(' $access_token'))?.split('=')[1]}`,
    }
    const addLine = () => {
        const newRow = {
            field_value: "",
            field_details: "",
            sort_by: rows.length + 1
        };
        setRows([...rows, newRow]);
    };

    const handleSave = async () => {
        setDetails(rows);
        const postData = {
            "user_id": getUserDetailsFromLocalStorage().user_id,
            "comp_env": rows,
            "tocid":props.id
        }
        try {
            let response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/compenvironment/${projectId}`, postData, { headers, params: { projectid: projectId } })
            toast.success('Competitive Environment saved successfully')
            getCompetitiveEnvironment()
            updateTocIsSaved({projectId:projectId,tocId:props.id})
            handleSectionSave(props.id)
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!')

        }
    };

    const getCompetitiveEnvironment = async () => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/compenvironment/${projectId}/${props.id}`)
            if (Object.keys(res?.data?.data).length != 0) {
                const transformedData = transformData(res?.data?.data);
                setRows([...transformedData]);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const transformData = (data) => {
        return data.map(item => ({
            field_value: item.field_value,
            field_details: item.field_details,
            sort_by: item.sort_by
        })).sort((a, b) => a.sort_by - b.sort_by);;
    };

    useEffect(() => {
        if (props?.go_in_id) {
            setHeader(props?.name)
            getCompetitiveEnvironment()
        }
    }, [props])

    
    const [updateIsApplicable,{isLoading:isUpdateApplicableLoading}]=useUpdateIsApplicableMutation()
    const [deleteClone,{isLoading:isCloneDeleteLoading}]=useDeleteCloneMutation();
    const handleDeleteClone=async()=>{
            try {
                deleteClone({
                    projectId:projectId,
                    tocId:props.id
                })
                toast.success("Clone successfully deleted")
            } catch (error) {
                console.log(error)
                toast.error("Something went wrong")
            }
        
        
    }
    const [isApplicable,setIsApplicable]=useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable:crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1-isApplicable)
        handleUpdateIsApplicable(1-isApplicable)
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
          handleDeleteClone();
          // Perform the delete action here
        } 
        setOpenDialog(false);
      };

      const handleClose = () => {
        setOpenDialog(false);
      };

      const onDelete=()=>{
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
      }
    return (

        <div className="card">

            {loader||isCloneDeleteLoading ? <SSOLoader /> : null}
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    {
                        headerIsEdit && isProjectEditable ?
                            <div className='input-group input-group-sm w-50'>
                                <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                <button type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                            </div>
                            :
                            <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
                    }

                    <div className='d-flex align-items-center'>
                        <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                                <input 
                                    className="form-check-input" 
                                    checked={isApplicable} 
                                    onChange={handleToggle}
                                    type="checkbox" 
                                    role="switch" 
                                    id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                            </div>
                        {props.isClone?<i className="me-3 fa fa-plus-square" alt="Square Plus"></i>:null}             
                        {(props.roleType!=='analyst')||(props.hasCloned)?<i className="fa fa-trash-o" alt="Trash" onClick={onDelete} title='Delete'></i>:null}
                        <DialogueBox 
                            openDialog={openDialog} 
                            setOpenDialog={setOpenDialog}
                            dialogTitle={dialogContent.title}
                            dialogContentText={dialogContent.text} 
                            handleConfirmation={handleConfirmation}
                            handleClose={handleClose}
                        />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <table className="table table-bordered table-sm ctoa_table">
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td><textarea disabled={!isProjectEditable} className="form-control form-control-sm" rows={2} value={row.field_value} onChange={(e) => {
                                    const updatedRows = [...rows];
                                    updatedRows[index].field_value = e.target.value;
                                    setRows(updatedRows);
                                }}></textarea></td>
                                <td><textarea className="form-control form-control-sm" rows={2} disabled={!isProjectEditable} value={row.field_details} onChange={(e) => {
                                    const updatedRows = [...rows];
                                    updatedRows[index].field_details = e.target.value;
                                    setRows(updatedRows);
                                }}></textarea></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isProjectEditable && <div className='d-flex justify-content-center'> <button type='button'className='btn btn-outline-secondary btn-sm mb-3 rounded-0' onClick={addLine}>
                    <i className='fa fa-plus me-1'></i> Add Line
                </button> </div>}

                {isProjectEditable && <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-primary btn-sm me-2" onClick={handleSave}><i className="fa fa-save me-1" ></i>Save</button>
                    <button type="button" className="btn btn-danger btn-sm"><i className="fa fa-close me-1"></i>Cancel</button>
                </div>}
            </div>
            <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{backgroundColor:'#1582C5'}}>
                <Footer/>
            </div>

        </div>



    );
}

export default CompetitiveEnvironment;

