import React, { useState, useEffect, useRef, useContext } from 'react';
import {  toast } from 'react-toastify';
import { usePostTocDataMutation, useUpdateIsApplicableMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { useParams } from 'react-router-dom';

function BestPracticeRecognition(props) {
    const {handleSectionSave}=useContext(ProgressContext)
    const [loader, setLoader] = useState(false)
    const [postTocData] = usePostTocDataMutation();
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const {projectId} = useParams();
    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved":1
                }
            }).then((res) => console.log(res))
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log("There was an error!", error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        setHeader(props?.name)
    }, [props])
    const [updateIsApplicable,{isLoading:isUpdateApplicableLoading}]=useUpdateIsApplicableMutation();
    const [isApplicable,setIsApplicable]=useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable:crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1-isApplicable)
        handleUpdateIsApplicable(1-isApplicable)
    };
    
    return (
        <>
            <div className="card mb-3 rounded-0">
                
                {loader||isUpdateApplicableLoading ? <SSOLoader /> : null}
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            headerIsEdit ?
                                <div className='input-group input-group-sm w-50'>
                                    <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                    <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                    <button type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                                </div>
                                :
                                <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
                        }
                        <div>
                        <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                                <input 
                                    className="form-check-input" 
                                    checked={isApplicable} 
                                    onChange={handleToggle}
                                    type="checkbox" 
                                    role="switch" 
                                    id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                            </div>
            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BestPracticeRecognition
