import React, { useEffect, useState, useContext } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { ProjectStatusContext } from './Providers/ProjectStatusProvider';
function SplitModel({ dnrDataSet, dummy,headings }) {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const splitTypeOptions = headings;
    const [splitType, setSplitType] = useState('');
    const [level, setLevel] = useState(0);
    const [currentId, setCurrentId] = useState('');
    const [splitSegments, setSplitSegments] = useState([]);
    const [dummyState, setDummyState] = useState(0);
    const [calculation, setCalculation] = useState(false)
    const [splitData, setSplitData] = useState({});
    const [value, setValue] = useState(0);
    const [totalData, setTotalData] = useState({});
    const [tabHeadings, setTabHeadings] = useState([]);
    const project_id = window.location.href.split('/').pop();

    const getUserDetailsFromLocalStorage = () => {
        const userDetailsString = sessionStorage.getItem('userDetails');
        if (userDetailsString) {
            return JSON.parse(userDetailsString);
        } else {
            return null;
        }
    };

    function getParentWithChildren(id, data) {
        function findNodeById(nodeId, nodes) {
            for (const node of nodes) {
                if (node.Id === nodeId || node.id === nodeId) {
                    return node;
                }
                if (node.children) {
                    const found = findNodeById(nodeId, node.children);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        }

        const node = findNodeById(id, data);
        if (!node) return null;

        const result = [
            {
                id: node.Id || node.id,
                name: node.name,
                level: node.level,
                children: node.children
            },
            ...node.children.map(child => ({
                id: child.id,
                name: child.name,
                level: child.level,
                children: child?.children
            }))
        ];

        return result;
    }

    function extractNodes(nodes) {
        const result = [];

        function traverse(node) {
            if (node.level === 0 || node.level === 1) {
                result.push({
                    id: node.id,
                    name: node.name,
                    level: node.level,
                    children: node.children
                });
            }
            if (node.level < 1) {
                node.children.forEach(child => traverse(child));
            }
        }

        nodes.forEach(node => traverse(node));

        return result;
    }

    useEffect(() => {
        if (dnrDataSet?.initialNodes) {
            const extractedNodes = extractNodes(dnrDataSet?.initialNodes);
            const newValue = extractedNodes.filter(e => e?.level === 0);
            setValue(newValue[0]?.id);
            setSplitSegments(extractedNodes);
            setTabHeadings(extractedNodes);
            setCurrentId(newValue[0]?.id);
            setLevel(newValue[0]?.level);
        }
    }, [dnrDataSet]);

    const getSplitData = async (id, currentLevel) => {
        try {
            const seg = id || tabHeadings.filter(e => e?.level === 0)[0]?.id;
            const Level = currentLevel || 0;

            const params = {
                seg_id: seg,
                user_id: getUserDetailsFromLocalStorage()?.user_id,
                level: Level
            };

            const data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/getsplitmodel/`, { params });
            setSplitData(data?.data?.split_data);
            setTotalData(data?.data?.total_data);
            setSplitType(data?.data?.formula_type)
            if (Object.keys(data?.data?.total_data).length == 0) {
                setCalculation(false)
            }

        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = async (event, newValue) => {

        setSplitSegments(getParentWithChildren(newValue, tabHeadings));
        setValue(newValue);
        const level = tabHeadings.filter(e => e?.id === newValue)[0].level;
        setLevel(level);
        setCurrentId(newValue);
        setSplitData(null);
        setSplitType('')


    };

    function calculateRevenue(prevrevenue, curgrowth) {
        let data = prevrevenue * (100 + parseFloat(curgrowth)) / 100
        return data.toFixed(2)
    }

    function calculaterevenuebymarket(curmarket, currtotrevenue) {
        let data = curmarket * currtotrevenue / 100
        return data.toFixed(2)
    }

    function calulateGrowth(currrevenue, prevrevenue) {
        let data = (currrevenue - prevrevenue) / prevrevenue * 100
        return data.toFixed(2)
    }

    function marketValue(currevenue, currtotrevenue) {
        let data = (currevenue / currtotrevenue) * 100
        return data.toFixed(2)
    }

    const handleInputChange = (segmentId, year, field, value) => {
        setSplitData(prevSplitData => {
            const newSplitData = { ...prevSplitData };
            if (!newSplitData[segmentId]) {
                newSplitData[segmentId] = {};
            }
            if (!newSplitData[segmentId][year]) {
                newSplitData[segmentId][year] = {};
            }
            newSplitData[segmentId][year][field] = value;
            return newSplitData;
        });
    };

    function calculateformula(formula_type, postData) {
        let postdata = { ...postData };
        let tot_seg_id = Object.keys(postdata?.total_data)[0]
        for (const segId in postdata.split_data) {
            if (postdata.split_data.hasOwnProperty(segId)) {

                const revenueSplitData = postdata.split_data[segId]
                let prevrevenue
                let currevenue
                if (formula_type == 'revenue_growth') {
                    Object.keys(revenueSplitData).forEach(async function (key, index) {
                        let currtotrevenue = postdata.total_data[tot_seg_id][key]['revenue']
                        let curgrowth = revenueSplitData[key]['revenue_growth']
                        if (index == 0) {
                            prevrevenue = 0
                            currevenue = revenueSplitData[key]['revenue']
                        }
                        else {
                            prevrevenue = revenueSplitData[key - 1]['revenue']
                            revenueSplitData[key]['revenue'] = calculateRevenue(prevrevenue, curgrowth)
                            currevenue = revenueSplitData[key]['revenue']
                        }

                        revenueSplitData[key]['market_share_value'] = marketValue(currevenue, currtotrevenue)


                    })
                }
                if (formula_type == 'revenue') {
                    Object.keys(revenueSplitData).forEach(async function (key, index) {
                        let currtotrevenue = postdata.total_data[tot_seg_id][key]['revenue']
                        let currevenue = revenueSplitData[key]['revenue']
                        if (index == 0) {
                            prevrevenue = 0
                            currevenue = revenueSplitData[key]['revenue']
                        }
                        else {
                            prevrevenue = revenueSplitData[key - 1]['revenue']
                        }
                        revenueSplitData[key]['revenue_growth'] = calulateGrowth(currevenue, prevrevenue)
                        revenueSplitData[key]['market_share_value'] = marketValue(currevenue, currtotrevenue)


                    })
                }
                if (formula_type == 'market') {
                    Object.keys(revenueSplitData).forEach(async function (key, index) {
                        let currtotrevenue = postdata.total_data[tot_seg_id][key]['revenue']
                        let curmarket = revenueSplitData[key]['market_share_value']
                        if (index == 0) {
                            prevrevenue = 0
                        }
                        else {
                            prevrevenue = revenueSplitData[key - 1]['revenue']
                        }
                        revenueSplitData[key]['revenue'] = calculaterevenuebymarket(currtotrevenue, curmarket)
                        let currevenue = revenueSplitData[key]['revenue']
                        revenueSplitData[key]['revenue_growth'] = calulateGrowth(currevenue, prevrevenue)
                    })
                }
            }

        }
        setSplitData(postdata?.split_data)
        setCalculation(true)
        return postdata
    }

    const saveSplitData = async () => {
        try {
            if (splitType.length > 0) {
                let postData = {
                    user_id: getUserDetailsFromLocalStorage()?.user_id,
                    dr_type: 'split',
                    formula_type: splitType,
                    split_data: splitData,

                }
                const data = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/createsplitmodel/`, postData);
                if (data?.status == '200') {
                    toast.success("Split model created successfully")
                } else {
                    toast.error('Something went wrong')
                }
            }
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        }

    };

    useEffect(() => {
        if (currentId?.length > 0) {
            getSplitData(currentId, level);
        }
    }, [currentId, level, dummy]);

    const handleSplitTypeChange = (event) => {
        setSplitType(event.target.value);
    };

    const isFieldEnabled = (field, yearIndex) => {
        if (splitType === 'revenue_growth') {
            if (field === 'revenue') {
                return yearIndex === 0;
            }
            if (field === 'revenue_growth') {
                return yearIndex > 0;
            }
            return false;
        }
        if (splitType === 'revenue') {
            return field === 'revenue';
        }
        if (splitType === 'market') {
            return field === 'market_share_value';
        }
        return false;
    };

    const CalculateData = async () => {
        try {
            if (splitType.length > 0) {
                let postData = {
                    user_id: getUserDetailsFromLocalStorage()?.user_id,
                    total_data: totalData,
                    split_data: splitData,
                };

                const newData = calculateformula(splitType, postData);
                setSplitData(newData?.split_data);
                setDummyState(dummyState + 1);
            } else {
                toast.error('Please select the split type');
            }
        } catch (error) {
            toast.error('Something went wrong');
            console.log(error);
        }
    };

    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <h6 className='text-center mb-0 text-white'>
                        SPLIT MODEL
                    </h6>
                </div>
                <div className='card-body'>
                    <div className='border d-inline-block px-3 py-1 mb-2'>
                        {splitTypeOptions?.map((e) =>
                            <div className='form-check form-check-inline'>
                                <input className='form-check-input' type='radio' checked={splitType == e?.value} value={e?.value} onChange={handleSplitTypeChange} name='splitType' id={e} />
                                <label className='form-check-label' htmlFor={e}>{e?.name}</label>
                            </div>
                        )}
                    </div>
                    <TabContext value={value}>
                        <TabList sx={{ ml: 2, flex: 1 }} component="div" onChange={handleChange} variant='scrollable' scrollButtons="auto">
                            {tabHeadings?.map(e => <Tab key={e?.id} value={e?.id} style={{ color: '#072B5B', textTransform: 'none' }} label={e?.name} />)}
                        </TabList>

                        {Object.keys(totalData).length > 0 ? splitSegments.map((e, index) => (
                            <TabPanel className='pb-0' key={index} value={e?.id}>
                                <div className='table-container'>
                                    <div className='table-wrapper mt-2'>
                                        <table className='table table-bordered table-striped table-sm text-center table-excelToHtml'>
                                            <thead>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <h6 className='mb-0 font14'>{e?.level === level ? e?.name : 'Total'}</h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="col" className='align-middle'>Year</th>
                                                    <th>Revenue(Millions)</th>
                                                    <th>Growth %</th>
                                                </tr>
                                            </thead>
                                            <tbody className='firsttable'>
                                                {Object.keys(totalData).length > 0 && Object.keys(Object.values(totalData)[0] || {}).map((year, yearIndex) => (
                                                    <tr key={year}>
                                                        <td>{year}</td>
                                                        <td>{parseFloat(Object.values(totalData)[0][year]?.revenue || 0)}</td>
                                                        <td>{parseFloat(Object.values(totalData)[0][year]?.revenue_growth || 0)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {splitSegments.filter(segment => segment?.level !== level).map((segment, index) => (
                                        <div className='table-wrapper mt-2'>
                                            <table key={index} className='table table-bordered table-striped table-sm text-center table-excelToHtml'>
                                                <thead>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <h6 className='mb-0 font14'>{segment?.name}</h6>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Year</th>
                                                        <th scope="col" className='align-middle revenue'>Revenue</th>
                                                        <th>Revenue Growth %</th>
                                                        <th>Market Share %</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {splitData && splitData[segment?.id] && Object.keys(splitData[segment.id] || {}).map((year, yearIndex) => (
                                                        <tr key={year}>
                                                            <td style={{ width: '10%' }} className='align-middle'>{year}</td>
                                                            <td>
                                                                <input
                                                                    type='number'
                                                                    className='form-control form-control-sm input-cus'
                                                                    value={splitData[segment.id][year]?.revenue?.toString()}
                                                                    onChange={(event) => handleInputChange(segment.id, year, 'revenue', event.target.value)}
                                                                    disabled={!isFieldEnabled('revenue', yearIndex)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type='number'
                                                                    className='form-control form-control-sm input-cus'
                                                                    value={splitData[segment.id][year]?.revenue_growth?.toString() == 'Infinity' ? '-' : splitData[segment.id][year]?.revenue_growth?.toString()}
                                                                    onChange={(event) => handleInputChange(segment.id, year, 'revenue_growth', event.target.value)}
                                                                    disabled={!isFieldEnabled('revenue_growth', yearIndex)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type='number'
                                                                    className='form-control form-control-sm input-cus'
                                                                    value={splitData[segment.id][year]?.market_share_value?.toString()}
                                                                    onChange={(event) => handleInputChange(segment.id, year, 'market_share_value', event.target.value)}
                                                                    disabled={!isFieldEnabled('market_share_value', yearIndex)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                                {isProjectEditable && <div className='d-flex justify-content-around mt-3'>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={CalculateData}>
                                        <i className="fa fa-save me-1"></i>Calculate
                                    </button>

                                    <button type="button" className="btn btn-primary btn-sm" onClick={saveSplitData}>
                                        <i className="fa fa-save me-1"></i>Save and Continue
                                    </button>
                                </div>}
                            </TabPanel>
                        )) : <div className='text-center'><span style={{ color: 'red' }}> *Please fill split details of the parent and then proceed to this tab</span></div>}
                    </TabContext>
                </div>
            </div>
        </>
    );
}

export default SplitModel;
