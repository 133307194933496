import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetProjectDetailsQuery, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import CkEditorBase from '../../CkEditorReact/CkEditorBase';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { useParams } from 'react-router-dom';
import Footer from './Footer.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import DialogueBox from '../../ComponentUtils/DialogBox.js';

function ScopeAnalysis(props) {
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const { handleSectionSave } = useContext(ProgressContext)
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation();
    const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess, refetch: projectDataRefetch } = useGetProjectDetailsQuery({ projectId: props?.go_in_id });
    const [sector, setSector] = useState({})
    const [ckId, setCkId] = useState(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const getSectorData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsectorbyid/${id}`);
            setSector(res?.data?.data?.[0] || {})
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (ProjectData?.data) {
            getSectorData(ProjectData?.data?.sector_id)
            setEditorContent(ProjectData?.data?.scope_analysis_details)
            setCkId(props?.id)
        }
    }, [ProjectData, isProjectDataLoading, isProjectDataSuccess])
    const [clone, setClone] = useState(0)
    const [type, setType] = useState('Application');
    const [typeVal, setTypeVal] = useState(0)
    const selectRef = useRef(null);
    const [segValue, setSegValue] = useState('')
    const [segDetails, setSegDetails] = useState([])
    const [segmentNo, setSegmentNo] = useState(3);
    const [loader, setLoader] = useState(false)
    const [segmentTopics, setSegmentTopics] = useState(Array.from({ length: 6 }, () => []));
    const segmentNameRefs = useRef([]);
    const [segmentationTypes, setSegmentationTypes] = useState([])
    const [segmentData, setSegmentData] = useState({
        user_id: getUserDetailsFromLocalStorage().user_id,
        segmentations: []
    });
    const [postTocData] = usePostTocDataMutation();
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [regions, setRegions] = useState([]);
    const [editorContent, setEditorContent] = useState('');
    const { projectId } = useParams();
    const handleEditorChange = (content) => {
        setEditorContent(content);
        let strippedText = content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;

        if (characterCount < 1500) {
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                handleCkSave(content)
            }, 1000);

            setDebounceTimeout(timeout);

        } else {
            toast.error('Only 1500 characters allowed!')
        }



    };
    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }


    useEffect(() => {
        const newSegmentData = {
            ...segmentData,
            segmentations: segmentTopics.map((topics, index) => ({
                name: segmentNameRefs.current[index]?.value || null,
                isparent: 1,
                sort: index + 1,
                id: null,
                children: topics.map((topic, idx) => ({
                    name: topic,
                    isparent: 0,
                    sort: idx + 1,
                    id: null
                }))
            }))
        };
        setSegmentData(newSegmentData);
    }, [segmentTopics]);


    const segmentNum = (e) => {
        const newSegmentNo = parseInt(e?.target?.value || e);
        setSegmentNo(newSegmentNo);
        segmentNameRefs.current = Array.from({ length: newSegmentNo }, (_, index) => segmentNameRefs.current[index] || null);
    };
    const getSegmentNameRef = (segmentIndex) => {
        if (segmentNameRefs.current && segmentNameRefs.current[segmentIndex - 1]) {
            return segmentNameRefs.current[segmentIndex - 1];
        }
        return null;
    };

    function transformApiResponse(apiResponse) {
        let segmentations = [];
        var type
        Object.keys(apiResponse).forEach(key => {
            const segment = apiResponse[key];
            type = apiResponse[key].type
            const segmentation = {
                name: segment?.name,
                isparent: segment.parent_id === null ? 1 : 0,
                sort: segment.sort_by,
                id: segment.id,
                children: [],
                isDeleted: 0
            };

            if (Object.keys(segment.children).length > 0) {
                Object.keys(segment.children).forEach(childKey => {
                    const childSegment = segment.children[childKey];
                    segmentation.children.push({
                        name: childSegment?.name,
                        isparent: 0,
                        sort: childSegment.sort_by,
                        id: childSegment.id,
                        isDeleted: 0
                    });
                });
            }

            segmentations.push(segmentation);
        });

        return {
            user_id: apiResponse[Object.keys(apiResponse)[0]].created_by,
            segmentations: segmentations,
            seg_type: type
        };
    }

    function generateTree(items, parentId = null) {
        const tree = {};

        items
            .filter(item => item.parent_id === parentId)
            .forEach(item => {
                tree[item.id] = { ...item, children: generateTree(items, item.id) };
            });
        return tree;
    }


    const getSegDetails = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${window.location.href?.split('/').pop()}/segmentationtype`)
            setSegDetails(data?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getRegions = async () => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getregions`);
            setRegions(response?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleCkSave = async (e) => {
        try {
            var scope_analysis_details = e
            var response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${props?.go_in_id}/update`, { scope_analysis_details })
            handleSectionSave(props.id)
            updateTocIsSaved({ projectId: projectId, tocId: props.id })
            await projectDataRefetch()
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }


    useEffect(() => {
        getRegions()
        getSegDetails()
    }, [])


    useEffect(() => {
        setHeader(props?.name)
    }, [props])
    const [createCloneToc, {
        isLoading: isCreateCloneLoading,
    }] = useCreateCloneTocMutation()
    const [deleteClone, { isLoading: isCloneDeleteLoading }] = useDeleteCloneMutation();
    const [updateIsApplicable, { isLoading: isUpdateApplicableLoading }] = useUpdateIsApplicableMutation()



    const handleDeleteClone = async () => {
        try {
            deleteClone({
                projectId: projectId,
                tocId: props.id
            })
            toast.success("Clone successfully deleted")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }

    const [isApplicable, setIsApplicable] = useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable: crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1 - isApplicable)
        handleUpdateIsApplicable(1 - isApplicable)
    };
    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
            handleDeleteClone();
            // Perform the delete action here
        }
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onDelete = () => {
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
    }

    console.log(props)
    return (
        <>
            <div className="card ">
                {loader || isCreateCloneLoading || isCloneDeleteLoading ? <SSOLoader /> : null}
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            headerIsEdit && isProjectEditable ?
                                <div className='input-group input-group-sm w-50'>
                                    <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                    <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                    <button type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                                </div>
                                :
                                <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
                        }

                        <div className='d-flex align-items-center'>
                            <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                                <input
                                    className="form-check-input"
                                    checked={isApplicable}
                                    onChange={handleToggle}
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                            </div>
                            {props.isClone ? <i className="me-3 fa fa-plus-square" alt="Square Plus"></i> : null}
                            {(props.roleType !== 'analyst') || (props.hasCloned) ? <i className="fa fa-trash-o" alt="Trash" onClick={onDelete} title='Delete'></i> : null}
                            <DialogueBox
                                openDialog={openDialog}
                                setOpenDialog={setOpenDialog}
                                dialogTitle={dialogContent.title}
                                dialogContentText={dialogContent.text}
                                handleConfirmation={handleConfirmation}
                                handleClose={handleClose}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body pb-0">
                    <div className='d-flex justify-content-end mb-2'>
                        {/* <Tooltip title="Scope of analysis">
                            <FontAwesomeIcon style={{ fontSize: '20px', color: '#0d6efd' }} icon={faCircleInfo} className="" />
                        </Tooltip> */}
                        <i data-bs-toggle="modal" data-bs-target="#myModalSa" style={{ color: '#1582C5' }} className='fa fa-info-circle font16 cursor-pointer'></i>
                    </div>
                    <table className='table table-sm table-bordered sa_table'>
                        <tbody>
                            <tr>
                                <td>Base Year</td>
                                <td>Geographic Coverage</td>
                                <td>Study Period from</td>
                                <td>Study Period to</td>
                                <td>Forecast Period from</td>
                                <td>Forecast Period to</td>
                                <td>Currency</td>
                            </tr>
                            <tr>
                                <td>{ProjectData?.data?.baseyear || "-"}</td>
                                <td>{regions?.filter(ele => ele.id == ProjectData?.data?.geograpy_coverage)?.[0]?.short_name || "-"}</td>
                                <td>{ProjectData?.data?.study_from || "-"}</td>
                                <td>{ProjectData?.data?.study_to || "-"}</td>
                                <td>2020</td>
                                <td>2027</td>
                                <td>{ProjectData?.data?.currency == 1 ? "$ (USD)" : "€ (EUR)"}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='my-3'>
                        {
                            ckId ?
                                <CkEditorBase onChange={handleEditorChange} data={editorContent} editorId='scope_analysis' tocId={ckId} Limits={[300, 1500, true]} />
                                : null
                        }
                    </div>
                </div>
                <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{ backgroundColor: '#1582C5' }}>
                    <Footer />
                </div>
            </div>
            <div class="modal fade" id="myModalSa">
                <div class="modal-dialog  modal-lg">
                    <div class="modal-content">
                        <div class="modal-header py-2">
                            <h5 class="modal-title">Scope of Analysis</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div class="modal-body font14">
                            <ul>
                                <li className='mb-2'>Provide a concise market description, focusing on recent developments and other information that gives readers a high-level understanding.</li>
                                <li className='mb-2'>Define what constitutes a unit (if applicable), and how prices have been calculated.</li>
                                <li className='mb-2'>Does this market influence growth opportunities for a broader/superset market? If so, explain why.</li>
                                <li className='mb-2'>Add rows to the scope table as needed.</li>
                                <li className='mb-2'>Include product or service examples that are included in this market.</li>
                                <li className='mb-2'>Do your regional evaluations focus on specific countries? If so, what are those countries and why is the focus narrow? List inclusions and exclusions as needed.</li>
                                <li className='mb-2'>Define whether revenue is calculated at the manufacturer level or in a specific channel.</li>
                                <li className='mb-2'>The use of abbreviations should be kept to a minimum; do not create your own to save space. Any that are commonly used and accepted in your industry should be defined on these slides.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ScopeAnalysis
