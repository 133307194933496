import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useCreateCloneTocMutation, useDeleteCloneMutation, usePostTocDataMutation, useUpdateIsApplicableMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import CkEditorBase from '../../CkEditorReact/CkEditorBase';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';
function AnalysisTocChild(props) {
    const { handleSectionSave } = useContext(ProgressContext)
    const [postTocData] = usePostTocDataMutation();
    const [loader, setLoader] = useState(false);
    const [description, setDescription] = useState("");
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [ckId, setCkId] = useState(null)
    const { projectId } = useParams();
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    useEffect(() => {
        setDescription(props?.description)
        setHeader(props?.name)
        setCkId(props?.id)
    }, [props])

    const handleChange = (e) => {
        let strippedText = e.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;


        if (characterCount < 1500) {
            setDescription(e)
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                handleSave(false, false, e)
            }, 1000);

            setDebounceTimeout(timeout);
        } else {
            toast.error('Only 1500 characters allowed!')
        }

    };

    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved": 1
                }
            }).then((res) => console.log(res))
            setHeaderIsEdit(false)
            setLoader(false)
            handleSectionSave(props.id)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    const handleSave = async (headerSave = false, descriptionSave = false, data = '') => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": data != '' ? data : descriptionSave ? description : props?.description,
                    "name": headerSave ? header : props?.name,
                    "isSaved": 1
                }
            }).then((res) => console.log(res))
            if (headerSave) setHeaderIsEdit(false)
            setLoader(false)
            handleSectionSave(props.id)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    const [createCloneToc, {
        isLoading: isCreateCloneLoading,
    }] = useCreateCloneTocMutation()
    const [deleteClone, { isLoading: isCloneDeleteLoading }] = useDeleteCloneMutation();
    const [updateIsApplicable, { isLoading: isUpdateApplicableLoading }] = useUpdateIsApplicableMutation();
    const handleCreateClone = async () => {
        try {
            createCloneToc({ projectId: projectId, tocId: props.id }).then((res) => toast.success("Clone created"))

        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }

    const handleDeleteClone = async () => {
        try {
            deleteClone({
                projectId: projectId,
                tocId: props.id
            })
            toast.success("Clone successfully deleted")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }


    }
    const [isApplicable, setIsApplicable] = useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable: crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1 - isApplicable)
        handleUpdateIsApplicable(1 - isApplicable)
    };


    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
            handleDeleteClone();
            // Perform the delete action here
        }
        else if (dialogContent.action === 'clone') {
            handleCreateClone()
        }
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const onDelete = () => {
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
    }
    const onCreateClone = () => {
        setDialogContent({
            title: 'Confirm Clone creation',
            text: 'Are you sure you want to clone this section?',
            action: 'clone',
        });
        setOpenDialog(true);
    }
    return (

        <div className="card mb-3 rounded-0">

            {loader || isCreateCloneLoading || isCloneDeleteLoading || isUpdateApplicableLoading ? <SSOLoader /> : null}
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    {
                        headerIsEdit ?
                            <div className='input-group input-group-sm w-50'>
                                <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                <button type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                            </div>
                            :
                            <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
                    }

                    <div className='d-flex align-items-center'>
                        <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                            <input
                                className="form-check-input"
                                checked={isApplicable}
                                onChange={handleToggle}
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                            />
                            <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                        </div>
                        {props.isClone ? <i className="me-3 fa fa-plus-square" alt="Square Plus" onClick={onCreateClone}></i> : null}
                        {(props.roleType !== 'analyst') || (props.hasCloned) ? <i className="fa fa-trash-o" alt="Trash" onClick={onDelete} title='Delete'></i> : null}
                        <DialogueBox
                            openDialog={openDialog}
                            setOpenDialog={setOpenDialog}
                            dialogTitle={dialogContent.title}
                            dialogContentText={dialogContent.text}
                            handleConfirmation={handleConfirmation}
                            handleClose={handleClose}
                        />
                    </div>
                </div>
            </div>
            <div className="card-body">
                {
                    ckId ? <CkEditorBase onChange={(data) => handleChange(data)} data={description} tocId={ckId} editorId={`toc-${ckId}`} Limits={[300, 1500, true]} /> : null
                }

                <div className='d-flex justify-content-end'>
                    {/* <button className='btn btn-secondary rounded-0 mt-3 btn-sm' onClick={() => { handleSave(false, true) }}><i className="fa fa-save"></i> SAVE</button> */}
                </div>
            </div>
            <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{ backgroundColor: '#1582C5' }}>
                <Footer />
            </div>
        </div>

    )
}

export default AnalysisTocChild