import React, { useEffect } from 'react'
import { formatDate } from '../../Utils/CalculationUtils'
// import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';

const ProjectDetails = ({ ProjectData }) => {
  function findUserFromProjectData(user_id) {
    const userObj = ProjectData?.userdetails.find((item) => item.id === user_id);
    return userObj?.first_name + " " + userObj?.last_name;
  }

  // const {stageInfo}=useContext(ProjectStatusContext)
  return (
    <div>
      <table className="table table-bordered mt-4 mb-0">
        <tbody>
          <tr>
            <td>
              <p className="font14 fw600 mb-1">Product Code</p>
              <p className="font14 mb-0">{ProjectData?.data?.wipcode}</p>
            </td>
            <td>
              <p className="font14 fw600 mb-1">Last modified {formatDate(ProjectData?.data.updated_at)}</p>
              <p className="font14 mb-0"><img className="userImg me-1" src={require('../../images/user.png')} />{findUserFromProjectData(ProjectData?.data.updated_by)}</p>
            </td>
            <td>
              <p className="font14 fw600 mb-1">Created {formatDate(ProjectData?.data.created_at)}</p>
              <p className="font14 mb-0"><img className="userImg me-1" src={require('../../images/user.png')} />{findUserFromProjectData(ProjectData?.data.created_by)}</p>
            </td>
            {/* <td>
          <p className="font14 fw600 mb-1">Status</p>
          <p className="font14 mb-0">{stageInfo?.statusName}</p>
        </td> */}
          </tr>
          <tr>
            <td>
              <p className="font14 fw600 mb-1">Strategic Imperatives</p>
              <p className="font14 mb-0">{ProjectData?.data?.perspective}</p>
            </td>
            <td>
              <p className="font14 fw600 mb-1">Functional Title</p>
              <p className="font14 mb-0">{ProjectData?.data?.functionalTitle}</p>
            </td>
            <td>
            <p className="font14 fw600 mb-1">Sub Title</p>
            <p className="font14 mb-0">{ProjectData?.data?.subtitle}</p>
            </td>
            {/* <td>
          <p className="font14 fw600 mb-1">Status</p>
          <p className="font14 mb-0">{stageInfo?.statusName}</p>
        </td> */}
          </tr>
        </tbody>
      </table>
      
    </div>
  )
}

export default ProjectDetails