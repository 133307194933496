// CustomUploadAdapter.js
class CustomUploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve({ default: reader.result })
          }
          reader.onerror = reject
          reader.readAsDataURL(file)
        })
    )
  }

  abort() {
    // Reject the promise returned from upload() method.
    // This is optional.
  }
}

function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new CustomUploadAdapter(loader)
  }
}

export default CustomUploadAdapterPlugin
