// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    width: 100%;
}

.ck.ck-editor {
    max-width: 100%;
}

#revision-viewer-container {
    display: none;
}

.editor-container > .ck-editor {
    position: relative;
    width: 100%;
}

.editor-container .ck-editor__top .ck-toolbar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.editor-container .ck-editor__editable_inline {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.editors-holder {
    display: flex;
    flex-direction: row;
  }
  
  .editor-container {
    flex: 1 1;
/* Adjust this value to match the sidebar width */
  }
  
  .sidebar-container-main {
    position: fixed;
    top: 106px;
    right: 3px;
    width: 240px; /* Adjust the width as needed */
    height: calc(98vh - 97px);
    overflow-y: auto;
    background-color: #fff; /* Adjust background color as needed */
    /* box-shadow: 2px 0 5px rgba(0,0,0,0.1); Optional for a shadow effect */
    z-index: 1000;
  }
  
  .sidebar-container {
    padding: 10px; /* Adjust padding as needed */
  }

  `, "",{"version":3,"sources":["webpack://./src/CkEditorReact/Style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,SAAO;AACX,iDAAiD;EAC/C;;EAEA;IACE,eAAe;IACf,UAAU;IACV,UAAU;IACV,YAAY,EAAE,+BAA+B;IAC7C,yBAAyB;IACzB,gBAAgB;IAChB,sBAAsB,EAAE,sCAAsC;IAC9D,wEAAwE;IACxE,aAAa;EACf;;EAEA;IACE,aAAa,EAAE,6BAA6B;EAC9C","sourcesContent":[".editor-container {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    position: relative;\n    width: 100%;\n}\n\n.ck.ck-editor {\n    max-width: 100%;\n}\n\n#revision-viewer-container {\n    display: none;\n}\n\n.editor-container > .ck-editor {\n    position: relative;\n    width: 100%;\n}\n\n.editor-container .ck-editor__top .ck-toolbar {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}\n\n.editor-container .ck-editor__editable_inline {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n}\n\n.editors-holder {\n    display: flex;\n    flex-direction: row;\n  }\n  \n  .editor-container {\n    flex: 1;\n/* Adjust this value to match the sidebar width */\n  }\n  \n  .sidebar-container-main {\n    position: fixed;\n    top: 106px;\n    right: 3px;\n    width: 240px; /* Adjust the width as needed */\n    height: calc(98vh - 97px);\n    overflow-y: auto;\n    background-color: #fff; /* Adjust background color as needed */\n    /* box-shadow: 2px 0 5px rgba(0,0,0,0.1); Optional for a shadow effect */\n    z-index: 1000;\n  }\n  \n  .sidebar-container {\n    padding: 10px; /* Adjust padding as needed */\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
