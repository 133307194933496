// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-header {
  width: 100%;
  border-collapse: collapse;
  /* margin-bottom: 20px; */
}


.table-header td {
  border-bottom: 1px solid #dddddd;
  padding: 1rem;
  padding-left: 2rem;
  text-align: left;
  position: relative;
}

.table-headered {
  background-color: #034b8c;
  color: white;
  border-bottom: 1px solid #dddddd;
  padding-top: 6px;
  padding-bottom: 6px;

}

.project-item:nth-child(even) {
  background-color: #f2f2f2;
}



.link:hover {
  text-decoration: underline;
  cursor: pointer;

}

.LinkColumn {
  cursor: pointer;
  color: blue !important;
  text-decoration: underline;
}

.cursor-pointer{
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/MyProjects/project.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,yBAAyB;AAC3B;;;AAGA;EACE,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,gCAAgC;EAChC,gBAAgB;EAChB,mBAAmB;;AAErB;;AAEA;EACE,yBAAyB;AAC3B;;;;AAIA;EACE,0BAA0B;EAC1B,eAAe;;AAEjB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".table-header {\n  width: 100%;\n  border-collapse: collapse;\n  /* margin-bottom: 20px; */\n}\n\n\n.table-header td {\n  border-bottom: 1px solid #dddddd;\n  padding: 1rem;\n  padding-left: 2rem;\n  text-align: left;\n  position: relative;\n}\n\n.table-headered {\n  background-color: #034b8c;\n  color: white;\n  border-bottom: 1px solid #dddddd;\n  padding-top: 6px;\n  padding-bottom: 6px;\n\n}\n\n.project-item:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n\n\n.link:hover {\n  text-decoration: underline;\n  cursor: pointer;\n\n}\n\n.LinkColumn {\n  cursor: pointer;\n  color: blue !important;\n  text-decoration: underline;\n}\n\n.cursor-pointer{\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
