import React, { useState } from 'react'
import { Button, Modal, Box, Typography } from '@mui/material';
import '../CSS/TopBar.css'
import { useNavigate } from 'react-router-dom';
import frost_logo from '../../images/frost_logo.png'

function TopBar({ sendState, userData }) {

    const [isOpen, setIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const navigate = useNavigate()
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        sendState(!isOpen)
    };
    const openNotifications = () => {
        navigate('/notifications')
    }
    const handleModalClose = () => {
        setModalOpen(false)
    }
    const goHome = () => {
        window.open('https://growthuat.frost.com/', '_self');
    }
    const handleLogout = () => {
        const cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
        sessionStorage.clear()
        window.open('https://growthuat.frost.com/', '_self');

    }
    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-light custom-navbar py-0 sticky-top">
                <div className="container-fluid">
                    <div className='d-flex align-items-center'>
                        <img onClick={toggleSidebar} className='me-3' src={require('../../images/hamburger.png')} alt="logo" />
                        {frost_logo ? <img src={frost_logo} alt='null' /> : null}
                    </div>
                    <ul className="navbar-nav">
                        <li className="nav-item d-flex align-self-center">
                            <a title='https://growthuat.frost.com' className="nav-link d-flex align-self-center" href="https://growthuat.frost.com" target='_blank'>
                                <i style={{ fontSize: '22px' }} title='Home' className='fa fa-home me-2'></i>
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">
                                <img className='me-2' src={userData?.image} alt='Person Image' style={{ height: '35px', borderRadius: '20px' }} />
                                {userData?.name}
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><a className="dropdown-item"  onClick={handleLogout}>Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                style={{
                    position: 'absolute',
                    top: 70,
                    left: 950,
                }}
            >
                <Box sx={{

                    width: 300,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Modal Title
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        This is the content of the modal. You can put any component here.
                    </Typography>
                    <Button onClick={handleModalClose} sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>
        </>
    )
}

export default TopBar



