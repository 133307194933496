import React from 'react'
import './Styles/SSOLoader.css'

function SSOLoader(props) {
    return (
        <div>
            <div className="full-page-loader">
                <div className="loader"></div>
                <p>{props?.data ? `${props?.data?.current} of ${props?.data?.total} done, ` : null}Loading...</p>
            </div>
        </div>
    )
}

export default SSOLoader