import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils'
import axios from 'axios'

class TrackChangesAdapter {
  editor
  ckEditorTrackChanges
  constructor(editor, ckEditorTrackChanges) {
    this.editor = editor
    this.ckEditorTrackChanges = ckEditorTrackChanges
  }
  static get requires() {
    return ['TrackChanges', 'UsersInit']
  }

  static get pluginName() {
    return 'TrackChangesAdapter'
  }

  init() {
    const trackChangesPlugin = this.editor.plugins.get('TrackChanges')
    var ConfigData = this.editor.config
    const editorData = this.editor
    for (const suggestion of this.ckEditorTrackChanges.trackchanges_list) {
      trackChangesPlugin.addSuggestion(suggestion.content)
    }
    trackChangesPlugin.adapter = {
      getSuggestion: (suggestionId) => {},

      addSuggestion: async (suggestionData) => {
        try {
          const project_id = window.location.href.split('/').pop()
          let trackChangeObj = {}
          let finalObj = {}
          finalObj.ckeditor_id = ConfigData?._config?.id
          finalObj.suggestion_id = suggestionData.id
          trackChangeObj.id = suggestionData.id
          trackChangeObj.type = suggestionData.type
          trackChangeObj.authorId = getUserDetailsFromLocalStorage()?.user_id
          trackChangeObj.hasComments = suggestionData.hasComments
          trackChangeObj.data = suggestionData.data
          trackChangeObj.createdAt = new Date().toISOString()
          trackChangeObj.originalSuggestionId =
            suggestionData.originalSuggestionId
          trackChangeObj.attributes = suggestionData.attributes
          finalObj.content = trackChangeObj
          finalObj.user_id = getUserDetailsFromLocalStorage()?.user_id
          let response = await axios.post(
            `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/ckeditoraddtrackchanges`,
            finalObj
          )
          if (ConfigData?._config?.id == 'scope_analysis') {
            let secondaryPostData = {
              scope_analysis_details: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/update`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (ConfigData?._config?.id.split('-')[0] == 'toc') {
            let secondaryPostData = {
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/tocprojectdata/update/${project_id}/${ConfigData?._config?.tocId}/`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (
            ConfigData?._config?.id.split('-')[0] == 'siWhy' ||
            ConfigData?._config?.id.split('-')[0] == 'siPer'
          ) {
            let secondaryPostData = {
              type: 'strategic_imperative',
              column:
                ConfigData?._config?.id.split('-')[0] == 'siWhy'
                  ? 'strategic_imperative_why'
                  : 'strategic_imperative_perspective',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else {
            let secondaryPostData = {
              type: 'gou',
              column:
                ConfigData?._config?.id.split('-')[0] == 'context'
                  ? 'context'
                  : 'call_to_action',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          }
        } catch (error) {
          console.log(error)
        }
        return Promise.resolve({
          createdAt: new Date(), 
        })
      },

      updateSuggestion: async (id, suggestionData) => {
        try {
          const project_id = window.location.href.split('/').pop()
          let trackChangeObj = {}
          let finalObj = {}
          finalObj.ckeditor_id = ConfigData?._config?.id
          finalObj.suggestion_id = suggestionData.id
          trackChangeObj.id = suggestionData.id
          trackChangeObj.type = suggestionData.type
          trackChangeObj.authorId = getUserDetailsFromLocalStorage()?.user_id
          trackChangeObj.hasComments = suggestionData.hasComments
          trackChangeObj.data = suggestionData.data
          trackChangeObj.createdAt = new Date().toISOString()
          trackChangeObj.originalSuggestionId =
            suggestionData.originalSuggestionId
          trackChangeObj.attributes = suggestionData.attributes
          finalObj.content = trackChangeObj
          finalObj.user_id = getUserDetailsFromLocalStorage()?.user_id
          await new Promise((resolve) => setTimeout(resolve, 100))
          if (ConfigData?._config?.id == 'scope_analysis') {
            let secondaryPostData = {
              scope_analysis_details: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${project_id}/update`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (ConfigData?._config?.id.split('-')[0] == 'toc') {
            let secondaryPostData = {
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/tocprojectdata/update/${project_id}/${ConfigData?._config?.tocId}/`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else if (
            ConfigData?._config?.id.split('-')[0] == 'siWhy' ||
            ConfigData?._config?.id.split('-')[0] == 'siPer'
          ) {
            let secondaryPostData = {
              type: 'strategic_imperative',
              column:
                ConfigData?._config?.id.split('-')[0] == 'siWhy'
                  ? 'strategic_imperative_why'
                  : 'strategic_imperative_perspective',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          } else {
            let secondaryPostData = {
              type: 'gou',
              column:
                ConfigData?._config?.id.split('-')[0] == 'context'
                  ? 'context'
                  : 'call_to_action',
              description: editorData.getData(),
            }
            try {
              let response2 = await axios.post(
                `${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/${project_id}/ckeditorupdate/${ConfigData?._config?.tocId}`,
                secondaryPostData
              )
            } catch (error) {
              console.log(error)
            }
          }
        } catch (error) {
          console.log(error)
        }
        return Promise.resolve()
      },
    }
  }
}

export default TrackChangesAdapter
