import React, { useState, useEffect, useRef, useContext } from 'react';
import {  toast } from 'react-toastify';
import { useDeleteCloneMutation, usePostTocDataMutation, useUpdateIsApplicableMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { useParams } from 'react-router-dom';

function LegalDisclaimer(props) {   
    const {handleSectionSave} = useContext(ProgressContext) 
    const {isProjectEditable}=useContext(ProjectStatusContext)
    const [loader, setLoader] = useState(false)
    const [postTocData] = usePostTocDataMutation();
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");

    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved":1
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            handleSectionSave(props.id)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        setHeader(props?.name)
    }, [props])
    const checkEditability=()=>{
        if(!isProjectEditable){
            return
        } 
        else setHeaderIsEdit(true)
    }
    const {projectId}=useParams();
    const [deleteClone,{isLoading:isCloneDeleteLoading}]=useDeleteCloneMutation();
    const [updateIsApplicable,{isLoading:isUpdateApplicableLoading}]=useUpdateIsApplicableMutation()
    const handleDeleteClone=async()=>{
            try {
                deleteClone({
                    projectId:projectId,
                    tocId:props.id
                })
                toast.success("Clone successfully deleted")
            } catch (error) {
                console.log(error)
                toast.error("Something went wrong")
            } 
    }
    const [isApplicable,setIsApplicable]=useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable:crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }
    const handleToggle = () => {
        setIsApplicable(1-isApplicable)
        handleUpdateIsApplicable(1-isApplicable)
    };
    return (
        <>
            <div className="card mb-3 rounded-0">
                
                {loader||isCloneDeleteLoading||isUpdateApplicableLoading ? <SSOLoader /> : null}
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            headerIsEdit ?
                                <div className='input-group input-group-sm w-50'>
                                    <input disabled={!isProjectEditable} type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                    <button disabled={!isProjectEditable} type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                    <button disabled={!isProjectEditable} type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                                </div>
                                :
                                <p className="mb-0">{props?.name}<i onClick={ checkEditability } className='fa fa-edit font16 align-text-bottom ms-2'></i> </p>
                        }

                        <div className='d-flex align-items-center'>
                            <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                                <input 
                                    className="form-check-input" 
                                    checked={isApplicable} 
                                    onChange={handleToggle}
                                    type="checkbox" 
                                    role="switch" 
                                    id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                            </div>
                            {props.isClone?<i className="me-3 fa fa-plus-square" alt="Square Plus" ></i>:null}
                            <i className="fa fa-trash-o" alt="Trash" onClick={handleDeleteClone} title='Delete'></i>
                            
                        </div>
                    </div>
                </div>
               
            </div>
        </>

    )
}

export default LegalDisclaimer
