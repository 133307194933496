import React, { useEffect, useState } from 'react'

function CagrCalculation(props) {

    const [tableData, setTableData] = useState([]);
    const formatConvet = (arr) => {
        var transformedData = [{
            seriesname: arr?.[0]?.seriesname,
            data: arr.map(item => ({
                label: item.label,
                value: item.value
            }))
        }];
        setTableData(transformedData);
    }

    const formatConvertSplit = (data) => {
        let result = data?.data?.map((element) => ({ data: element?.data?.map((ele, index) => ({ "value": ele?.value, "label": data?.label[index]?.label })), seriesname: element?.seriesname }))
        setTableData(result);
    }
    const calculateCagr = (data) => {
        let study_to_value = data?.[0]?.value
        let basyear_value = data?.[data?.length - 1]?.value
        let study_to_year = data?.[0]?.label
        let basyear_year = data?.[data?.length - 1]?.label
        let difference = parseInt(basyear_year) - parseInt(study_to_year)
        return (study_to_value / basyear_value) ** 1 / difference - 1;
    }
    useEffect(() => {
        if ((props?.data)?.length != 0) {
            if (props?.isPage == "total") {
                formatConvet(props?.data)
            } else if (props?.isPage == "split") {
                formatConvertSplit(props)
            }
        }
    }, [props])

    return (
        <div>
            {tableData.length > 0 ?
                <table className='table table-bordered table-striped text-center table-excelToHtml mb-0'>
                    <thead className='thead-dark'>
                        <tr>
                            <th>Year</th>
                            {tableData?.[0]?.data.map((ele, index) => (
                                <th key={`label-${index}`}>{ele.label}</th>
                            ))}
                            {/* {
                            props?.isPage == "split" ? <th>CAGR</th> : null
                        } */}

                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData?.map((element) => (
                                <tr>
                                    <td>{element?.seriesname}</td>
                                    {element?.data?.map((ele, index) => (
                                        <td key={`value-${index}`}>{ele.value}</td>
                                    ))}
                                    {/* {
                                    props?.isPage == "split" ? <td>{calculateCagr(element?.data)}</td> : null
                                } */}

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                : null
            }

        </div>
    )
}

export default CagrCalculation
