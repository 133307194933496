import React, { useEffect, useMemo, useState } from 'react'
import CreateProject from '../CreateProject'
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useGetProjectDetailsQuery } from '../../redux/slices/ProjectPageAPISlice'
import Select from 'react-select';
import { useNavigate } from 'react-router-dom'
import SSOLoader from '../SSOLoader'

const CloneProject = ({cloneId}) => {
  const initialData=useMemo(()=>{
    return {
      sector_id: '',
      industry: '',
      title_name: '',
      wipcode: '',
      baseyear: '',
      geograpy_coverage: '',
      short_title: '',
      study_from: '',
      study_to: '',
      currency: '',
      user_id: '',
      go_in_type: '1',
      perspective: "",
      functionalTitle: "",
      subtitle: ""
    }
  },[])
  const [loader,setLoader]=useState(false)  

  const { data: projectDetailsData, isLoading, isError, isSuccess } = useGetProjectDetailsQuery({ projectId: cloneId }, {
    skip: !cloneId.length
  })

  const [cloneProjectObj,setCloneProjectObj]=useState(initialData)
  const [selectedProjectManager, setSelectedProjectManager] = useState({ value: '', label: '' })
  const [programAreaEnabled, setProgramAreaEnabled] = useState(true)
  const [requiredError, setRequiredError] = useState(false)
  const [requiredNumber, setRequiredNumber] = useState(false)
  const [requiredAlphaNumeric, setRequiredAlphaNumeric] = useState(false)
  const [practiceArea, setPracticeArea] = useState([])
  const [programArea, setProgramArea] = useState([])
  const [regions, setRegions] = useState([])
  const [pmList,setPmList]=useState([]);


  const getProgramArea = async (id) => {
    
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsector/${id}/`)
      setProgramArea(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }

const findUserFromProjectData = useMemo(() => {
  return (user_id) => {
    const userObj = projectDetailsData?.userdetails.find((item) => item.id === user_id);
    return userObj ? `${userObj.first_name} ${userObj.last_name}` : '';
  };
}, [projectDetailsData]);
  useEffect(()=>{
    if(isSuccess&&projectDetailsData?.data){
      

        setCloneProjectObj(prev => ({
          ...prev,
          title_name: projectDetailsData.data.title_name,
          short_title: projectDetailsData.data.short_title,
          geograpy_coverage: projectDetailsData.data.geograpy_coverage,
          baseyear: projectDetailsData.data.baseyear,
          industry: projectDetailsData.data.industry,
          sector_id: projectDetailsData.data.sector_id,
          study_from: projectDetailsData.data.study_from,
          study_to: projectDetailsData.data.study_to,
          currency: parseInt(projectDetailsData.data.currency),
          perspective: projectDetailsData.data.perspective,
          functionalTitle: projectDetailsData.data.functionalTitle,
          subtitle: projectDetailsData.data.subtitle,
          go_in_type: parseInt(projectDetailsData.data.go_in_type)
        }));
        getProgramArea(projectDetailsData.data.industry)
        setSelectedProjectManager({
          value: projectDetailsData?.data?.contributor_list[0]?.user_id,
          label: findUserFromProjectData(projectDetailsData?.data?.contributor_list[0]?.user_id)
        })
    }
  },[projectDetailsData,isLoading,isSuccess])


  function handleChange(e) {
    setRequiredNumber(false)
    setRequiredError(false)
    setRequiredAlphaNumeric(false)
    setEmptyWipCodeErr(false)

    if (e.target.id == "4digit") {
      if (isNaN(e.target.value)) {
        setRequiredNumber(true)
      } else {
        const { name, value } = e.target;
        setCloneProjectObj(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {

      const { name, value } = e.target;
      if (name == 'industry') {
        getProgramArea(e.target.value)
        setCloneProjectObj(prevState => ({
          ...prevState,
          [name]: value,
          sector_id: ''
        }));
        setProgramAreaEnabled(false)
      }
      setCloneProjectObj(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }

  }

  const getRegions = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getregions`)
      setRegions(data?.data?.data)
    } catch (error) {
      console.error(error)
    }
  }
  const getProjectManager = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/groupsbyuser?slug_name=growth_opportunity_analyst&email=`)
      let options = response.data?.data?.map(user => ({
        value: user.user_id,
        label: `${user.first_name} ${user.last_name}`
      }))
    
      setPmList(options)
    } catch (error) {
      console.error(error)
    }
  }
  const getPracticeArea = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getindustry`)
      setPracticeArea(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const [emptyWipCodeErr,setEmptyWipCodeErr]=useState(false);
  const navigate=useNavigate();
  const cloneProject = async () => {

    if (selectedProjectManager.value == '') {
      setRequiredError(true)
      return;
    }
    if (!isNaN(cloneProjectObj.wipcode) || !isNaN(cloneProjectObj.short_title)) {
      setRequiredAlphaNumeric(true)
      return;
    }
    if(!cloneProjectObj.wipcode){
      setEmptyWipCodeErr(true)
      return;
    }
    setLoader(true)
    try {
        const params = {
            userid: getUserDetailsFromLocalStorage()?.user_id,
        };
        const res = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${cloneId}/createversion/clone/`, 
          {...cloneProjectObj,
            user_id: getUserDetailsFromLocalStorage().user_id})
        
        navigate(`/project/${res?.data?.goinid}`)
        
          
        
        setTimeout(() => {
          window.location.reload()
        }, 0);
        toast.success('Cloned successfully')
    } catch (error) {
        console.error(error)
        toast.error('Something went wrong')
    } finally {
        setLoader(false)
        
    }
    
}

const customStyles = useMemo(()=>{
  return {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      width: 300,
      height: 20,
      boxShadow: state.isFocused ? '0 0 0 1px #0D6BF7' : null
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: 300
    }),
    option: (provided, state) => ({
      ...provided,
      width: 300,
      backgroundColor: state.isSelected ? '#0D6BF7' : state.isFocused ? '#0D6BF7' : null,
      color: state.isSelected ? 'white' : 'black'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    })
  }
},[]);
useEffect(()=>{
  getPracticeArea()
  getProjectManager()
  getRegions()
  
},[])

  return (
    <>
      {loader ? <SSOLoader /> : null}
    <div className="accordion-item custom-form">
        <h2 className="accordion-header d-none" id="heading1">
          <button className="accordion-button " type="button" data-bs-toggle="collapse" style={{ height: '50px', borderRadius: '5px' }} data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
            &nbsp;&nbsp;Create Project

          </button>
        </h2>
        <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
          <div className="accordion-body">
            <div className="row mt-2">
              <div className="col-md-4">
                <label class="form-label">Type <span className="spann">*</span></label>
                <select disabled value={cloneProjectObj.go_in_type} onChange={handleChange} name='go_in_type' class="form-select form-select-sm" aria-label="Default select example">
                  {/* <option value={"0"}>Select Project type</option> */}
                  <option value='1' selected>Growth Opportunity Industry</option>
                  {/* <option value='2'>Growth Opportunity Industry 2</option> */}
                </select>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-md-6'>
                <div className="mb-3">
                  <label className="form-label">Title<span className="spann">*</span></label>
                  <input type="text" value={cloneProjectObj.title_name} onChange={handleChange} name='title_name' placeholder="Enter Title" className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label"><i title="This Short title is used for internal purpose instead of title name " className="fa fa-info-circle"></i> Short Title <span className="spann">*</span></label>
                  <input type="text" value={cloneProjectObj.short_title} onChange={handleChange} name='short_title' placeholder="Enter Short Title" className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label">Geographic Coverage <span className="spann">*</span></label>
                  <select className="form-select form-select-sm" id="" value={cloneProjectObj.geograpy_coverage} onChange={handleChange} name='geograpy_coverage'>
                    <option value="">Please Select</option>
                    {regions.map((e) => <option key={e?.id} value={e?.id}>{e?.name}</option>)}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label">Product Code <span className="spann">*</span></label>
                  <input 
                    type="text" 
                    className="form-control form-control-sm" 
                    placeholder="Enter product Code" 
                    value={cloneProjectObj.wipcode} 
                    onChange={(e)=>{
                      setCloneProjectObj(prev => ({
                      ...prev,
                      wipcode:e.target.value
                    }))
                    setEmptyWipCodeErr(false)
                    setRequiredAlphaNumeric(false)
                  }} 
                    name='wipcode' 
                  />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label">Base Year <span className="spann">*</span></label>
                  <input className="form-control form-control-sm" id="4digit" value={cloneProjectObj.baseyear} onChange={handleChange} disabled name='baseyear' placeholder="Enter Base Year" type="text" pattern="^0[1-9]|[1-9]\d$" maxLength="4" />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label">Practice Area <span className="spann">*</span></label>
                  <select className="form-select form-select-sm" id="" value={cloneProjectObj.industry} onChange={handleChange} name='industry'>
                    <option value="">Please Select</option>
                    {practiceArea.map((e) => <option id={e?.name} key={e?.id} value={e?.id}>{e?.name}</option>)}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label" >Program Area <span className="spann">*</span></label>
                  <select className="form-select form-select-sm" disabled={programAreaEnabled} value={cloneProjectObj.sector_id} onChange={handleChange} name='sector_id'>
                    <option value="">Please Select</option>
                    {programArea.map((e) => <option value={e?.id} key={e?.id}>{e?.name}</option>)}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label" >Study Period From <span className="spann">*</span></label>
                  <input type="text" id="4digit" className="form-control form-control-sm" value={cloneProjectObj.study_from} disabled onChange={handleChange} name='study_from' placeholder="Enter Study Period From" maxLength='4' />
                </div>
              </div>
              <div className='col-md-3 '>
                <div className="mb-3">
                  <label className="form-label">Study Period To <span className="spann">*</span></label>
                  <input type="text" id="4digit" className="form-control form-control-sm" value={cloneProjectObj.study_to} disabled onChange={handleChange} name='study_to' placeholder="Enter Study Period To" maxLength='4' />
                </div>
              </div>

              <div className='col-md-6'>
                <div className="mb-3">
                  <label className="form-label">Monetary Unit <span className="spann">*</span></label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="currency" id="inlineRadio1" value='1' onChange={handleChange} checked={cloneProjectObj?.currency == '1'} />
                      <label className="form-check-label" htmlFor="inlineRadio1">$ (USD)</label>
                    </div>
                    <div className="form-check form-check-inline ms-3">
                      <input className="form-check-input" type="radio" name="currency" id="inlineRadio2" value='2' onChange={handleChange} checked={cloneProjectObj?.currency == '2'} />
                      <label className="form-check-label" htmlFor="inlineRadio2">€ (EUR)</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label" >Strategic Imperatives </label>
                  <select className="form-select form-select-sm" id="" name="perspective" onChange={handleChange} value={cloneProjectObj.perspective}>
                    <option value="" selected>Select Strategic Imperatives</option>
                    <option value="Innovative Business Models">Innovative Business Models</option>
                    <option value="Customer Value Chain Compression">Customer Value Chain Compression</option>
                    <option value="Transformative Mega Trends">Transformative Mega Trends</option>
                    <option value="Internal Challenges">Internal Challenges</option>
                    <option value="Competitive Intensity">Competitive Intensity</option>
                    <option value="Geopolitical Chaos">Geopolitical Chaos</option>
                    <option value="Disruptive Technologies">Disruptive Technologies</option>
                    <option value="Industry Convergence">Industry Convergence</option>
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label">Functional Title </label>
                  <input type="text" value={cloneProjectObj?.functionalTitle} onChange={handleChange} name='functionalTitle' placeholder="Enter Functional Title" className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-md-3'>
                <div className="mb-3">
                  <label className="form-label">Sub Title </label>
                  <input type="text" value={cloneProjectObj?.subtitle} onChange={handleChange} name='subtitle' placeholder="Enter Sub Title" className="form-control form-control-sm" />
                </div>
              </div>
              <div className='col-md-3'>
                <div class="">
                  <label class="form-label">Select Project Manager <span className="spann">*</span></label>

                  <Select
                    className="form-select-sm ps-0"
                    placeholder="Select Project Manager"
                    value={selectedProjectManager || null}
                    onChange={(e) => {
                      setRequiredError(false),
                        setSelectedProjectManager(e)
                      
                    }}
                    options={pmList}
                    styles={customStyles}
                    isSearchable
                  />
                </div>
              </div>
            </div>
            {requiredError ? <span style={{ color: 'red' }}>All fields are required</span> : null}
            {requiredNumber ? <span style={{ color: 'red' }}>Please input number</span> : null}
            {requiredAlphaNumeric ? <span style={{ color: 'red' }}>Only numbers not allowed in product code and short title</span> : null}
            {emptyWipCodeErr?<span style={{ color: 'red' }}>Project Code cannot be empty</span> : null}
            <div className="d-flex justify-content-end">
              <button type="button" onClick={cloneProject} className="btn btn-primary btn-sm me-2"><i className="fa fa-save me-1"></i>Clone</button>
            </div>
          </div>
        </div>
      </div>
    </>  
  )
}

export default CloneProject;