// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  background-color: #ECF4FB;
}
.main_container{
  display: flex;
  width: 100%;
  align-items: stretch;
}
.main-content{
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

`, "",{"version":3,"sources":["webpack://./src/layout/CSS/FrostSkeleton.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,MAAM;EACN,QAAQ;AACV","sourcesContent":["body{\r\n  background-color: #ECF4FB;\r\n}\r\n.main_container{\r\n  display: flex;\r\n  width: 100%;\r\n  align-items: stretch;\r\n}\r\n.main-content{\r\n  width: 100%;\r\n  min-height: 100vh;\r\n  transition: all 0.3s;\r\n  position: absolute;\r\n  top: 0;\r\n  right: 0;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
