import React, { useEffect, useMemo, useRef, useState } from "react"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Styles/createProject.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SSOLoader from './SSOLoader';
import Select from 'react-select';
import backArrow from '../assets/images/back arrow.svg'
import versionHistory from '../assets/images/Version history.svg'
import bulb from '../assets/images/PNG/lightbulb.png'
import { useGetProjectDetailsQuery, usePostContributorsDataMutation } from "../redux/slices/ProjectPageAPISlice";
import { getUserDetailsFromLocalStorage } from "../Utils/userUtils";

function CreateProject({ editDataSet, isVisible, onClose }) {
    console.log(editDataSet);
    const titleInputRef = useRef(null);
    const [loader, setLoader] = useState(false)
    const [editData, setEditData] = useState({});
    const [selectedProjectManager, setSelectedProjectManager] = useState({ value: '', label: '' })
    const [programAreaEnabled, setProgramAreaEnabled] = useState(true)



    const headers = {
        Authorization: `Bearer ${document?.cookie?.split(';')?.find(token => token.startsWith(' $access_token'))?.split('=')[1]}`,
    }

    const { data: projectDetailsData, isLoading, isError, isSuccess } = useGetProjectDetailsQuery({ projectId: editDataSet?.id }, {
        skip: !editDataSet?.id
    })
    const findUserFromProjectData = useMemo(() => {
        return (user_id) => {
            const userObj = projectDetailsData?.userdetails.find((item) => item.id === user_id);
            return userObj ? `${userObj.first_name} ${userObj.last_name}` : '';
        };
    }, [projectDetailsData]);

    useEffect(() => {
        if (editDataSet && isSuccess) {
            console.log(projectDetailsData);
            setSelectedProjectManager({
                value: projectDetailsData?.data?.contributor_list[0]?.user_id,
                label: findUserFromProjectData(projectDetailsData?.data?.contributor_list[0]?.user_id)
            })
        }
    }, [isLoading, isError, isSuccess])

    function CheckAllKeyValuePairs(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if ((typeof value === 'string' && value.trim().length === 0)) {
                    console.log(key);

                    return false;
                }
            }
        }
        return true;
    }
    let initialData = {
        sector_id: '',
        industry: '',
        title_name: '',
        wipcode: '',
        baseyear: '',
        geograpy_coverage: '',
        short_title: '',
        study_from: '',
        study_to: '',
        currency: '',
        user_id: getUserDetailsFromLocalStorage()?.user_id ? getUserDetailsFromLocalStorage()?.user_id : '',
        go_in_type: '1',
        perspective: "",
        functionalTitle: "",
        subtitle: ""
    }

    const [createProject, setCreateProject] = useState(initialData)
    const [requiredError, setRequiredError] = useState(false)
    const [requiredNumber, setRequiredNumber] = useState(false)
    const [requiredAlphaNumeric, setRequiredAlphaNumeric] = useState(false)
    const [practiceArea, setPracticeArea] = useState([])
    const [programArea, setProgramArea] = useState([])
    const [regions, setRegions] = useState([])

    const navigate = useNavigate();

    const getProgramArea = async (id, name) => {
        let params = {
            industry: name,
            parent_id: id
        }
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsector/${id}/`)
            setProgramArea(data?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }

    function handleChange(e) {
        setRequiredNumber(false)
        setRequiredError(false)
        setRequiredAlphaNumeric(false)

        if (e.target.id == "4digit") {
            if (isNaN(e.target.value)) {
                setRequiredNumber(true)
            } else {
                const { name, value } = e.target;
                setCreateProject(prevState => ({
                    ...prevState,
                    [name]: value,
                }));
            }
        } else {

            const { name, value } = e.target;
            if (name == 'industry') {
                getProgramArea(e.target.value, e.target.options[e.target.selectedIndex].innerHTML)
                setCreateProject(prevState => ({
                    ...prevState,
                    [name]: value,
                    sector_id: ''
                }));
                setProgramAreaEnabled(false)
            }
            setCreateProject(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }

    }
    const [postContributorsData] = usePostContributorsDataMutation();

    async function CreateProject() {
        createProject.currency = parseInt(createProject.currency)
        createProject.go_in_type = parseInt(createProject.go_in_type)
        // console.log(isNaN(createProject.wipcode));

        if (selectedProjectManager.value == '') {
            setRequiredError(true)
            return;
        }
        if (!isNaN(createProject.wipcode) || !isNaN(createProject.short_title)) {
            setRequiredAlphaNumeric(true)
            return;
        }

        try {
            setLoader(true)
            setRequiredError(false)
            let checkValues = { ...createProject }
            delete checkValues.perspective;
            delete checkValues.subtitle;
            delete checkValues.functionalTitle;
            if (CheckAllKeyValuePairs(checkValues)) {
                console.log(createProject)
                if (Object.keys(editData).length == 0) {
                    var response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/createproject`, { ...createProject, user_id: getUserDetailsFromLocalStorage().user_id }, { headers })
                } else {
                    let sendData = { ...createProject }
                    delete sendData?.updated_by
                    var response = await axios.post(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${editData?.id}`, { ...sendData, user_id: getUserDetailsFromLocalStorage().user_id })
                }
                setCreateProject(initialData)
                // editDataSet.reload()

                if (Object.keys(editData).length == 0) {
                    await postContributorsData({
                        projectId: response?.data?.data?.id,
                        body: {
                            user_id: selectedProjectManager?.value,
                            created_by: getUserDetailsFromLocalStorage().user_id,
                            role_type: "Project Manager",
                        },
                    });

                    navigate(`/project/${response?.data?.data?.id}`);
                    setLoader(false)
                    window.location.reload();
                    return toast.success('Project Created Successfully')
                } else {

                    await postContributorsData({
                        projectId: editDataSet?.id,
                        body: {
                            user_id: selectedProjectManager?.value,
                            created_by: getUserDetailsFromLocalStorage().user_id,
                            role_type: "Project Manager"
                        }
                    })
                    window.location.reload()
                    setLoader(false)
                    return toast.success('Project Updated Successfully')
                }
            } else {
                setRequiredError(true)
                setLoader(false)
                return toast.error("Something went wrong")

            }
        } catch (error) {
            console.log(error)
            setLoader(false)
            return toast.error(error?.response?.data?.message || 'Something went wrong!Please try again later')

        }
    }
    const getPracticeArea = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getindustry`)
            setPracticeArea(data?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }
    const [pmList, setPmList] = useState([]);
    const getProjectManager = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/groupsbyuser?slug_name=growth_opportunity_analyst&email=`)
            let options = response.data?.data?.map(user => ({
                value: user.user_id,
                label: `${user.first_name} ${user.last_name}`
            }))
            // console.log(options);
            setPmList(options)
        } catch (error) {
            console.error(error)
        }
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '8px',
            borderColor: '#ddd',
            boxShadow: 'none',
            '&:hover': { borderColor: '#aaa' },
            minHeight: '40px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '12px', // Increase the font size of options
            backgroundColor: state.isSelected ? 'white' : state.isFocused ? '#f0f0f0' : 'white',
            color: 'black',
            '&:hover': {
                backgroundColor: '#f0f0f0',
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f0f0f0',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontSize: '1.6rem', // Increase font size of selected tags (chips)
            color: 'black',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'black',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#ddd',
                color: 'black',
            },
        }),
    };
    const getRegions = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getregions`)
            setRegions(data?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (practiceArea.length == 0) {
            getPracticeArea()
        }
    }, [practiceArea])
    useEffect(() => {
        if (titleInputRef.current) {
            titleInputRef.current.focus();
            console.log("focus");
        }
        getRegions()
        getProjectManager()
    }, [])

   


    useEffect(() => {
        if (editDataSet) {
            setEditData(editDataSet)
            let tempdata = practiceArea?.filter(ele => ele.id == editDataSet?.industry)
            let id = tempdata?.[0]?.id;
            let name = tempdata?.[0]?.name;
            if (id) getProgramArea(id, name)
            console.log(editDataSet?.editData);
            let dataS = editDataSet
            delete dataS?.deleted_by;
            delete dataS?.return_status;
            setCreateProject(dataS)
            console.log(dataS);

        }
    }, [editDataSet, practiceArea])


    const [showModal, setShowModal] = useState(isVisible);
    useEffect(() => {
        if (isVisible) {
            setShowModal(true);
        }
    }, [isVisible]);

    useEffect(() => {
        if (titleInputRef.current) {
            titleInputRef.current.focus();
            console.log("focus");
        }
    }, [showModal])
    const handleClose = () => {
        setShowModal(false);
        onClose();
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            handleClose();
        }
    };



    return showModal ? (
        <div className={`modal-overlay ${isVisible ? 'visible' : ''}`} onClick={handleOutsideClick}>
            <div className={`modal-content ${isVisible ? 'slide-up' : 'slide-down'}`}>
                <div style={{ backgroundColor: 'white' }} className="modal-body">
                    <div class="d-flex flex-column dashboardMain">
                        <div class="w-100 h-100 flex-grow-1 overflow-auto">
                            <div class="w-100 h-100 d-flex flex-column">
                                <div class="w-100 h-auto gp-page-header sticky-top bg-white">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="w-50">
                                            <div class="w-100 d-flex justify-content-start align-items-end gap-5">
                                                <div class="d-flex justify-content-start align-items-center gp-pageheader-title">
                                                    <button type="button" class="gp-pageheader-back" onClick={handleClose}>
                                                        <img src={backArrow} alt="back arrow" />
                                                    </button>
                                                    <h1>{editDataSet?.title_name ? 'Edit' : 'Create'} Project</h1>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="w-50">
                                            <div class="w-100 d-flex justify-content-end align-items-center">

                                                <button type="button">
                                                    <img src={versionHistory} alt="version history" />
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div class="w-100 flex-grow-1">
                                    <div class="w-100 h-100 d-flex flex-column gp-main-area-space">
                                        <div class="w-100 flex-grow-1">
                                            <div class="d-flex h-100 justify-content-between align-items-start gap-5">
                                                <div class="gp-projectform-main ">
                                                    <div class="row gp-createproject-type">
                                                        <div class="col-6">
                                                            <div class="w-100">
                                                                <label class="gp-field-label">Type
                                                                    <span>*</span></label>
                                                                <select name='go_in_type' value={createProject.go_in_type} onChange={handleChange}
                                                                    class="w-100 gp-select-input gp-select">
                                                                    <option value='1' selected>Growth Opportunity Industry</option>
                                                                    <option value='2'>GO-3-WEEK-MAX</option>
                                                                <option value='3'>GO-Outlook</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row ">
                                                        <div class="col-6">
                                                            <div class="w-100">
                                                                <label for="projectType" class="gp-field-label" title="Enter the approved title as created in Production Plan">Title
                                                                    <span>*</span></label>
                                                                <input id="projectType"
                                                                    ref={titleInputRef} value={createProject.title_name} onChange={handleChange} name='title_name' placeholder="(Sample - Growth Opportunities in Battery Sector)"
                                                                    class="w-100 gp-input-frost" />
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label for="projectType" title="Enter Short Title - (Sample - Goibs-12)" class="gp-field-label">Short Title
                                                                            <span>*</span></label>
                                                                        <input id="" type="text" value={createProject.short_title} onChange={handleChange} name='short_title' placeholder="(Sample - Goibs)"
                                                                            class="w-100 gp-input-frost" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label for="projectType" class="gp-field-label">Geographic
                                                                            Coverage <span>*</span></label>
                                                                        <select id="projectType" value={createProject.geograpy_coverage} onChange={handleChange} name='geograpy_coverage'
                                                                            class="w-100 gp-select-input">
                                                                            <option value="">Please Select</option>
                                                                            {regions.map((e) => <option key={e?.id} value={e?.id}>{e?.name}</option>)}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label title="Enter product Code - (Sample - TRS5-543)" class="gp-field-label">Product Code
                                                                            <span>*</span></label>
                                                                        <input type="text" disabled={Object.keys(editData).length !== 0 ?
                                                                            (editData?.updated_by == null && editData?.hasCloned == 1) ? false
                                                                                : true
                                                                            : false} className="gp-input-frost" placeholder="(Sample - PFJ8-01-00-00)" value={createProject.wipcode} onChange={handleChange} name='wipcode' />
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label title="Enter Base Year - (Sample - 2024)" class="gp-field-label">Base
                                                                            Year<span>*</span></label>
                                                                        <input className="gp-input-frost" id="4digit" value={createProject.baseyear} onChange={handleChange} disabled={Object.keys(editData).length !== 0} name='baseyear' placeholder="(Sample - 2024)" type="text" pattern="^0[1-9]|[1-9]\d$" maxLength="4" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label for="projectType" class="gp-field-label">Practice Area
                                                                            <span>*</span></label>
                                                                        <select value={createProject.industry} onChange={handleChange} name='industry'
                                                                            class="w-100 gp-select-input">
                                                                            <option value="">Please Select</option>
                                                                            {practiceArea.map((e) => <option id={e?.name} key={e?.id} value={e?.id}>{e?.name}</option>)}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label for="projectType" class="gp-field-label">Program Area
                                                                            <span>*</span></label>
                                                                        <select disabled={programAreaEnabled} value={createProject.sector_id} onChange={handleChange} name='sector_id'
                                                                            class="w-100 gp-select-input">
                                                                            <option value="">Please Select</option>
                                                                            {programArea.map((e) => <option value={e?.id} key={e?.id}>{e?.name}</option>)}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label for="projectType" class="gp-field-label" title="Enter Study Period From - (Sample - 2020)">Study Period
                                                                            From <span>*</span></label>
                                                                        <input type="text" id="4digit" className="gp-input-frost" value={createProject.study_from} disabled={Object.keys(editData).length !== 0} onChange={handleChange} name='study_from' placeholder="(Sample - 2020)" maxLength='4' />
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label for="projectType" class="gp-field-label" title="Enter Study Period To - (Sample - 2027)">Study Period To
                                                                            <span>*</span></label>
                                                                        <input type="text" id="4digit" className="gp-input-frost" value={createProject.study_to} disabled={Object.keys(editData).length !== 0} onChange={handleChange} name='study_to' placeholder="(Sample - 2027)" maxLength='4' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="w-100">
                                                                        <label for="projectType" class="gp-field-label">Monetary Unit
                                                                            <span>*</span></label>
                                                                        <div
                                                                            class="w-100 d-flex justify-content-start align-items-center gap-5">
                                                                            <div class="w-auto d-flex align-items-center">
                                                                                <input type="radio" name="currency" id="inlineRadio1" value='1' onChange={handleChange} checked={createProject?.currency == '1'}
                                                                                    style={{ height: '20px', width: '20px', verticalAlign: 'middle' }}
                                                                                    class="gp-radio-input" />
                                                                                <label htmlFor="inlineRadio1"
                                                                                    class="mb-0 lh-1 gp-radio-input-label">$
                                                                                    (USD)</label>
                                                                            </div>
                                                                            <div class="w-auto d-flex align-items-center">
                                                                                <input type="radio" name="currency" id="inlineRadio2" value='2' onChange={handleChange} checked={createProject?.currency == '2'}
                                                                                    style={{ height: '20px', width: '20px', verticalAlign: 'middle' }}
                                                                                    class="gp-radio-input" />
                                                                                <label htmlFor="inlineRadio2"
                                                                                    class="mb-0 lh-1 gp-radio-input-label">€
                                                                                    (EUR)</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div class="row mt-5">
                                                        <div class="col-6">
                                                            <div class="w-100">
                                                                <label for="projectType" class="gp-field-label">Strategic
                                                                    Imperatives</label>
                                                                <select name="perspective" onChange={handleChange} value={createProject?.perspective}
                                                                    class="w-100 gp-select-input">
                                                                    <option value="" selected>Select Strategic Imperatives</option>
                                                                    <option value="Innovative Business Models">Innovative Business Models</option>
                                                                    <option value="Customer Value Chain Compression">Customer Value Chain Compression</option>
                                                                    <option value="Transformative Mega Trends">Transformative Mega Trends</option>
                                                                    <option value="Internal Challenges">Internal Challenges</option>
                                                                    <option value="Competitive Intensity">Competitive Intensity</option>
                                                                    <option value="Geopolitical Chaos">Geopolitical Chaos</option>
                                                                    <option value="Disruptive Technologies">Disruptive Technologies</option>
                                                                    <option value="Industry Convergence">Industry Convergence</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label title="Enter Functional Title" for="projectType" class="gp-field-label">Functional
                                                                            Title</label>
                                                                        <input type="text" value={createProject?.functionalTitle} onChange={handleChange} name='functionalTitle' placeholder="Enter Functional Title" className="gp-input-frost" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="w-100">
                                                                        <label title="Enter Sub Title" class="gp-field-label">Sub Title
                                                                        </label>
                                                                        <input type="text" value={createProject?.subtitle} onChange={handleChange} name='subtitle' placeholder="Enter Sub Title" className="gp-input-frost" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="w-100" style={{ fontSize: '14px' }}>
                                                                <label for="projectType" class="gp-field-label">Select Project Manager
                                                                    <span>*</span></label>
                                                                <Select
                                                                    classNamePrefix="gp-input-frost"
                                                                    placeholder="Select Project Manager"
                                                                    value={selectedProjectManager || null}
                                                                    onChange={(e) => {
                                                                        setRequiredError(false),
                                                                            setSelectedProjectManager(e)
                                                                        console.log(e);
                                                                    }}
                                                                    options={pmList}
                                                                    styles={customStyles}
                                                                    isSearchable
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {requiredError ? <span className="row" style={{ color: 'red', fontSize: '14px' }}>All fields are required</span> : null}
                                                    {requiredNumber ? <span className="row" style={{ color: 'red', fontSize: '14px' }}>Please input number</span> : null}
                                                    {requiredAlphaNumeric ? <span className="row" style={{ color: 'red', fontSize: '14px' }}>Only numbers not allowed in product code and short title</span> : null}
                                                </div>
                                                <div class="gp-projectinstruction-main h-100">
                                                    <div class="w-100 h-100 gp-project-instruction">
                                                        <div class="d-flex justify-content-start align-items-center gap-2 mb-3">
                                                            <div class="gp-instruction-icon">
                                                                <img src={bulb} alt="instruction icon" />
                                                            </div>
                                                            <p class="gp-instruction-text mb-0">Instructions</p>
                                                        </div>
                                                        <div class="w-100">
                                                            <ul class="gp-instruction-content">
                                                                <li>For the Title, enter the approved title as created in Production
                                                                    Plan.</li>
                                                                <li>The short title needs to be a combination of alphabets and/or
                                                                    numerals.</li>
                                                                <li>The Product Code(WIP Code) also cannot be just numbers.</li>
                                                                <li>The study period should span the tenure of the study for which the
                                                                    project is being created.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="gp-project-form-action">
                                            <div class="w-100 d-flex justify-content-start align-items-center gap-4">
                                                <button type="button" class="gp-modal-btn" onClick={handleClose}>cancel</button>
                                                <button type="button" class="gp-modal-btn gp-modal-btn-fill" onClick={CreateProject}>save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default CreateProject
