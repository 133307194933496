
import appData from '../../AppStaticData/EditorData'
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';


const { revisionHistoryData } = appData

class UsersInit {

    editor;
    userData;
    constructor(editor, userData) {
        this.editor = editor;
        this.userData = userData?.userdetails
    }

    static get pluginName() {
        return 'UsersInit';
    }

    static get requires() {
        return ['Users'];
    }

    init() {
        const users = this.editor.plugins.get('Users');
        for (const user of this.userData) {
            users.addUser({
                id: user?.user_id,
                name: user?.first_name +' '+user?.last_name
            });
        }
      

        users.defineMe(getUserDetailsFromLocalStorage().user_id);
    }
}



export default UsersInit;

