import React,{useContext} from 'react'
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider'

const ProjectStatusBar = () => {
    const {projectStages,isActiveStage}=useContext(ProjectStatusContext)
  return (
    <div id="wizardRow">
        <div className="text-center">
            <div className="wizard-form">
                <h6 style={{color:'#1582C5'}}>Project Stage</h6>
            <ul id="progressBar" className="progressbar px-0 mb-0">
                {projectStages.map((item,i)=>{
                    return <li key={i} id="progressList-2" className={`d-inline-block fw-bold position-relative text-center float-left progressbar-list ${isActiveStage===item.value?`active`:''}`}>
                    <span className={item.value===isActiveStage?"active":""}>{item.statusName}</span>
                    </li>
                })}
                
            </ul>
            </div>
        </div>
    </div>
  )
}

export default ProjectStatusBar