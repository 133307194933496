// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-excelToHtml thead th {
    background-color: #172E55;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }

.table-excelToHtml tbody td{
    font-size: 12px;
    font-weight: 600;
  }
  .table-excelToHtml tbody td input.form-control{
    border: 1px solid #5d8ee1;
  }
  .table-excelToHtml tbody td input.input-cus{
    font-size: 12px;
    font-weight: 600;
    border: var(--bs-border-width) solid var(--bs-border-color);
  }
`, "",{"version":3,"sources":["webpack://./src/layout/CSS/TotalMarketAnalysis.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,gBAAgB;EAClB;;AAEF;IACI,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,2DAA2D;EAC7D","sourcesContent":[".table-excelToHtml thead th {\n    background-color: #172E55;\n    color: #fff;\n    font-size: 14px;\n    font-weight: 600;\n  }\n\n.table-excelToHtml tbody td{\n    font-size: 12px;\n    font-weight: 600;\n  }\n  .table-excelToHtml tbody td input.form-control{\n    border: 1px solid #5d8ee1;\n  }\n  .table-excelToHtml tbody td input.input-cus{\n    font-size: 12px;\n    font-weight: 600;\n    border: var(--bs-border-width) solid var(--bs-border-color);\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
