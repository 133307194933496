import React, { useEffect, useState } from 'react'

function DisplayDandR(props) {
    const [unitDrivers, setUnitDrivers] = useState({});
    const [years, setYears] = useState([]);
    const [page,setPage]=useState('');
    useEffect(() => {
        setUnitDrivers(props?.unitDrivers)
        setYears(props?.years)
        setPage(props?.type)
    }, [props])

    return (
        <div className='card-body'>
            {
                Object.keys(unitDrivers).length !== 0 ? (
                    <table className="table table-bordered table-striped text-center table-excelToHtml">
                        <thead className="thead-dark">
                            <tr>
                                <th>Rank</th>
                                <th>{page=="drivers"?"Drivers":"Restraint"}</th>
                                {years.map((year) => (
                                    <th key={year}>{year}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(unitDrivers).map(([driver, impacts], index) => (
                                <tr key={driver}>
                                    <td>{index + 1}</td>
                                    <td>{driver}</td>
                                    {years.map((year) => (
                                        <td key={year}>
                                            {impacts[year]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
                    :
                    <div className='text-center'>No Data Found</div>
            }
        </div>
    )
}

export default DisplayDandR