import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useCreateCloneTocMutation, useDeleteCloneMutation, usePostTocDataMutation, useUpdateIsApplicableMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader';
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import CagrCalculation from './Components/CagrCalculation';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);


function ChartsList(props) {
    const { projectId } = useParams();
    const { handleSectionSave } = useContext(ProgressContext)
    const [loader, setLoader] = useState(false)
    const [postTocData] = usePostTocDataMutation();
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [totaldata, setTotaldata] = useState([]);
    const [splitdata, setSplitdata] = useState([]);
    const [splitdatacat, setSplitdatacat] = useState();
    const [chart1Heading, setChart1Heading] = useState('');
    const [isSplitChart, setIsSplitChart] = useState(['dfdfds']);
    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved": 1
                }
            }).then((res) => console.log(res))
            setHeaderIsEdit(false)
            setLoader(false)
            handleSectionSave(props.id)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log("There was an error!", error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    function transformData(response) {
        const { total_data, split_data, segdetails, formula_type, dr_type } = response;

        const transformedData = {
            total_data: {},
            split_data: {},
            segdetails,
            formula_type,
            dr_type
        };

        total_data.forEach((yearData, index) => {
            const year = Object.keys(yearData)[0];
            if (index === 0) {
                transformedData.total_data[props?.seg_id] = {};
            }
            transformedData.total_data[props?.seg_id][year] = yearData[year];
        });

        for (const key in split_data) {
            transformedData.split_data[key] = {};
            split_data[key].forEach(yearData => {
                const year = Object.keys(yearData)[0];
                transformedData.split_data[key][year] = yearData[year];
            });
        }

        return transformedData;
    }


    const getChatData = async (seg_id) => {
        try {
            let params = {
                seg_id: seg_id,
                user_id: getUserDetailsFromLocalStorage()?.user_id
            }
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/project/${props?.go_in_id}/getsplitmodel/`, { params })
            if (res?.data?.dr_type == 'other') {
                let transformedData = transformData(res?.data)
                let totalDataResult = transformedData?.total_data;
                let splitDataResult = transformedData?.split_data;
                let segDetailsResult = transformedData?.segdetails;
                let type = res.data?.formula_type
                setIsSplitChart(Object.keys(splitDataResult)?.filter((ele) => segDetailsResult[ele]?.no_data == 1))
                var totalDataSet = [];
                var splitCat = [];
                var splitDataSet = [];

                let totalDataKeys = Object.keys(totalDataResult)
                let splitDataKeys = Object.keys(splitDataResult)[0]
                if (Object.keys(totalDataResult).length) {
                    totalDataSet = Object.keys(totalDataResult[totalDataKeys?.[0]])?.map((ele) => ({
                        seriesname: segDetailsResult[totalDataKeys[0]]?.name,
                        label: ele,
                        value: totalDataResult[totalDataKeys[0]][ele][type] == '' ? 0 : totalDataResult[totalDataKeys[0]][ele][type]
                    }));
                    setTotaldata(totalDataSet)
                    setChart1Heading(segDetailsResult[totalDataKeys[0]]?.name)
                }

                if (Object.keys(splitDataResult).length) {
                    const firstObject = splitDataResult[splitDataKeys];
                    splitCat = Object.entries(firstObject).map(([key]) => {
                        return {
                            label: key
                        }
                    })

                    setSplitdatacat(splitCat)
                    splitDataSet = Object.entries(splitDataResult).map(([key, value]) => {
                        return {
                            seriesname: segDetailsResult[key]?.name,
                            data: Object.entries(value).map(([key1, value1]) => (
                                { value: value1.Revenues }
                            ))
                        };
                    });
                    setSplitdata(splitDataSet)
                }

            } else {
                let totalDataResult = res.data?.total_data;
                let splitDataResult = res.data?.split_data;
                let segDetailsResult = res.data?.segdetails;
                setIsSplitChart(Object.keys(splitDataResult)?.filter((ele) => segDetailsResult[ele]?.no_data == 1))
                var totalDataSet = [];
                var splitCat = [];
                var splitDataSet = [];

                let totalDataKeys = Object.keys(totalDataResult)
                let splitDataKeys = Object.keys(splitDataResult)[0]
                if (Object.keys(totalDataResult).length) {
                    totalDataSet = Object.keys(totalDataResult[totalDataKeys?.[0]])?.map((ele) => ({
                        seriesname: segDetailsResult[totalDataKeys[0]]?.name,
                        label: ele, value: totalDataResult[totalDataKeys?.[0]]?.[ele]?.revenue
                    }));
                    setTotaldata(totalDataSet)
                    setChart1Heading(segDetailsResult[totalDataKeys[0]]?.name)
                }

                if (Object.keys(splitDataResult).length) {
                    const firstObject = splitDataResult[splitDataKeys];
                    splitCat = Object.entries(firstObject).map(([key]) => {
                        return {
                            label: key
                        }
                    })

                    setSplitdatacat(splitCat)
                    splitDataSet = Object.entries(splitDataResult).map(([key, value]) => {
                        return {
                            seriesname: segDetailsResult[key]?.name,
                            data: Object.entries(value).map(([key1, value1]) => (
                                { value: value1.revenue }
                            ))
                        };
                    });
                    setSplitdata(splitDataSet)
                }
            }



        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setHeader(props?.name)
        if (props?.seg_id) {
            getChatData(props?.seg_id)
        }
    }, [props])

    const chartConfigs1 = {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: {
            chart: {
                caption: `Revenue Forecast, ${chart1Heading || ""} , ${splitdatacat?.[0]?.label || ""} - ${splitdatacat?.[splitdatacat.length - 1]?.label || ""}`,
                xAxisName: "Year",
                yAxisName: "Revenue ($ MILLION)",
                theme: "fusion",
                "paletteColors": "#042743"
            },
            data: totaldata

        }
    };

    const chartConfigs2 = {
        type: 'stackedcolumn3d',
        width: '100%',
        height: '400',
        dataFormat: 'json',
        dataSource: {
            "chart": {
                "caption": `Revenue Forecast by Product (${splitdata?.map((ele) => { return (ele?.seriesname) })}) , ${splitdatacat?.[0]?.label || ""} - ${splitdatacat?.[splitdatacat.length - 1]?.label || ""}`,
                "subCaption": "For current year",
                "xAxisname": "Quarter",
                "yAxisName": "Revenues (In USD)",
                "showValues": "1",
                "theme": "fusion",
                "paletteColors": "#042743,#17436A,#194582,#0D4D8A,#1582C3,#0081C2,#0994D5,#00B1EF,#A3BDE0,#BBDAEE"
            },
            "categories": [{
                "category": splitdatacat
            }],
            "dataset": splitdata
        }
    };

    const [createCloneToc, {
        isLoading: isCreateCloneLoading,
    }] = useCreateCloneTocMutation()
    const [deleteClone, { isLoading: isCloneDeleteLoading }] = useDeleteCloneMutation();
    const [updateIsApplicable, { isLoading: isUpdateApplicableLoading }] = useUpdateIsApplicableMutation();
    const handleCreateClone = async () => {
        try {
            createCloneToc({ projectId: projectId, tocId: props.id }).then((res) => toast.success("Clone created"))

        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }
    const [isApplicable, setIsApplicable] = useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable: crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1 - isApplicable)
        handleUpdateIsApplicable(1-isApplicable)
    };

    const handleDeleteClone = async () => {
        try {
            deleteClone({
                projectId: projectId,
                tocId: props.id
            })
            toast.success("Clone successfully deleted")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }


    }

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
          handleDeleteClone();
          // Perform the delete action here
        }
        else if(dialogContent.action === 'clone'){
            handleCreateClone()
        }
        setOpenDialog(false);
      };

      const handleClose = () => {
        setOpenDialog(false);
      };

      const onDelete=()=>{
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
      }
      const onCreateClone=()=>{
        setDialogContent({
            title: 'Confirm Clone creation',
            text: 'Are you sure you want to clone this section?',
            action: 'clone',
        });
        setOpenDialog(true);
      }
    
    return (
        <>
            <div className="card ">
                {loader || isCloneDeleteLoading || isCreateCloneLoading || isUpdateApplicableLoading ?
                    <SSOLoader /> : null}
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            headerIsEdit ?
                                <div className='input-group input-group-sm w-50'>
                                    <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                    <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                    <button type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                                </div>
                                :
                                <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
                        }

                        <div className='d-flex align-items-center'>
                            <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                                <input
                                    className="form-check-input"
                                    checked={isApplicable}
                                    onChange={handleToggle}
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                            </div>
                            {props.isClone ? <i className="me-3 fa fa-plus-square" alt="Square Plus" onClick={onCreateClone}></i> : null}
                            {(props.roleType!=='analyst')||(props.hasCloned)?<i className="fa fa-trash-o" alt="Trash" onClick={onDelete} title='Delete'></i>:null}
                            <DialogueBox
                                openDialog={openDialog} 
                                setOpenDialog={setOpenDialog}
                                dialogTitle={dialogContent.title}
                                dialogContentText={dialogContent.text} 
                                handleConfirmation={handleConfirmation}
                                handleClose={handleClose}
                            />
                        </div>
                    </div>
                </div>

                <div className='card-body'>
                    <div className='p-1 border mb-5'>
                        {
                            totaldata?.length != 0 ?

                                <>
                                    <ReactFC className="mb-3" {...chartConfigs1} />
                                    <CagrCalculation data={totaldata} isPage={"total"} />
                                </>
                                : <div className='my-2 text-center'>No Data Found</div>
                        }
                    </div>
                    <div className='p-1 border'>
                        {
                            isSplitChart?.length == 0 ?
                                <>
                                    < ReactFC {...chartConfigs2} />
                                    <CagrCalculation data={splitdata} label={splitdatacat} isPage={"split"} />
                                </>
                                : <div className='my-2 text-center'>No Data Found</div>
                        }
                    </div>

                </div>
                <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{ backgroundColor: '#1582C5' }}>
                    <Footer />
                </div>
            </div>
        </>

    )
}

export default ChartsList
