import Box from '@mui/material/Box';
import { useDeleteSegmentationTypeMutation, 
    useGetSegmentationTypeQuery,useEditSegmentationTypeMutation } from '../../redux/slices/ProjectPageAPISlice';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function BasicSelect() {
    const [segmentationList,setOpenSegmentationList]=useState([]);
    const {projectId}=useParams();
    const {data,isLoading,isSuccess}=useGetSegmentationTypeQuery({projectId:projectId})
    
  useEffect(()=>{
    if(isSuccess){
        setOpenSegmentationList(data?.data?.map((item)=>{
        return {id:item.id,name:item.name}
      }))
    }
  },[isLoading,data])

  return (
    <Box sx={{ minWidth: 500 }}>
      
        <div className=''>
                <h6 className=''>Segmentations</h6>
                {segmentationList?.map((e, index) => {
                    return <SegmentationItems key={index} item={e}/>
                })}
        </div>
            
    </Box>
  );
}


function SegmentationItems({item}){
    const {projectId}=useParams();
    const [deleteSegmentationType]=useDeleteSegmentationTypeMutation();
    const [editSegmentationType]=useEditSegmentationTypeMutation();
    const [segmentName,setSegmentName]=useState(item.name);
    useEffect(()=>{
        setSegmentName(item.name)
    },[item])
    const handleDeleteSegmentType=async(id)=>{
        try {
            await deleteSegmentationType({
                projectId:projectId,
                body:{
                    seg_id:id,
                    userid:getUserDetailsFromLocalStorage().user_id
                }
            })
        } catch (error) {
            console.log(error)
        }
      }
    
      const handleEditSegmentType=async(id)=>{
        if(segmentName.length===0){
            toast.error("Segment cannot be empty")
            return
          }
        if(segmentName.length<3){
            toast.error("Minimum length of segment should be 3 ")
            return
        }
        try {
            await editSegmentationType({
                projectId:projectId,
                body:{
                    name:segmentName, 
                    userid:getUserDetailsFromLocalStorage().user_id, 
                    seg_id:id
                }
            })
        } catch (error) {
            console.log(error)
        }
      }
    return <div className='input-group input-group-sm mb-3'>
    <input
        type="text"
        className="form-control rounded-0"
        name='name'
        value={segmentName}
        onChange={(e)=>setSegmentName(e.target.value)}
    />
    <button className='btn btn-primary mx-2 rounded-0' onClick={()=>handleEditSegmentType(item.id)}><i className="fa fa-edit"></i> Edit</button>
    <button className='btn btn-danger rounded-0' onClick={()=>handleDeleteSegmentType(item.id)}><i className="fa fa-trash"></i> Delete</button>
    
</div>
}
