// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_div_topbar {
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 60px;
}

.toggle_bars,
.bells {
  height: 50%;
  padding-right: 50px;
  padding-top: 14px;
  color: #fff;
}

.logouts,
.bells {
  margin-left: 64%;
}

.navbar-toggler-icon {
  width: 2.5em;
  height: 2.5em;
}

.custom-navbar {
  box-shadow: 0 2px #118cce0f;
  background-color: #172E55;
  z-index: 9999;
  height: 51px;

}

.custom-navbar .navbar-nav .nav-link {
  color: #fff;
  font-weight: 600;
}

.custom-navbar .navbar-nav .nav-link:focus,
.custom-navbar .navbar-nav .nav-link:hover {
  color: #DFE8F0;
}

.custom-navbar .navbar-nav .nav-link.active,
.custom-navbar .navbar-nav .nav-link.show {
  color: #DFE8F0;
}
`, "",{"version":3,"sources":["webpack://./src/layout/CSS/TopBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;AACd;;AAEA;;EAEE,WAAW;EACX,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;AACb;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,aAAa;EACb,YAAY;;AAEd;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;EAEE,cAAc;AAChB","sourcesContent":[".main_div_topbar {\n  display: flex;\n  flex-direction: row;\n  position: fixed;\n  height: 60px;\n}\n\n.toggle_bars,\n.bells {\n  height: 50%;\n  padding-right: 50px;\n  padding-top: 14px;\n  color: #fff;\n}\n\n.logouts,\n.bells {\n  margin-left: 64%;\n}\n\n.navbar-toggler-icon {\n  width: 2.5em;\n  height: 2.5em;\n}\n\n.custom-navbar {\n  box-shadow: 0 2px #118cce0f;\n  background-color: #172E55;\n  z-index: 9999;\n  height: 51px;\n\n}\n\n.custom-navbar .navbar-nav .nav-link {\n  color: #fff;\n  font-weight: 600;\n}\n\n.custom-navbar .navbar-nav .nav-link:focus,\n.custom-navbar .navbar-nav .nav-link:hover {\n  color: #DFE8F0;\n}\n\n.custom-navbar .navbar-nav .nav-link.active,\n.custom-navbar .navbar-nav .nav-link.show {\n  color: #DFE8F0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
