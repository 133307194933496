import React from 'react'
import slide16 from "../images/staticslides/Slide_16.jpg";
function GrowthOppAnalysysSlide() {
  return (
    <div className="slideimg border" style={{ backgroundImage: `url(${slide16})` }}>
              <div className="slideDiv"> 
                <div className="d-flex justify-content-between align-items-center">
                  <div className="pagecountno">
                    <span className="">5</span>
                  </div>
                  <div className="pagetitletext">
                    <span className="">Growth Opportunities in XXX Sector, 2024-20XX</span>
                  </div>
                </div>
                <div className="row mt-5 pt-4">
                  <div className="col-md-7">
                  </div>
                  <div className="col-md-5 mt-5 pt-4">
                    <h5 className="text-white">Growth Opportunity Analysis: Growth Generator in</h5>
                    <input type="text" className="form-control form-control-sm mb-2 me-2" placeholder="Sector"/>  
                    <textarea className="form-control form-control-sm mb-2" rows={3} placeholder="Click to add subtitle"></textarea>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default GrowthOppAnalysysSlide