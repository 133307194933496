import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
// process.env.REACT_APP_GO_IN_BASE_URL/project/{project-id}/drsegmentation?user_id={userId}

export const DriverRestraintAPiSlice= createApi({
    reducerPath:'api',
    baseQuery:fetchBaseQuery({baseUrl:process.env.REACT_APP_GO_IN_BASE_URL}),
    tagTypes:['segmentTreeApi'],
    endpoints:(builder)=>({
        getDNRData:builder.query({
            query:({projectId,userId})=>`/project/${projectId}/drsegmentation?user_id=${userId}`,
            providesTags:['segmentTreeApi']
        }),
        addSegmentationNode:builder.mutation({
            query:({projectId,body})=>{
                return ({
                    url:`/project/${projectId}/adddrsegmentation`,
                    method:'POST',
                    body:body
                })
            },
            invalidatesTags:['segmentTreeApi']
        }),
        editSegmentationNode:builder.mutation({
            query:({projectId,body})=>{
                return({
                    url:`/project/${projectId}/editdrsegmentation`,
                    method:"POST",
                    body:body
                })
            },
            invalidatesTags:['segmentTreeApi']
        }),
        deleteSegmentationNode:builder.mutation({
            query:({projectId,body})=>{
                return({
                    url:`/project/${projectId}/deletedrsegmentation`,
                    method:"DELETE",
                    body:body
                })
            },
            invalidatesTags:['segmentTreeApi']
        })
    })
})

export const {useGetDNRDataQuery,useAddSegmentationNodeMutation,
    useEditSegmentationNodeMutation,useDeleteSegmentationNodeMutation
}=DriverRestraintAPiSlice