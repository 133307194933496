import React, { useContext } from 'react'
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';

const AwardRow = ({ index, awardsData, handleChange }) => {
    const {isProjectEditable}=useContext(ProjectStatusContext)
  return (
        <React.Fragment >
            <tr className='odd'> 
                <th className='align-middle text-center' rowSpan="4">Award {index+1}</th> 
                <td>Recipient</td> 
                <td>Company Contact</td>
                <td>Contact Title</td>
                <td>Contact Phone #</td>
                <td>Contact Email</td> 
            </tr> 
            <tr>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.recipient} onChange={(e) => handleChange(index, 'recipient', e.target.value)} /></td>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.company_contact} onChange={(e) => handleChange(index, 'company_contact', e.target.value)} /></td>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.contact_title} onChange={(e) => handleChange(index, 'contact_title', e.target.value)} /></td>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.contact_phone} onChange={(e) => handleChange(index, 'contact_phone', e.target.value)} /></td>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.contact_email} onChange={(e) => handleChange(index, 'contact_email', e.target.value)} /></td>
        </tr>
            <tr className='odd'> 
                <td>Award Category</td> 
                <td>Region</td> 
                <td>Market</td> 
                <td>BPR Support? (Y/N)</td>
                <td>Planned Submission Date/Comments</td>
            </tr>
            <tr>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.award_category} onChange={(e) => handleChange(index, 'award_category', e.target.value)} /></td>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.Region} onChange={(e) => handleChange(index, 'Region', e.target.value)} /></td>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.market} onChange={(e) => handleChange(index, 'market', e.target.value)} /></td>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.bpr_support} onChange={(e) => handleChange(index, 'bpr_support', e.target.value)} /></td>
            <td><input disabled={!isProjectEditable} type="text" className="form-control form-control-sm" value={awardsData.comments} onChange={(e) => handleChange(index, 'comments', e.target.value)} /></td>
        </tr>
        </React.Fragment>
  )
}

export default AwardRow;