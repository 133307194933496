// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    font-size: 14px;
}
.custom-datatable thead th{
	background-color:#0e4c8b !important;
	color:#fff;
    font-size: 14px;
    font-weight: 600;
}
.custom-datatable tbody td{
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Styles/CustomDatatable.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;CACC,mCAAmC;CACnC,UAAU;IACP,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB","sourcesContent":["body{\n    font-size: 14px;\n}\n.custom-datatable thead th{\n\tbackground-color:#0e4c8b !important;\n\tcolor:#fff;\n    font-size: 14px;\n    font-weight: 600;\n}\n.custom-datatable tbody td{\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
