import { Box, Link, Button, Modal, Typography } from '@mui/material';
import React from 'react'
import axios from 'axios';
import { useState, useEffect } from 'react'
import './Styles/dashboard.css'
import SampleTable from './SampleTable';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateProject from './CreateProject';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';



function MyProjects() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('draft');
  const [practiceArea, setPracticeArea] = useState([])
  const [programArea, setProgramArea] = useState([])



  const [filters, setFilters] = useState({
    GO_IN_Type: [{ id: "1", name: "GO-IN - Industry" },
      { id: "0", name: "GO-3-WEEK-MAX" }
    ],
    Practice_Area: [],
    Program_Area: [],
  });

  const [appliedFilters, setAppliedFilters] = useState({
    GO_IN_Type: '1',
    Status: '',
    Practice_Area: '',
    Program_Area: '',
  });
  const fetchData = async (path, reqStatus, role_type, user_id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/${path}`, {
        params: {
          status: reqStatus,
          role_type: role_type,
          user_id: user_id
        },
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.status === 200) {
        const result = response.data;
        return result;
      } else {
        return {};
      }
    } catch (error) {
      console.log("There was an error!", error);
      return {}
    }
  };
  const GetStageItemArrayLength = async (status) => {
    const res = await fetchData('listproject', status, getUserDetailsFromLocalStorage()?.role, getUserDetailsFromLocalStorage()?.user_id);
    let filteredDataSet = res?.data
    if (appliedFilters?.GO_IN_Type) {
      filteredDataSet = filteredDataSet.filter((e) => e.go_in_type == appliedFilters.GO_IN_Type)
    }
    if (appliedFilters?.Practice_Area) {
      filteredDataSet = filteredDataSet.filter((e) => e.industry == appliedFilters.Practice_Area)
    }
    if (appliedFilters?.Program_Area) {
      filteredDataSet = filteredDataSet.filter((e) => e.sector_id == appliedFilters.Program_Area)
    }
    return filteredDataSet.length;
  }
  const [sectionData, setSectionData] = useState([
    { name: 'Draft', value: '-', slug: 'draft' },
    { name: "Returned To Analyst", value: '-', slug: 'toAnalyst' },
    { name: "Project Manager", value: '-', slug: 'toPm' },
    { name: "Quality Control", value: '-', slug: 'toEditor' },
    { name: "Electronic Publishing", value: '-', slug: 'toQc' },
    { name: "Published", value: '-', slug: 'published' },
  ])

  const handleClick = (e, index, slug) => {
    e.preventDefault();
    setSelectedStatus(slug);
    setActiveIndex(index);
  }
  const getPracticeArea = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getindustry`)
      setFilters(prevFilters => ({
        ...prevFilters,
        Practice_Area: data?.data?.data
      }));
      setPracticeArea(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const getProgramArea = async (id, name) => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsector/${id}/`)
      setFilters(prevFilters => ({
        ...prevFilters,
        Program_Area: data?.data?.data
      }));
      setProgramArea(data?.data?.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name == 'Practice_Area') {
      getProgramArea(value)
      setAppliedFilters({
        ...appliedFilters,
        [name]: value,
        Program_Area: ''

      });
    } else {
      setAppliedFilters({
        ...appliedFilters,
        [name]: value,
      });
    }

  };

  const clearFilters = () => {
    setAppliedFilters({
      GO_IN_Type: '',
      Status: '',
      Practice_Area: '',
      Program_Area: '',
    })
  };
  useEffect(() => {
    if (practiceArea.length == 0) {
      getPracticeArea()
    }
  }, [practiceArea])

  const findCount = async () => {
    if (getUserDetailsFromLocalStorage()?.role == 'analyst') {
      var stages = ['draft', 'toPm', 'toAnalyst', 'toEditor', 'toQc', 'published', 'OtherPublishedProjects'];
      var names = [
        'Draft',
        'Project Manager',
        'Returned To Analyst',
        'Quality Control',
        'Electronic Publishing',
        'Published',
        'All Published Projects'
      ];
    } else {
      var stages = ['draft', 'toPm', 'toAnalyst', 'toEditor', 'toQc', 'published'];
      var names = [
        'Draft',
        'Project Manager',
        'Returned To Analyst',
        'Quality Control',
        'Electronic Publishing',
        'Published'
      ];
    }


    const counts = await Promise.all(stages.map(stage => GetStageItemArrayLength(stage)));

    const count = stages.map((stage, index) => ({
      name: names[index],
      value: counts[index] || '-',
      slug: stage
    }));

    return count;
  };


  useEffect(() => {
    const fetchCount = async () => {
      try {
        let count = await findCount();
        if (count.length) {
          setSectionData(count)
        }
      } catch (error) {
        console.log("Error fetching count:", error);
      }
    };
    fetchCount();
  }, [appliedFilters]);

  return (
    <div className='container-fluid'>
      <div className='d-flex justify-content-between py-2 align-items-center'>
        <h5 style={{ color: '#0e4c8b' }} className='mb-0'>My Projects</h5>
        <button type='button' data-bs-toggle="modal" data-bs-target="#createProjectModal" className='btn btn-sm btn-primary'><FontAwesomeIcon icon={faPlus} /> Create Project</button>
      </div>
      <div className='card mb-3 rounded-0 border-0' style={{ backgroundColor: '#1582C5' }}>
        <div className='card-body'>
          <div className='row gx-3'>
            <div className='col-auto d-flex align-items-center'>
              <h5 className='mb-0 me-2 text-white'>Filters</h5>
            </div>
            {Object.keys(filters).map((filterKey, index) => (
              <div className="col" key={index}>
                <select
                  key={filterKey}
                  className="form-select form-select-sm"
                  name={filterKey}
                  value={appliedFilters[filterKey]}
                  onChange={handleFilterChange}
                  disabled={ (filterKey === "Program_Area" && appliedFilters?.Practice_Area == '')}
                >
                  {filterKey !== 'GO_IN_Type' ?
                    <option value="" >
                      {filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}
                    </option> : null}
                    
                  {filters[filterKey]?.map((e,index) => <option key={e?.id} value={index+1}>{e?.name}</option>)}

                </select>
              </div>
            ))}
            <div className='col d-flex align-items-end'>
              <button className="btn btn-light btn-sm" onClick={clearFilters}><i class="fa fa-refresh"></i> Reset</button>
            </div>
          </div>
        </div>
      </div>

      <Box className="row gx-2">
        {sectionData.map(({ name, value, slug }, index) => (
          <Link className='col-auto cursor-pointer' key={index} onClick={(e) => handleClick(e, index, slug)} >
            <span className={`badge ${index === activeIndex ? 'bg-primary rounded-0' : 'bg-secondary rounded-0'}`}>{name}&nbsp;{'(' + value + ')'}</span>
          </Link>
        ))}
        <Box className='extras' />
      </Box>

      <SampleTable data={selectedStatus} filterData={appliedFilters} />
      <div className="modal fade" id="createProjectModal"  >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header py-2">
              <h5 style={{ color: '#0e4c8b' }} className="modal-title">Create Project</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <div className='font14 d-flex border p-1'>
                <i style={{ color: '#1582C5' }} className='fa fa-info-circle font16 mx-1 mt-1'></i>
                <ul className='mb-0 ps-4'>
                  <li className='mb-1'>For the Title, enter the approved title as created in Production Plan.</li>
                  <li className='mb-1'>The short title needs to be a combination of alphabets and/or numerals.</li>
                  <li className='mb-1'>The Product Code(WIP Code) also cannot be just numbers.</li>
                  <li className='mb-1'>The study period should span the tenure of the study for which the project is being created.</li>
                </ul>
              </div>
              <CreateProject reload={() => { clearFilters() }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyProjects

