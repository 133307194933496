import React, { useContext, useEffect, useState } from 'react'
import { useGetCriteriaDataQuery, useGetGrowthUniverseDataByIdQuery } from '../../redux/slices/ProjectPageAPISlice';
import { useParams } from 'react-router-dom'
import GouTabsComponent from '../GrowthOppUniverse/GouTabsComponent'
import GouWithOutTabs from '../GrowthOppUniverse/GouWithOutTabs'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {  toast } from 'react-toastify'
import { usePostTocDataMutation } from '../../redux/slices/ProjectPageAPISlice';
import SSOLoader from '../SSOLoader'
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import Footer from './Footer';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
function GrowthOpportunityUniverse(props) {
    const [loader,setLoader]=useState(false)
    const {isProjectEditable}=useContext(ProjectStatusContext)
    const { projectId } = useParams();
    const { data, isError } = useGetCriteriaDataQuery();
    const { data: growthUniverseDatabyId, isLoading, isSuccess } = useGetGrowthUniverseDataByIdQuery({ projectId: projectId, goInId: '' })
    const [gouDatabyProject, setGrowthDataByProject] = useState([])
    const [value, setValue] = useState(0)
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    const handleAddGoin = () => {
        const newTab = { name: 'New Tab', id: '' };
        setGrowthDataByProject([...gouDatabyProject, newTab]);
        setValue(gouDatabyProject.length);
    };
    const [postTocData] = usePostTocDataMutation();
    
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");

    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                    "isSaved":1
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log(error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }


    useEffect(() => {
        setHeader(props?.name)
    }, [props])


    useEffect(() => {
        setGrowthDataByProject(growthUniverseDatabyId?.data ? growthUniverseDatabyId?.data : []);
        setValue(0)
    }, [isLoading, growthUniverseDatabyId, isSuccess]);

    return (
        <div className="card ">
            
             {loader ? <SSOLoader /> : null}
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    {
                        headerIsEdit ?
                            <div className='input-group input-group-sm w-50'>
                                <input type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                                <button type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                                <button type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                            </div>
                            :
                            <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
                    }

                    
                </div>
            </div>
            <div className="card-body">
                {gouDatabyProject?.length ? <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 2 }}>
                        <TabList onChange={handleChange} aria-label="growth-universe-tabs">
                            {gouDatabyProject?.map((item, i) => {
                                return <Tab key={i} label={item.name} value={i} />
                            })}

                        </TabList>
                        {isProjectEditable?<button onClick={handleAddGoin} className="btn btn-primary btn-sm">Add</button>:null}
                    </Box>
                    {gouDatabyProject?.map((item, i) => {
                        return <TabPanel value={i} key={i}>
                            <GouTabsComponent goInid={item.id}
                                growthUniverseData={data?.data} tocId={props.id}/>
                        </TabPanel>
                    })}
                </TabContext> : <GouWithOutTabs goInid='' growthUniverseData={data?.data} tocId={props.id}/>}

            </div>
            <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{backgroundColor:'#1582C5'}}>
                <Footer/>
            </div>
        </div>

    )
}

export default GrowthOpportunityUniverse