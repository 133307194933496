import React from 'react'

function AddCustomers() {
    return (
        <div className="sticky-top rs-top">
            <div className="row gx-1">
                <div className="col-4">
                    <select className="form-select form-select-sm">
                        <option>Level</option>
                        <option>Level 1</option>
                        <option>Level 2</option>
                        <option>Level 3</option>
                    </select>
                </div>
                <div className="col-8">
                    <div className="input-group input-group-sm mb-1">
                        <input type="text" className="form-control form-control-sm" placeholder="Search By Name" />
                        <span className="input-group-text">Add</span>
                    </div>
                </div>
                <div className="font12 mt-2">
                    Customer will see radar-specific information WITHOUT the text descriptions, a list of companies names ONLY (with no other information), and a radar graphic with all dots but NO labels.
                </div>
                
            </div>
            <div className='input-group input-group-sm mt-5 '>
                <h6 className='col-12'>Customers</h6>
               <div className='col-9 d-flex justify-content-start '>
                    <span
                        type="text"
                        className="form-control form-control-sm "
                        name='name'
                    ></span>
                    <span className="input-group-text" >
                        <img
                            className=""
                            src={require('../images/action_icon/close.png')}
                        />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AddCustomers