const range = (start, stop, step) => 
        Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

function formatDate(dateString) {
        const date = new Date(dateString);
        
        const day = date.getUTCDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getUTCFullYear();
        
        let hours = date.getUTCHours();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
      
        return `${day}-${month} ${year} at ${hours}:${minutes}${ampm}`;
}

export {range,formatDate}