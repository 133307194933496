import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

export const SegmentationTreeContext = createContext();

export const SegmentationTreeProvider = ({ children,isForecastCompleted }) => {
    const [isNodeEditable,setIsNodeEditable]=useState(false);
    
    const segmentationTreeContextValue = useMemo(
        ()=>{
            return {
              isNodeEditable,setIsNodeEditable
            }
        },[
          isNodeEditable,setIsNodeEditable]);
         
  return (
    <SegmentationTreeContext.Provider value={segmentationTreeContextValue}>
      {children}
    </SegmentationTreeContext.Provider>
  );
};