import React, { useContext, useEffect, useState } from 'react'
import { useDeleteGouDataMutation, useGetGrowthUniverseDataByIdQuery, usePostCriteriaDataMutation } from '../../redux/slices/ProjectPageAPISlice';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import CkEditorBase from '../../CkEditorReact/CkEditorBase';
import './Style.css'

function GouWithOutTabs({ goInid, growthUniverseData, tocId }) {

    const [postCriteriaData] = usePostCriteriaDataMutation();
    const [deleteGouData] = useDeleteGouDataMutation()
    const { projectId } = useParams();
    const { data, isLoading } = useGetGrowthUniverseDataByIdQuery({ projectId: projectId, goInId: goInid })
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const [selectedUserIndustries, setSelectedUserIndustries] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedGrowthItems, setSelectedGrowthItems] = useState([]);

    const [goInTitle, setGoInTitle] = useState('');
    const [baseYear, setBaseYear] = useState(new Date().getUTCFullYear());
    const [timeLine, setTimeLine] = useState('');
    const [opportunitySize, setOpportunitySize] = useState('')

    const [candDone, setCandDOne] = useState('');
    const [candDTwo, setCandDTwo] = useState('');

    const [validationErrors, setValidationErrors] = useState({
        title: false,
        opportunitySize: false,
        timeLine: false,
        baseYear: false,
        userIndustries: false,
        regions: false,
        growthItems: false
    });
    const range = (start, stop, step) =>
        Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));



    const validateGOU = () => {
        let isValid = true;
        let errors = [];
        let newValidationErrors = {
            title: false,
            opportunitySize: false,
            timeLine: false,
            baseYear: false,
            userIndustries: false,
            regions: false,
            growthItems: false
        };

        if (!goInTitle.trim()) {
            errors.push('Title is required.');
            newValidationErrors.title = true;
            isValid = false;
        }

        if (opportunitySize === "") {
            errors.push('Opportunity Size in 5 Years must be selected.');
            newValidationErrors.opportunitySize = true;
            isValid = false;
        }

        if (timeLine === "") {
            errors.push('Timeline for Action must be selected.');
            newValidationErrors.timeLine = true;
            isValid = false;
        }

        if (baseYear === "") {
            errors.push('Base Year must be selected.');
            newValidationErrors.baseYear = true;
            isValid = false;
        }

        if (selectedUserIndustries.length === 0) {
            errors.push('At least one User Industry must be selected.');
            newValidationErrors.userIndustries = true;
            isValid = false;
        }

        if (selectedRegions.length === 0) {
            errors.push('At least one Region must be selected.');
            newValidationErrors.regions = true;
            isValid = false;
        }

        if (selectedGrowthItems.length === 0) {
            errors.push('At least one Growth Item must be selected.');
            newValidationErrors.growthItems = true;
            isValid = false;
        }

        setValidationErrors(newValidationErrors);
        return { isValid, errors };
    };


    const handlePostGOU = () => {

        const { isValid, errors } = validateGOU();

        if (!isValid) {
            // Loop over the array and show the errors
            errors.forEach(error => {
                toast.error(error);
            });
            return;
        }

        const dataObj = {
            userid: getUserDetailsFromLocalStorage().user_id,
            title_go: goInTitle,
            opp_size: opportunitySize,
            timeline: timeLine,
            baseyear: baseYear,
            industry: selectedUserIndustries,
            regions: selectedRegions,
            growth: selectedGrowthItems,
            context: candDone,
            call_to_action: candDTwo
        }



        postCriteriaData({ body: dataObj, eco_project_id: projectId })
            .then((res) => console.log(res)).then(() => {
                setSelectedUserIndustries([]);
                setSelectedRegions([]);
                setSelectedGrowthItems([]);
                setGoInTitle('');
                setBaseYear(new Date().getFullYear());
                setTimeLine(0);
                setOpportunitySize(0);
            }).catch((err) => console.log(err));
    }
    const handleDelete = async () => {
        try {
            await deleteGouData({ projectId: projectId, goInId: goInid }).unwrap();

            toast.success('GOU data deleted successfully')

        } catch (error) {
            toast.error('Error deleting GOU data:', error);
        }
    };
    const handleCkChange = (data,num) => {
        // console.log(data);
        
        if(num == 1){
            setCandDOne(data)
        }else{
            setCandDTwo(data)
        }
    }
    return (
        <React.Fragment>

            <div className='accordion-body'>
                <div className='row'>
                    <div className='col-md-9'>
                        <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input type="text"
                                value={goInTitle}
                                disabled={!isProjectEditable}
                                onChange={(e) => {
                                    setGoInTitle(e.target.value);
                                    setValidationErrors(prev => ({ ...prev, title: false }));
                                }}
                                name='title_name'
                                className={`form-control form-control-sm ${validationErrors.title ? 'is-invalid' : ''}`}
                            />
                        </div>
                    </div>


                </div>
            </div>
            <div className="accordion-body px-1">
                <div className="row gx-1">
                    <div className="col">
                        <div className="d-flex gpotbmediaobj mb-1 border">
                            <div className="flex-shrink-0 align-items-center d-flex">
                                Opp. Size in 5 Years
                            </div>
                            <div className="flex-grow-1 align-self-center d-flex">
                                <select
                                    disabled={!isProjectEditable}
                                    value={opportunitySize}
                                    onChange={(e) => {
                                        setOpportunitySize(e.target.value);
                                        setValidationErrors(prev => ({ ...prev, opportunitySize: false }));
                                    }}
                                    className={`form-select form-select-sm ${validationErrors.opportunitySize ? 'is-invalid' : ''}`}
                                    style={{ width: '85px', margin: '0 auto' }}
                                >
                                    <option value="">Select Opportunity Size</option>
                                    {growthUniverseData?.oppsize?.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>
                                    })}

                                </select>
                            </div>
                        </div>
                        <div className="d-flex gpotbmediaobj mb-1 border">
                            <div className="flex-shrink-0 align-items-center d-flex">
                                Timeline for Action
                            </div>
                            <div className="flex-grow-1 align-self-center">
                                <select
                                    disabled={!isProjectEditable}
                                    value={timeLine}
                                    onChange={(e) => {
                                        setTimeLine(e.target.value)
                                        setValidationErrors(prev => ({ ...prev, timeLine: false }));
                                    }}
                                    className={`form-select form-select-sm ${validationErrors.timeLine ? 'is-invalid' : ''}`}
                                    style={{ width: '85px', margin: '0 auto' }}
                                >
                                    <option value="">Select Timeline</option>
                                    {growthUniverseData?.timeline?.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="d-flex gpotbmediaobj mb-1 border">
                            <div className="flex-shrink-0 align-items-center d-flex">
                                Base Year
                            </div>
                            <div className="flex-grow-1 align-self-center">
                                <select
                                    disabled={!isProjectEditable}
                                    className={`form-select form-select-sm ${validationErrors.baseYear ? 'is-invalid' : ''}`}
                                    value={baseYear}
                                    onChange={(e) => {
                                        setBaseYear(e.target.value)
                                        setValidationErrors(prev => ({ ...prev, baseYear: false }));
                                    }}
                                    style={{ width: '85px', margin: '0 auto' }}
                                >
                                    {range(new Date().getFullYear(), new Date().getFullYear() + 5, 1).map((item) => {
                                        return <option key={item}>{item}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className='border p-1 text-center relreg_title'>
                            Relevant End User Industries for this Growth Opportunity
                        </div>
                        <div className={`row gpicondiv gx-1 ${validationErrors.userIndustries ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.industry?.map((item, i) => {
                                return <div disabled={!isProjectEditable} onClick={() => {

                                    if (selectedUserIndustries?.includes(item.id)) {
                                        setSelectedUserIndustries((prev) => prev.filter((i) => item.id !== i))
                                    }
                                    else setSelectedUserIndustries((prev) => [...prev, item.id]);
                                    setValidationErrors(prev => ({ ...prev, userIndustries: false }));
                                }} key={i}
                                    className="col-md-4 mb-1">
                                    <div className={selectedUserIndustries?.includes(item.id) ? "d-flex gpmediaobj p-1 active" : "d-flex gpmediaobj p-1"}>
                                        <div className="flex-shrink-0 align-self-center">
                                            <img src={require(`../../images/industries/${selectedUserIndustries?.includes(item.id) ? `icon_white` : `icon_grey`}/${item.icon_name}`)} />
                                        </div>
                                        <div className="flex-grow-1 ms-2 align-self-center">
                                            <span>{item.name}</span>
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>
                    </div>
                    <div className="col">
                        <div className='border p-1 text-center relreg_title'>
                            Applicable Regions
                        </div>
                        <div className={`row gx-1 ${validationErrors.regions ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.region?.map((item, i) => {
                                return <div disabled={!isProjectEditable} onClick={() => {
                                    if (selectedRegions?.includes(item.id)) {
                                        setSelectedRegions((prev) => prev.filter((i) => item.id !== i))
                                    }
                                    else setSelectedRegions((prev) => [...prev, item.id]);
                                    setValidationErrors(prev => ({ ...prev, regions: false }));
                                }} key={i} className='col-md-6'>
                                    <div className={selectedRegions?.includes(item.id) ? "mapimg mb-1 active" : "mapimg mb-1"}>
                                        <img className='img-fluid' src={require(`../../images/regions/${selectedRegions?.includes(item.id) ? `icon_white` : `icon_grey`}/${item.icon_name}`)} />
                                    </div>
                                </div>
                            })}

                        </div>
                    </div>
                </div>


                <div className='mt-3 mb-2 text-center gp_title'>
                    Frost & Sullivan has identified 10 Growth Processes that serve as levers for determining and evaluating new Growth Opportunities.
                </div>
                <div className="d-flex border growthprocess mb-4">
                    <div className="flex-shrink-0 align-self-center text-center p-2">
                        <img className='mb-2' src={require('../../images/growthprocess/gp_icon.png')} />
                        <span>Growth Process</span>
                    </div>
                    <div className="flex-grow-1 bg-white pt-3 px-2">
                        <div className={`row gx-2 ${validationErrors.growthItems ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.growth.slice(0, 5).map((item, i) => {
                                return <div
                                    disabled={!isProjectEditable}
                                    onClick={() => {
                                        if (selectedGrowthItems?.includes(item.id)) {
                                            setSelectedGrowthItems((prev) => prev.filter((i) => item.id !== i))
                                        }
                                        else setSelectedGrowthItems((prev) => [...prev, item.id]);
                                        setValidationErrors(prev => ({ ...prev, growthItems: false }));
                                    }}
                                    key={i} className='col mb-3' style={{ cursor: 'pointer' }}>
                                    <div className={selectedGrowthItems?.includes(item.id) ? "iconWrapper active" : "iconWrapper"}>
                                        <img src={require(`../../images/growthprocess/${selectedGrowthItems?.includes(item.id) ? `icon_white` : `icon_blue`}/${item.icon_name}`)} />
                                    </div>
                                    <span className={selectedGrowthItems?.includes(item.id) ? "gouspan active" : "gouspan"} >{item.name}</span>
                                </div>
                            })}

                        </div>
                        <div className={`row gx-2 ${validationErrors.growthItems ? 'is-invalid' : ''}`}>
                            {growthUniverseData?.growth.slice(5, 10).map((item, i) => {
                                return <div
                                    disabled={!isProjectEditable}
                                    onClick={() => {
                                        if (selectedGrowthItems?.includes(item.id)) {
                                            setSelectedGrowthItems((prev) => prev.filter((i) => item.id !== i))
                                        }
                                        else setSelectedGrowthItems((prev) => [...prev, item.id]);
                                        setValidationErrors(prev => ({ ...prev, growthItems: false }));
                                    }}
                                    key={i} className='col mb-3' style={{ cursor: 'pointer' }}>
                                    <div className={selectedGrowthItems?.includes(item.id) ? "iconWrapper active" : "iconWrapper"}>
                                        <img src={require(`../../images/growthprocess/${selectedGrowthItems?.includes(item.id) ? `icon_white` : `icon_blue`}/${item.icon_name}`)} />
                                    </div>
                                    <span className={selectedGrowthItems?.includes(item.id) ? "gouspan active" : "gouspan"} >{item.name}</span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className='row gx-1'>
                    <div className='col-6'>
                        <div className='conTextDef mb-1'>
                            <img src={require('../../images/calltoaction/icon_b.png')} />
                            <span>Context and Definition</span>
                        </div>
                        {
                            isProjectEditable ? <CkEditorBase onChange={(data) => handleCkChange(data,1)} data={candDone} editorId='gouWithOutTabs-1' tocId={tocId} Limits={[250, 1200, true]} /> :
                                <textarea rows={10} disabled className='form-control form-control-sm '></textarea>
                        }

                    </div>
                    <div className='col-6'>
                        <div className='callToAction mb-1 ps-4'>
                            <img src={require('../../images/calltoaction/icon37.png')} />
                            <span>Call to Action</span>
                        </div>
                        {
                            isProjectEditable ?

                                <CkEditorBase onChange={(data) => handleCkChange(data,2)} data={candDTwo} editorId='gouWithOutTabs-2' tocId={tocId} Limits={[250, 1200, true]} />
                                : <textarea disabled rows={10} className='form-control form-control-sm '></textarea>
                        }
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
                <button disabled={!isProjectEditable} type="button" onClick={handlePostGOU} className="btn btn-primary btn-sm me-2"><i className="fa fa-save me-1"></i>Save</button>
                <button disabled={!isProjectEditable} type="button" onClick={handleDelete} className="btn btn-danger btn-sm"><i className="fa fa-close me-1"></i>Delete</button>
            </div>
        </React.Fragment>
    )
}

export default GouWithOutTabs