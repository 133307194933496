import React, { useContext, useEffect, useState } from 'react'
import { useGetProjectDetailsQuery, usePostTocDataMutation, useUpdateTocIsSavedMutation } from '../redux/slices/ProjectPageAPISlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ProjectStatusContext } from '../Providers/ProjectStatusProvider';
import { ProgressContext } from '../Providers/ProgressProvider';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import { useParams } from 'react-router-dom';
import CkEditorBase from '../CkEditorReact/CkEditorBase';
import Footer from './TocChildren/Footer';


function IndexSlide(props) {
    const { projectId } = useParams()
    const { isProjectEditable } = useContext(ProjectStatusContext)
    const { handleSectionSave } = useContext(ProgressContext)
    const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess } = useGetProjectDetailsQuery({ projectId: projectId });
    const [sector, setSector] = useState({})
    const getSectorData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsectorbyid/${id}`);
            setSector(res?.data?.data?.[0] || {})
        } catch (error) {
            console.log(error);
        }
    }

    const [text, setText] = useState('');
    const [ckId, setCkId] = useState(null);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved] = useUpdateTocIsSavedMutation()



    const handleTextChange = (e) => {
        let strippedText = e.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
        const characterCount = strippedText.length;
        // const wordCount = strippedText.split(/\s+/).filter(word => word.length > 0).length;



        if (characterCount < 300) {
            setText(e);
            if (debounceTimeout) clearTimeout(debounceTimeout);
            const timeout = setTimeout(() => {
                handleSave(e);
            }, 3000);
            setDebounceTimeout(timeout);

        } else {
            toast.error('Only 300 characters allowed!')
        }

    }

    const handleSave = async (inputText) => {
        try {
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": inputText,
                    "name": props?.name,
                    "isSaved": 1
                }
            }).then((res) => console.log(res))
            toast.success('Auto Saved Successfully')
            updateTocIsSaved({ projectId: projectId, tocId: props.id })
            handleSectionSave(props.id)
        } catch (error) {
            console.log("There was an error!", error);
            toast.error("Something went wrong")
        }
    }
    useEffect(() => {
        if (ProjectData?.data) {
            getSectorData(ProjectData?.data?.sector_id)
        }
    }, [ProjectData])

    useEffect(() => {
        return () => {
            if (debounceTimeout) clearTimeout(debounceTimeout);
        };
    }, [debounceTimeout]);


    useEffect(() => {
        setText(props?.description)
        setCkId(props?.id)
    }, [props])


    return (
        <>
            <div class="w-100 h-100 flex-grow-1 position-relative gp-editor">
                <div class="w-100 h-100 gp-editing-content">
                <span className="gp-ck-title">{props?.ProjectName} in {sector?.name} Sector, {ProjectData?.data?.study_from}-{ProjectData?.data?.study_to}</span>
                    <img src={props?.slide}
                        alt="ck-edited-image" />
                    <div class="gp-ck-editor-index">

                        <p className="text-white">{props?.name} in {sector?.name}, {ProjectData?.data?.study_from}-{ProjectData?.data?.study_to}</p>
                        {
                            ckId ? <CkEditorBase onChange={handleTextChange} data={text} tocId={ckId} editorId={`toc-${ckId}`} Limits={[50, 300, true]} /> : null
                        }
                    </div>

                </div>
            </div>
           <Footer />
           
        </>
    )
}

export default IndexSlide

