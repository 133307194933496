import React, { useContext, useEffect, useState } from 'react'
import { useGetProjectDetailsQuery, usePostTocDataMutation, useUpdateTocIsSavedMutation } from '../redux/slices/ProjectPageAPISlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ProjectStatusContext } from '../Providers/ProjectStatusProvider';
import { ProgressContext } from '../Providers/ProgressProvider';
import { getUserDetailsFromLocalStorage } from '../Utils/userUtils';
import { useParams } from 'react-router-dom';
import Footer from './TocChildren/Footer';
function StaticSlide(props) {
    const { projectId } = useParams()
    const { data: ProjectData, isLoading: isProjectDataLoading, isSuccess: isProjectDataSuccess } = useGetProjectDetailsQuery({ projectId: projectId });
    const [sector, setSector] = useState({})
    const getSectorData = async (id) => {
        try {
            var res = await axios.get(`${process.env.REACT_APP_GO_IN_BASE_URL}/projectdet/getsectorbyid/${id}`);
            setSector(res?.data?.data?.[0] || {})
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (ProjectData?.data) {
            getSectorData(ProjectData?.data?.sector_id)
        }
    }, [ProjectData])
    return (
        <div className="slideimg border position-relative" style={{
            backgroundImage: sessionStorage.getItem('mode') !== "fullScreen" 
              ? `url(${props?.slide})` 
              : `url(${props?.fullScreenSlide})`
          }}>
            <div className="slideDiv">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="pagecountno">
                        <span className="text-white">{props?.pageNo}</span>
                    </div>
                    <div className="pagetitletext">
                        <span className="">{props?.name} in {sector?.name} Sector, {ProjectData?.data?.study_from}-{ProjectData?.data?.study_to}</span>
                    </div>
                </div>
            </div>
            <p style={{marginBottom:'2px'}} className='font12 position-absolute bottom-0 start-50 translate-middle-x'>{ProjectData?.data?.wipcode}</p>
        </div>
    )
}

export default StaticSlide