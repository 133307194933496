import Button from '@mui/material/Button';
import { useState, Fragment, ReactElement } from 'react';
import { Box, Tabs, Tab, } from '@mui/material'
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect } from 'react';
import TotalMarketAnalysis from '../../TotalMarketAnalysis';
import OtherModels from '../../Components/OtherModels';
import axios from 'axios';
import { useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice';
import { useParams } from 'react-router-dom';
import { ProgressContext } from '../../Providers/ProgressProvider';
import { useContext } from 'react';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { toast, ToastContainer } from 'react-toastify';

const ModelTabs = ({ parentNodeId, DnrData, isForecastCompleted, tocId }) => {
  const [enableCalc, setEnableCalc] = useState(false)
  const [showForecast, setShowForecast] = useState(false)
  const { selectedTab, setSelectedTab } = useContext(ProjectStatusContext)
  const [otherData, setOtherData] = useState([])
  const id = window.location.href.split('/').pop()

  const getDnrData = async () => {
    let params = {
      seg_id: parentNodeId,
      user_id: getUserDetailsFromLocalStorage()?.user_id,
    }
    try {

      let data = await axios.get(
        `${process.env.REACT_APP_GO_IN_BASE_URL}/project/${id}/getdrdata`,
        { params }
      )
      let type = data?.data?.data?.dr_type
      let otherData = data?.data?.data?.other_data
      if (type == 'other' || typeof myVariable == 'undefined') {
        setSelectedTab(1)
        setOtherData(data?.data)
      } else {
        setSelectedTab(0)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (typeof parentNodeId != 'undefined') {
      getDnrData()
    }
  }, [parentNodeId])

  const MainCalculate = async () => {
    setShowForecast(!showForecast)
  }

  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  }


  return (
    <div>
      <ToastContainer />
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 2 }}>
          <TabList sx={{ ml: 2, flex: 1 }} onChange={handleChange} component="div" >
            <Tab value={0} sx={{ color: 'inherit' }} onClick={() => { setSelectedTab(0) }} label="Drivers and Restraints" />

            <Tab value={1} sx={{ color: 'inherit' }} onClick={() => { setSelectedTab(1) }} label="External Models" />
          </TabList>
          {enableCalc ?
            <>
              <Button autoFocus  >
                Save & Create Chart
              </Button>
              <Button autoFocus onClick={MainCalculate}>
                Calculate
              </Button>
            </> : null}
        </Box>



        <TabPanel value={0}>
          <TotalMarketAnalysis parentNode_Id={parentNodeId} dnrDataSet={DnrData} isForecastCompleted={isForecastCompleted} tocId={tocId} />
        </TabPanel>
        <TabPanel value={1} ><OtherModels parentNode_Id={parentNodeId} other_data={otherData} dnrDataSet={DnrData} reload={getDnrData}/></TabPanel>
      </TabContext>

    </div>
  )
}

export default ModelTabs