import React, { useContext, useEffect, useState } from 'react'
import { useCreateCloneTocMutation, useDeleteCloneMutation, useGetBPRDataQuery, useGetGroupByUserListQuery, usePostBPRDataMutation, usePostTocDataMutation, useUpdateIsApplicableMutation, useUpdateTocIsSavedMutation } from '../../redux/slices/ProjectPageAPISlice'
import { useParams } from 'react-router-dom'
import AwardRow from '../BPRAward/AwardRow';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetailsFromLocalStorage } from '../../Utils/userUtils'
import { Autocomplete, TextField } from '@mui/material';
import SSOLoader from '../SSOLoader';
import { ProjectStatusContext } from '../../Providers/ProjectStatusProvider';
import { ProgressContext } from '../../Providers/ProgressProvider';
import Footer from './Footer';
import DialogueBox from '../../ComponentUtils/DialogBox';

const GoInBestPracticeRecognition = (props) => {
    const {handleSectionSave}=useContext(ProgressContext)
    const {isProjectEditable}=useContext(ProjectStatusContext)
    const { projectId } = useParams();
    const [postTocData] = usePostTocDataMutation();
    const [updateTocIsSaved]=useUpdateTocIsSavedMutation();
    const [headerIsEdit, setHeaderIsEdit] = useState(false);
    const [header, setHeader] = useState("");
    const [loader,setLoader]=useState(false);
    const { data, isLoading, isError, isSuccess } = useGetBPRDataQuery({ projectId: projectId,tocId:props.id })
    const { data: bprAnalystData,
        isLoading: isBprAnalystLoading,
        isSuccess: isBprSuccess
    } = useGetGroupByUserListQuery({ slug: 'growth_opportunity_analyst' });
    const { data: pmListData,
        isLoading: isPmDataLoading,
        isSuccess: isPmDataSuccess
    } = useGetGroupByUserListQuery({ slug: 'radar_project_manager' })
    const [postBPRData] = usePostBPRDataMutation()
    const [analystList, setAnalystList] = useState([]);
    const [pmList, setPmList] = useState([]);

    const [selectedPm, setSelectedPm] = useState(null);
    const [selectedAnalyst, setSelectedAnalyst] = useState(null)
    const handleSaveToc = async () => {
        try {
            setLoader(true)
            await postTocData({
                projectId: props?.go_in_id,
                propsId: props?.id,
                body: {
                    "userid": getUserDetailsFromLocalStorage()?.user_id,
                    "description": props?.description,
                    "name": header,
                }
            })
            setHeaderIsEdit(false)
            setLoader(false)
            toast.success('Saved Successfully')
        } catch (error) {
            console.log( error);
            setLoader(false)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        if (isBprSuccess) {
            const transformAnalystList = bprAnalystData?.data.map((item) => ({
                email: item.email,
                user_id: item.user_id
            }))
            setAnalystList(transformAnalystList)
        }
    }, [isBprAnalystLoading, isBprSuccess])

    useEffect(() => {
        if (isPmDataSuccess) {
            const transformPmList = pmListData?.data.map((item) => ({
                email: item.email,
                user_id: item.user_id
            }))
            setPmList(transformPmList)

        }
    }, [isPmDataLoading, isPmDataSuccess, , isSuccess, isLoading])
    const userID = getUserDetailsFromLocalStorage().user_id
    const [bprId, setBprId] = useState(null);
    const [awardsData, setAwardsData] = useState([
        { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
        { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
        { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
    ]);
    useEffect(() => {
        if (isSuccess && data?.data?.criteria?.length) {
            const transformedData = data?.data?.criteria?.map(item => ({
                recipient: item.recipient,
                company_contact: item.company_contact,
                contact_title: item.contact_title,
                contact_phone: item.contact_phone,
                contact_email: item.contact_email,
                award_category: item.award_category,
                Region: item.Region,
                market: item.market,
                bpr_support: item.bpr_support,
                comments: item.comments
            }));

            setAwardsData(transformedData)
            setBprId(data?.data.id)

        }
        else {
            setAwardsData([
                { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
                { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
                { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
            ])

        }
    }, [data, isLoading, isSuccess])
    useEffect(() => {
        if (isSuccess && isBprSuccess) {

            const selectedItem = analystList.find((item) => item.user_id === data?.data.analyst_name)
            setSelectedAnalyst(selectedItem)
        }

    }, [isBprAnalystLoading, isBprSuccess, isSuccess, analystList])

    useEffect(() => {
        if (isSuccess && isPmDataSuccess) {
            const selectedItem = pmList.find((item) => item.user_id === data?.data.pm_name)
            setSelectedPm(selectedItem)
        }
    }, [isPmDataLoading, isPmDataSuccess, isSuccess, pmList])

    const handleChange = (index, field, value) => {
        const newAwards = [...awardsData];
        newAwards[index][field] = value;
        setAwardsData(newAwards);
    };
    const [validationErrors, setValidationErrors] = useState("");

    function handleValidate() {
        const errors = awardsData.map((award, index) => {
            const isEmpty = Object.values(award).every(value => value === '');
            return isEmpty ? (`Award ${index + 1} must have at least one field filled.`) : '';
        }).filter(error => error !== '');
        setValidationErrors(errors);
        const toastIds = errors.map(error => toast.error(error));

        setTimeout(() => {
            toastIds.forEach(toastId => {
                if (toast.isActive(toastId) && toast.dismiss) {
                    toast.dismiss(toastId);
                }
            });
        }, 3000);
        return errors.length === 0;
    }

    const handleBPRsave = async () => {
        if (!selectedAnalyst) {
            toast.error("Please add an Analyst")
        }
        if (!selectedPm) {
            toast.error("Please add Project Manager")
        }
        if (handleValidate()) {
            postBPRData({
                projectId: projectId,
                body: bprId ? {
                    userid: userID,
                    analyst_id: selectedAnalyst.user_id,
                    pm_id: selectedPm.user_id,
                    bpr_id: bprId,
                    bpraward: awardsData,
                    tocid:props.id
                } : {
                    userid: userID,
                    analyst_id: selectedAnalyst.user_id,
                    pm_id: selectedPm.user_id,
                    bpraward: awardsData,
                    tocid:props.id
                }
            }).then((res) => {
                toast.success('Form submitted successfully');
            }).catch((err) => console.log(err))
            updateTocIsSaved({projectId:projectId,tocId:props.id})
            handleSectionSave(props.id)
        }


    }

    const handleClear = () => {
        setAwardsData([
            { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
            { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
            { recipient: '', company_contact: '', contact_title: '', contact_phone: '', contact_email: '', award_category: '', Region: '', market: '', bpr_support: '', comments: '' },
        ])
        setSelectedAnalyst(null);
        setSelectedPm(null)
    }

    useEffect(() => {
        setHeader(props?.name)
    }, [props])
    const [createCloneToc,{
        isLoading:isCreateCloneLoading,
    }]=useCreateCloneTocMutation()
    const [deleteClone,{isLoading:isCloneDeleteLoading}]=useDeleteCloneMutation();
    const [updateIsApplicable,{isLoading:isUpdateApplicableLoading}]=useUpdateIsApplicableMutation()
    

    
        const handleCreateClone=async()=>{
            try {
                createCloneToc({projectId,tocId:props.id}).then((res)=>toast.success("Clone created"))
                
            } catch (error) {
                console.log(error)
                toast.error("Something went wrong")
            }
        }

        const handleDeleteClone=async()=>{
                try {
                    deleteClone({
                        projectId:projectId,
                        tocId:props.id
                    })
                    toast.success("Clone successfully deleted")
                } catch (error) {
                    console.log(error)
                }
            
            
        }
    const [isApplicable,setIsApplicable]=useState(props?.isApplicable)
    const handleUpdateIsApplicable = async (crtIsApplicable) => {
        try {
            updateIsApplicable({
                projectId: projectId,
                tocId: props.id,
                isApplicable:crtIsApplicable
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        }
    }

    const handleToggle = () => {
        setIsApplicable(1-isApplicable)
        handleUpdateIsApplicable(1-isApplicable)
    };
    
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', text: '', action: null });

    const handleConfirmation = () => {
        if (dialogContent.action === 'delete') {
          handleDeleteClone();
          // Perform the delete action here
        }
        else if(dialogContent.action === 'clone'){
            handleCreateClone()
        }
        setOpenDialog(false);
      };

      const handleClose = () => {
        setOpenDialog(false);
      };

      const onDelete=()=>{
        setDialogContent({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this section?',
            action: 'delete',
        });
        setOpenDialog(true);
      }
      const onCreateClone=()=>{
        setDialogContent({
            title: 'Confirm Clone creation',
            text: 'Are you sure you want to clone this section?',
            action: 'clone',
        });
        setOpenDialog(true);
      }
    
    return (
    <div className="card">
        {loader||isCreateCloneLoading||isCloneDeleteLoading||isUpdateApplicableLoading ? <SSOLoader /> : null}
       <div className="card-header">
           <div className="d-flex justify-content-between align-items-center">
               {
                   headerIsEdit ?
                       <div className='input-group input-group-sm w-50'>
                           <input disabled={!isProjectEditable} type='text' className='form-control rounded-0' value={header} onInput={(e) => setHeader(e.target.value)} />
                           <button disabled={!isProjectEditable} type='button' className='btn btn-warning mx-2 rounded-0' onClick={() => { handleSaveToc() }}><i style={{ color: 'unset' }} className='fa fa-save'></i> Save</button>
                           <button disabled={!isProjectEditable} type='button' className='btn btn-danger rounded-0' onClick={() => { setHeaderIsEdit(false) }}><i className='fa fa-refresh'></i> Reset</button>
                       </div>
                       :
                       <p className="mb-0">{props?.name}<i onClick={() => { setHeaderIsEdit(true) }} className='fa fa-edit font16 align-text-bottom ms-2' title='Edit Title'></i> </p>
               }
    
               <div className='d-flex align-items-center'>
                    <div className="form-check form-switch me-3 mb-0 d-flex align-items-center">
                        <input 
                            className="form-check-input" 
                            checked={isApplicable} 
                            onChange={handleToggle}
                            type="checkbox" 
                            role="switch" 
                            id="flexSwitchCheckDefault"
                        />
                        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">Applicable</label>
                    </div>
                   {props.isClone?<i className="me-3 fa fa-plus-square" alt="Square Plus" onClick={onCreateClone}></i>:null}
                   {(props.roleType!=='analyst')||(props.hasCloned)?<i className="fa fa-trash-o" alt="Trash" onClick={onDelete} title='Delete'></i>:null}
                    <DialogueBox
                        openDialog={openDialog} 
                        setOpenDialog={setOpenDialog}
                        dialogTitle={dialogContent.title}
                        dialogContentText={dialogContent.text} 
                        handleConfirmation={handleConfirmation}
                        handleClose={handleClose}
                    />
               </div>
           </div>
       </div>
    
       <div className="card-body">
           <table className="table table-bordered bpr_table table-sm">
               <tbody>
                   <tr className='theadtitle'>
                       <td>Analyst Name</td>
                       <td colSpan={5}>
                           <Autocomplete
                               disablePortal
                               disabled={!isProjectEditable}
                               options={analystList}
                               value={selectedAnalyst}
                               onChange={(event, newValue) => setSelectedAnalyst(newValue)}
                               getOptionLabel={(option) => option.email}
                               renderInput={(params) =>
                                   <TextField disabled={!isProjectEditable} {...params} label="Search by email" />
                               }
                           />
                       </td>
                   </tr>
                   <tr className='theadtitle'>
                       <td>PM Name</td>
                       <td colSpan={5}>
                           <Autocomplete
                               disablePortal
                               disabled={!isProjectEditable}
                               value={selectedPm}
                               onChange={(event, newValue) => setSelectedPm(newValue)}
                               options={pmList}
                               getOptionLabel={(option) => option.email}
                               renderInput={(params) =>
                                   <TextField disabled={!isProjectEditable} {...params} label="Search by email" />
                               }
                           />
                       </td>
                   </tr>
                   <tr>
                       <th className='align-middle text-center' colSpan={6}>3 AWARDS (REQUIRED)</th>
                   </tr>
                   {awardsData.map((item, i) => {
                       return <AwardRow
                           key={i}
                           index={i}
                           awardsData={item}
                           handleChange={handleChange}
                       />
                   })}
    
    
               </tbody>
           </table>
    
           <div className="d-flex justify-content-end">
               <button disabled={!isProjectEditable} type="button" onClick={handleBPRsave} className="btn btn-primary btn-sm me-2"><i className="fa fa-save me-1"></i>Save</button>
               <button disabled={!isProjectEditable} type="button" onClick={handleClear} className="btn btn-danger btn-sm"><i className="fa fa-close me-1"></i>Clear</button>
           </div>
       </div>
       <div className='card-footer text-white py-1 font14 bg-white border-top-0' style={{backgroundColor:'#1582C5'}}>
                <Footer/>
            </div>
    
    </div>
    )
}



export default GoInBestPracticeRecognition