const getUserDetailsFromLocalStorage = () => {
    const userDetailsString = sessionStorage.getItem('userDetails');
    if (userDetailsString) {
        return JSON.parse(userDetailsString);
    } else {
        return null;
    }
}
function buildTree(items, parentId = null) {
    return items.filter((item) => item.parent_id === parentId)
    .map((item) => {
        const children = buildTree(items, item.id)
        return { ...item, children }
    })
}
export {getUserDetailsFromLocalStorage,buildTree}