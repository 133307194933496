import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@mui/material';

const DialogueBox = ({ 
    openDialog, 
    setOpenDialog, 
    dialogTitle, 
    dialogContentText, 
    handleConfirmation,
    handleClose
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus 
    >
      <DialogTitle id="alert-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">No</Button>
        <Button onClick={() => {
          handleConfirmation();
          setOpenDialog(false); // Ensure dialog closes after confirmation
        }} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogueBox;