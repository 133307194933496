import { useContext } from "react";
import { ProjectStatusContext } from "../../Providers/ProjectStatusProvider";
import high from "../../images/high.png";
import med from "../../images/medium.png";
import low from "../../images/low.png";


const ImpactButton = ({ metric, impact, label, className, onClick }) => {
    const {isProjectEditable} = useContext(ProjectStatusContext)
    return <button 
    disabled={!isProjectEditable}
    className={`btn btn-sm mb-1 d-flex ${metric.impact === impact ? className: `btn-outline-${className.split('-')[1]}`}`}
    onClick={() => onClick(metric.id, impact)}
>
    <>{impact == "high" ? <img className="highcls" src={high} /> : impact == "medium" ? <img className="medcls" src={med} /> : <img className="lowcls" src={low} />}</> {label}
</button>
};
export default ImpactButton